import { Typography, 
    Grid, MenuItem, FormControl, Select, 
    InputLabel, Button, FormHelperText } from '@mui/material';
import TextField from '@mui/material/TextField'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { DialogAdjustments } from "../../components/DialogStatus/DialogAdjustments"
import { getParam } from "../../utils/utils"
import { backend_url } from '../../settings'
import { useFetch } from '../../hooks'
import { useEffect, useState } from 'react';
import { useUserPrefs } from '../UserPreferences/ProvideUserPrefs';
import SnackbarUtils from '../../utils/SnackbarUtils'
import { DateTime } from 'luxon';
import { useTranslation } from "react-i18next"
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    maxHeight: 700,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const warningStyles = {
    "& .MuiFormLabel-root.Mui-error": {
        color: "orange !important"
    },
    "& .MuiInput-underline.Mui-error:after": {
        borderBottomColor: "orange !important"
    },
    "& .MuiFormHelperText-root.Mui-error": {
        color: "orange !important"
    }
}

export const AccrualAdjustmentModal = ({open, handleClose, setUpdateRows}) => {

    const { t } = useTranslation("translation")
    const value_bu = localStorage.getItem('bu_bpx')
    const tokenBpxRequest = localStorage.getItem('token_bpx')
    const currentUser = JSON.parse(localStorage.getItem('current_user'))
    const request = useFetch()
    const [aoOptions, setAoOptions] = useState()
    const [planAccountOptions, setPlanAccountOptions] = useState() 
    const [aObj, setAObj] = useState("")
    const [planAccount, setPlanAccount] = useState("")
    const [periods, setPeriods] = useState()
    const { prefsData } = useUserPrefs()
    const [dateFrom, setDateFrom] = useState()
    const [dateTo, setDateTo] = useState()
    const [isManual, setIsManual] = useState()
    const [isZeroBudget, setIsZeroBudget] = useState(false)
    const [isDateOutside, setIsDateOutside] = useState()
    const [isDateToOutside, setIsDateToOutside] = useState()
    const [isDateExcluded, setIsDateExcluded] = useState({dateFrom: false, dateTo: false})
    const [isDateOverwrite, setIsDateOverwrite] = useState({dateFrom: false, dateTo: false})
    const [firstAmount, setFirstAmount] = useState()
    const [secondAmount, setSecondAmount] = useState()
    const [budgetError, setBudgetError] = useState()
    const [budgetWarning, setBudgetWarning] = useState()
    const [config, setConfig] = useState()
    const [dialogOpen, setDialogOpen] = useState(false)
    const [dialogType, setDialogType] = useState()
    const [calendar, setCalendar] = useState()
    const [runPlanError, setRunPlanError] = useState(false)
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    useEffect(() => {

        var queryParams = {
            business_unit: value_bu,
            limit: 100,
            skip: 0,
        }
        var queryString = getParam(queryParams).toString()

        request.fetchData(
            `${backend_url.config_accrual_adjustment}?${queryString}`,
            'GET',
            null,
            false,
            tokenBpxRequest,
            false
        )
        .then(data => {
            if (data !== null) {
                queryParams["AO_TYPES"] = data.map(conf => {
                    return conf.AO_TYPE
                })
                queryString = getParam(queryParams).toString()
                setConfig(data)
                request.fetchData(
                    `${backend_url.get_plan_accounts}?${queryString}`,
                    'GET',
                    null,
                    false,
                    tokenBpxRequest,
                    false
                )
                .then(data => {
                    setPlanAccountOptions(data)
                })
            }             
        })
      
    }, [])


    const handleAOChange = (event) => {
        setAObj(event.target.value)
        request.fetchData(
            `${backend_url.accrual_object_header}/${event.target.value.AO_ID}?business_unit=${value_bu}`,
            'GET',
            null,
            false,
            tokenBpxRequest,
            false
        )
        .then(aoData => {
            var calendar = "PL01"
            if (aoData.run_plan !== null) {
                calendar = aoData.run_plan.CALENDAR
                if (aoData.run_plan.BUDGET === 0 || aoData.run_plan.BUDGET === null 
                    || aoData.run_plan.BUDGET === '0' || aoData.run_plan.BUDGET === '0.0') {
                    setIsZeroBudget(true)
                }    
            } else {
                setRunPlanError(true)
            }
            setCalendar(calendar) 
            request.fetchData(
                `${backend_url.config_calendar_periods}?skip=0&limit=100000&CAL_ID=${calendar}&PERIOD_TYPE=W`,
                'GET',
                null,
                false,
                tokenBpxRequest,
                false
            )
            .then(data => {
                setPeriods(data)
                validateDate(dateFrom, setIsDateOutside, event.target.value, aoData.run_plan.CALENDAR)
                validateDate(dateTo, setIsDateToOutside, event.target.value, aoData.run_plan.CALENDAR, false)
            })
        })
        setIsManual(event.target.value.IS_MANUAL)
    }

    const handlePAChange = (event) => {
        setAObj("")
        setPlanAccount(event.target.value)
        var queryParams = {
            business_unit: value_bu,
            PLAN_ACCOUNT_BP_NR: event.target.value.PLAN_ACCOUNT_NUMBER,
            AO_STATUS: ["NEW", "ACTIVE", "ENDED"]
        }
        queryParams["AO_TYPE"] = config.map(conf => {
            return conf.AO_TYPE
        })
        const queryString = getParam(queryParams).toString()
        request.fetchData(
            `${backend_url.accrual_object_header}?${queryString}`,
            'GET',
            null,
            false,
            tokenBpxRequest,
            false
        )
        .then(data => {
            setAoOptions(data)
        })
    }

    const handleDateFromChange = (value, event) => {
        setDateFrom(new Date(value))
        validateDate(value, setIsDateOutside, aObj, calendar)
    }

    const handleDateToChange = (value, event) => {
        setDateTo(new Date(value))
        validateDate(value, setIsDateToOutside, aObj, calendar, false)
    }

    const checkPeriod = (date) => {  
        var checkedPeriod = ""
        periods.map(period => {
            if (period.DATE_PERIOD_FROM <= DateTime.fromJSDate(date).toISODate() && DateTime.fromJSDate(date).toISODate() <= period.DATE_PERIOD_TO) {
                checkedPeriod = period.PERIOD_ID
            }     
        })
        return `${days[date.getDay()]}\n${checkedPeriod}`
    }

    const handleFirstAmountChange = (event) => {
        setFirstAmount(event.target.value)
        validateBudget(event, secondAmount)
    } 

    const handleSecondAmountChange = (event) => {
        setSecondAmount(event.target.value)
        validateBudget(event, firstAmount)
    }

    const validateBudget = (e, amount) => {
        var configForType = config.find((elem) => {
            return elem.AO_TYPE === aObj.AO_TYPE && elem.AMOUNTS_SUM_NOT_ZERO
        })
        if (parseFloat(amount) + parseFloat(e.target.value) !== 0) {
            if (configForType.AMOUNTS_SUM_NOT_ZERO === "E")
                setBudgetError(true)
            else
                setBudgetWarning(true)
        } else {
            if (configForType.AMOUNTS_SUM_NOT_ZERO === "E")
                setBudgetError(false)
            else
                setBudgetWarning(false)
        }
    }

    const validateDate = (value, setDate, aObj, calendar, isDateFrom = true) => {
        if (aObj) {
            let convertedDate = DateTime.fromJSDate(new Date(value)).toISODate()
            if (convertedDate < aObj.DATE_FROM_EXFACTORY || convertedDate > aObj.DATE_TO_EXFACTORY) {
                setDate(true)
            } else 
                setDate(false)
            if (calendar && convertedDate !== null) {
                request.fetchData(
                    `${backend_url.config_calendars_events}/${calendar}/${convertedDate}`,
                    'GET',
                    null,
                    false,
                    tokenBpxRequest,
                    false
                )
                .then(data => {
                    if (data.EXCLUDED === true) {
                        if (isDateFrom) {
                            setIsDateExcluded((prevStat) => ({
                                ...prevStat,
                                dateFrom: true
                            }))
                        } else {
                            setIsDateExcluded((prevStat) => ({
                                ...prevStat,
                                dateTo: true
                            }))
                        }
                    }
                    if (data.DATE_POSTING_OVERWRITE !== null) {
                        if (isDateFrom) {
                            setIsDateOverwrite((prevStat) => ({
                                ...prevStat,
                                dateFrom: true
                            }))
                        } else {
                            setIsDateOverwrite((prevStat) => ({
                                ...prevStat,
                                dateTo: true
                            }))
                        }
                    }
                })
                .catch(e => {})
            }
        }
        
    }

    const validateRequiredFields = () => {
        return !planAccount || !aObj || !firstAmount || !secondAmount || (!config && aObj)
    }

    const showErrorsAndWarnings = () => { 
        return runPlanError && aObj || !config && aObj || !isManual && aObj || !isZeroBudget && aObj
                    || isDateOutside && aObj || isDateToOutside && aObj || isDateExcluded.dateFrom && aObj
                        || isDateExcluded.dateTo && aObj || isDateOverwrite.dateFrom && aObj || isDateOverwrite.dateTo && aObj
                            || budgetWarning || budgetError
    }

    const createAdjustment = (action) => {
        let adjustment = {
            BUSINESS_UNIT: value_bu,
            PLAN_ACCOUNT_BP_NR: planAccount.PLAN_ACCOUNT_NUMBER,
            PLAN_ACCOUNT_BP_CAT: planAccount.PLAN_ACCOUNT_CAT,
            AO_ID: aObj.AO_ID,
            AO_VERSION: aObj.VERSION,
            // PP1_DATE: DateTime.fromJSDate(dateFrom).setZone(prefsData.time_zone).toISO(),
            PP1_DATE: DateTime.fromJSDate(dateFrom).toFormat("yyyy-MM-dd"),
            PP1_AMOUNT: firstAmount,
            // PP2_DATE: DateTime.fromJSDate(dateTo).setZone(prefsData.time_zone).toISO(),
            PP2_DATE: DateTime.fromJSDate(dateTo).toFormat("yyyy-MM-dd"),
            PP2_AMOUNT: secondAmount,
            AC_AD_STATUS: "New",
            USER_ID: currentUser.username,
            TIMESTAMP: DateTime.now().setZone(prefsData.time_zone).toISO(),
            STATUS_MESSAGE: "Created"
        }
            
            request.fetchData(
                `${backend_url.accrual_adjustment}/${action}?business_unit=${value_bu}`,
                "POST",
                JSON.stringify([adjustment]),
                false,
                tokenBpxRequest,
                false,
                true,
                false,
                true
            ).then(data => {
                if (data) {
                    SnackbarUtils.success(JSON.stringify("Accrual Adjustment successfully created"))
                    handleClose()
                }
                
            }).catch(e => {     
                let actionText = action === "simulate" ? "Simulation" : "Execution"
                SnackbarUtils.error(JSON.stringify(`${actionText} has failed. Please try again later.`))
            })
        
    }

    const handleSimulate = () => {
        setDialogType("Simulation")
        setDialogOpen(true)
    }

    const handleExecute = () => {
        setDialogType("Posting")
        setDialogOpen(true)
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ '& .MuiDialog-paper': { maxWidth: '600px' } }}
        >
                <DialogTitle sx={{textAlign: "center", paddingBottom: 0, position: "relative" }}>
                  <IconButton
                    onClick={handleClose}
                    className="wrap-cancel-modal"
                    sx={{
                      position: "absolute",
                      right: "20px",
                      top: "17px",
                      padding: "0.2rem",
                      color: "#9d9d9d",
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                    <strong>{t("Adjustments.MODAL.TITLE")}</strong>
                    <hr/>
                </DialogTitle>
                
                <DialogContent sx={{paddingTop:0}}>
                <Grid rowSpacing={3} columnSpacing={3} container sx={{marginTop: "5px"}}>
                    <Grid item xs={6}>
                        <FormControl sx={{width: "250px"}} size="small">
                            <InputLabel id="plan-account-select-label">{t("Adjustments.PLAN_ACCOUNT")}</InputLabel>
                            <Select
                                labelId="plan-account-select-label"
                                id="plan-account-select"
                                label={t("Adjustments.PLAN_ACCOUNT")}
                                onChange={handlePAChange}
                                size='small'
                                value={planAccount ? planAccount : ""}
                                inputProps={{
                                    sx: {fontSize: "13px",
                                }
                                }}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            '& .MuiMenuItem-root': {
                                                fontSize: 13,
                                            },
                                        },
                                        style: {
                                            fontSize: 13,
                                        },
                                    },
                                }}
                            >
                                {planAccountOptions && planAccountOptions.map(option => {
                                    return <MenuItem key={option.PLAN_ACCOUNT_NUMBER} value={option}>{option.PLAN_ACCOUNT_NUMBER + ' - ' + option.PLAN_ACCOUNT_NAME}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl sx={{width: "250px"}} size="small">
                            <InputLabel id="accrual-object-select-label">{t("Adjustments.MODAL.ACCRUAL_OBJECT")}</InputLabel>
                            <Select
                                error={(!config && aObj) || (runPlanError && aObj) || (!isManual && aObj) || (!isZeroBudget && aObj) ? true : false}
                                labelId="accrual-object-select-label"
                                id="accrual-object-select"
                                label={t("Adjustments.MODAL.ACCRUAL_OBJECT")}
                                onChange={handleAOChange}
                                size='small'
                                value={aObj ? aObj : ""}
                                inputProps={{
                                    sx: {fontSize: "13px"}
                                }}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            '& .MuiMenuItem-root': {
                                                fontSize: 13,
                                            },
                                        },
                                        style: {
                                            fontSize: 13,
                                        },
                                    },
                                }}
                                sx={{
                                    "& .MuiFormLabel-root.Mui-error": {
                                        color: (!config && aObj || runPlanError && aObj) ? "red" : "#ed7d31 !important"
                                    },
                                    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
                                        borderColor: (!config && aObj || runPlanError && aObj) ? "red" : "#ed7d31 !important"
                                    }
                                }}
                            >
                                {aoOptions ? aoOptions.map(option => {
                                    return <MenuItem key={option.AO_ID} value={option}>{option.AO_ID} - {option.ACTIVITY_ID} - {option.AO_DESCRIPTION}</MenuItem>
                                }) : <MenuItem value=""></MenuItem>}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2" component="h6">{t("Adjustments.MODAL.1_P")}</Typography>
                        <hr/>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2" component="h6">{t("Adjustments.MODAL.2_P")}</Typography>
                        <hr/>
                    </Grid>
                    <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} >
                            <DesktopDatePicker           
                                inputFormat={prefsData && prefsData.date_format}
                                onChange={(value, event) => handleDateFromChange(value, event)}
                                value={dateFrom ? dateFrom : null}
                                disabled={!config && aObj ? true : false}
                                renderInput={(params) => 
                                    <TextField
                                        sx={{
                                            width: "170px",
                                            "& .MuiFormLabel-root.Mui-error": {
                                                color: "#ed7d31 !important"
                                            },
                                            "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "#ed7d31 !important"
                                            },
                                            "& .MuiOutlinedInput-root.Mui-error": {
                                                color: (isDateOutside && aObj || isDateExcluded.dateFrom && aObj || isDateOverwrite.dateFrom && aObj) ? "#ed7d31 !important" : "black"
                                            }
                                        }}
                                        size="small"
                                        {...params} 
                                        inputProps={
                                            {
                                                ...params.inputProps,
                                                placeholder: "dd-mm-yyyy"
                                            }
                                        }
                                        error={(isDateOutside && aObj) || (isDateExcluded.dateFrom && aObj) || (isDateOverwrite.dateFrom && aObj) ? true : false}
                                    /> 
                                }
                            />
                        </LocalizationProvider>
                        {dateFrom && periods ?  <span style={{whiteSpace: "pre-line"}} className='periodCurrency'>{checkPeriod(dateFrom)}</span> : null}
                    </Grid>
                    <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} >
                            <DesktopDatePicker
                                inputFormat={prefsData && prefsData.date_format}
                                onChange={(value, event) => handleDateToChange(value, event)}
                                value={dateTo ? dateTo: null}
                                disabled={!config && aObj ? true : false}
                                renderInput={(params) => 
                                    <TextField
                                        sx={{
                                            width: "170px",
                                            "& .MuiFormLabel-root.Mui-error": {
                                                color: "#ed7d31 !important"
                                            },
                                            "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "#ed7d31 !important"
                                            },
                                            "& .MuiOutlinedInput-root.Mui-error": {
                                                color: (isDateToOutside && aObj || isDateExcluded.dateTo && aObj || isDateOverwrite.dateTo && aObj) ? "#ed7d31 !important" : "black"
                                            }
                                        }}
                                        size="small"
                                        {...params} inputProps={
                                            {
                                                ...params.inputProps,
                                                placeholder: "dd-mm-yyyy"
                                            }
                                        }
                                        error={(isDateToOutside && aObj) || (isDateExcluded.dateTo && aObj) || (isDateOverwrite.dateTo && aObj) ? true : false}
                                    /> 
                                }
                            />
                        </LocalizationProvider>
                        {dateTo && periods ? <span style={{whiteSpace: "pre-line"}} className='periodCurrency'>{checkPeriod(dateTo)}</span>: null}
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            error={budgetError || budgetWarning ? true : false}
                            id="posting-amount-p1"
                            label={t("Adjustments.MODAL.POSTING_AMOUNT")}
                            type="number"
                            disabled={!config && aObj ? true : false}
                            sx={{   
                                width: "200px",
                                "& .MuiFormLabel-root.Mui-error": {
                                    color: budgetError ? "red" : "#ed7d31 !important"
                                },
                                "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
                                    borderColor: budgetError ? "red" : "#ed7d31 !important"
                                },
                                "& .MuiOutlinedInput-root.Mui-error": {
                                    color: budgetError ? "red" : "#ed7d31 !important"
                                }
                            }}
                            size='small'
                            value={firstAmount ? firstAmount : ""}
                            onChange={handleFirstAmountChange}
                            onBlur={(e) => validateBudget(e, secondAmount)}
                        />
                        <span className='periodCurrency'>{aObj !== "" ? aObj.AO_CURRENCY : ""}</span>
                    </Grid>
                    <Grid item xs={6}>   
                        <TextField
                            id="posting-amount-p2"
                            label={t("Adjustments.MODAL.POSTING_AMOUNT")}
                            type="number"
                            disabled={!config && aObj ? true : false}
                            sx={{
                                width: "200px", 
                                "& .MuiFormLabel-root.Mui-error": {
                                    color: budgetError ? "red" : "#ed7d31 !important"
                                },
                                "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
                                    borderColor: budgetError ? "red" : "#ed7d31 !important"
                                },
                                "& .MuiOutlinedInput-root.Mui-error": {
                                    color: budgetError ? "red" : "#ed7d31 !important"
                                }
                                
                            }}
                            size='small'
                            error={budgetError || budgetWarning ? true : false}
                            value={secondAmount ? secondAmount : ""}
                            onChange={handleSecondAmountChange}
                            onBlur={(e) => validateBudget(e, firstAmount)}
                        />
                        <span className='periodCurrency'>{aObj !== "" ? aObj.AO_CURRENCY : ""}</span>
                    </Grid>
                    {showErrorsAndWarnings() &&
                        <Grid item xs={12}>
                            <Typography variant="body2" component="h6">{t("Adjustments.Errors")}</Typography>
                            <hr/>
                            {runPlanError && aObj ? <FormHelperText sx={{color: "red"}}>{t("Adjustments.RUN_PLAN_ERROR")}</FormHelperText>: null}
                            {!config && aObj ? <FormHelperText sx={{color: "red"}}>{t("Adjustments.CONFIG_ERROR")}</FormHelperText>: null}
                            {!isManual && aObj ? <FormHelperText sx={{color: "#ed7d31"}}>{t("Adjustments.MANUAL_WARNING")}</FormHelperText> : null}
                            {!isZeroBudget && aObj ? <FormHelperText sx={{color: "#ed7d31"}}>{t("Adjustments.BUDGET_ZERO_WARNING")}</FormHelperText>: null}
                            {(isDateOutside && aObj || isDateToOutside && aObj) ? <FormHelperText sx={{color: "#ed7d31"}}>{t("Adjustments.AO_VALIDITY_WARNING")}</FormHelperText>: null}
                            {(isDateExcluded.dateFrom && aObj || isDateExcluded.dateTo && aObj) ? <FormHelperText sx={{color: "#ed7d31"}}>{t("Adjustments.DATE_EXCLUDED_WARNING")}</FormHelperText>: null}
                            {(isDateOverwrite.dateFrom && aObj || isDateOverwrite.dateTo && aObj) ? <FormHelperText sx={{color: "#ed7d31"}}>{t("Adjustments.DATE_OVERWRITE_WARNING")}</FormHelperText>: null}
                            {budgetWarning ? <FormHelperText sx={{color: "#ed7d31"}}>{t("Adjustments.AMOUNTS_WARNING")}</FormHelperText>: null}
                            {budgetError ? <FormHelperText sx={{color: "red"}}>{t("Adjustments.AMOUNTS_ERROR")}</FormHelperText>: null}
                        </Grid>
                    }
                    
                    <DialogAdjustments 
                        dialogType={dialogType} 
                        handleAction={createAdjustment} 
                        dialogOpen={dialogOpen} 
                        acAd={null} 
                        planAccount={planAccount.PLAN_ACCOUNT_NUMBER + ' - ' + planAccount.PLAN_ACCOUNT_NAME} 
                        adjustmentList={false}
                        handleModalClose={()=>{}}
                        isManual={isManual}
                        isZeroBudget={isZeroBudget}
                        isDateOutside={isDateOutside || isDateToOutside}
                        budgetWarning={budgetWarning}
                        isDateExcluded={isDateExcluded}
                        isDateOverwrite={isDateOverwrite}
                        handleClose={() => {setDialogOpen(false); setUpdateRows((prevState) => !prevState)}}/>
                </Grid>
                </DialogContent>
                <DialogActions> 
                    <Button variant="outlined" disabled={budgetError || runPlanError || validateRequiredFields()} size="small" onClick={() => handleSimulate()}>{t("Adjustments.MODAL.SIMULATE")}</Button>
                    <Button variant="contained" disabled={budgetError || runPlanError || validateRequiredFields()} size="small" onClick={() => handleExecute()}>{t("Adjustments.MODAL.EXECUTE")}</Button>
                </DialogActions>
        </Dialog>
    )
}
