import { LoggedIn, SwitchEdit } from '../../../../components'
import { GridToolbarColumnsButton } from '@mui/x-data-grid-pro'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Box } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import RestoreIcon from '@mui/icons-material/Restore';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { useNavigate } from 'react-router'
import { useSearchParams } from "react-router-dom"
import { Lock, LockOpen } from '@mui/icons-material'
import { useParams } from 'react-router-dom'
import FontSize from '../../../../components/CustomToolbar/FontSize'

function Toolbar(props) {

  const { 
    bread, 
    title, 
    displayColumn = false, 
    filters,
    loading,
    onSwitcher,
    switcher,
    openUpload,
    isFontSize=false,
    fontSize=null,
    onChangeFontSize=()=>{}
  } = props

  const navigate = useNavigate()
  
  //const isReturn = searchParams.get('isReturn')
  const params = useParams();
  const passID = params.id

  function resetPage () {
    localStorage.removeItem("filters")
    return window.location.reload()
  }

  function handleClick (event, id) {
    navigate(`/accrual-balance/accrual_posting?AO_ID=${id}&filters=false`)
  }

  function displayColumnAndExport () {

    if (displayColumn) {
      return (
        <div className='toolbarSection toolbarIconsBlock'>
          <div className='toolbarIconSection toolbarGridExport'>
              <button onClick={(e) => openUpload()}>
                <span className="MuiButton-startIcon">
                  <FileDownloadOutlinedIcon sx={{ fontSize: "18px" }} />  
                </span>
                Upload File
                <span className="MuiTouchRipple-root"></span>
              </button>
          </div>

          <div className='toolbarIconSection toolbarSwitch'>
            <SwitchEdit 
              onSwitcher={onSwitcher} 
              switcher={switcher} 
              loading={loading}
              text="Show All Types" 
            />
          </div>

        </div>
      )
    } else {
      return null
    }

  }

  
  return (
    <div className='toolbarContainerBlock'>
      
      <div className='toolbarborderBlock'>
        <div className='toolbarBlock'>
          <LoggedIn breadcrumbs={bread} />
          { displayColumnAndExport() }
        </div>
      </div>

      {
        isFontSize?
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <h2 className='toolbarTitle'>{title}</h2> 
          <FontSize size={fontSize} onChange={onChangeFontSize}/> 
        </Box>:
        <h2 className='toolbarTitle'>{title}</h2>  
      }

      { filters && (<Box sx={{ mb: 2 }}>{filters}</Box>) }      
      
    </div>
  )
}

export default Toolbar
