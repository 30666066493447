import React, { useState, useEffect, useCallback } from "react"
import { DataGridPro, GridOverlay, useGridApiRef } from "@mui/x-data-grid-pro"
import { LinearProgress } from "@mui/material"
import { backend_url } from "../../settings"
import { getParam, numberFormatter, checkObjectKey } from "../../utils/utils"
import { useTranslation } from "react-i18next"
import { CustomToolbar } from "../../components"
import { Link } from "react-router-dom"
import { CustomLinkTable } from "../../components"
import CustomMultipleFilter from '../../mui-components/CustomMultipleFilter'
import CustomPanel from '../../mui-components/CustomPanel'
import { useFetch, useConvertJSON, usePermissions } from "../../hooks"
import { useUserPrefs } from "../UserPreferences/ProvideUserPrefs";
import { applyFilters, checkSize } from '../../utils/utils'



function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}


export default function MyBalance() {

  const apiRef = useGridApiRef()

  const { t } = useTranslation("translation")
  const value_bu = localStorage.getItem("bu_bpx")
  const tokenBpxRequest = localStorage.getItem("token_bpx")

  const request = useFetch()
  const { json2array } = useConvertJSON()
  const { obj, childObj } = usePermissions()
  const checkActivityID = checkObjectKey(obj, childObj, 'Activity_ID')

  const [rows, setRows] = useState([])
  const [page, setPage] = useState(0)
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(false)

  const [exportQuery, setExportQuery] = useState("")
  const { prefsData, saveUserPrefs, updateUserPrefsOnClose, saveUserPrefsWithoutRefresh, silentUpdateUserPrefs } = useUserPrefs();
  const [orderedColumns, setOrderedColumns] = useState(prefsData.reports_column_order&&prefsData.reports_column_order.My_Balance || []);
  const [fontSize, setFontSize] = useState(prefsData.reports_column_size&&prefsData.reports_column_size.My_Balance || 'm');
  const columnVisibility = {};
  if(prefsData.reports_column_visibilty&&prefsData.reports_column_visibilty.My_Balance){
    prefsData.reports_column_visibilty.My_Balance.forEach(it=>columnVisibility[it]=false);
  }
  

  const saveColumnVisibility = (data)=>{
    saveUserPrefs({
      ...prefsData,
      reports_column_visibilty:{
        ...prefsData.reports_column_visibilty,
        My_Balance: Object.entries(data).filter(it=>!it[1]).map(it=>it[0])
      }
    })
  }

  const [filter, setFilter] = useState([
    {
      endpoint: backend_url.my_balance_filter_values,
      queryKeyToTyping: "ACTIVITY_ID",
      title: t("MyBalance.ACTIVITY_ID"),
      inputText: t("MyBalance.ACTIVITY_ID_INPUT"),
      firstKeyOption: "ACTIVITY_ID",
      secondKeyOption: "",
      valueFilter: applyFilters("ACTIVITY_ID", "mybalance"),
      changingArray: [],
      atLeastThreeLetter: true,
      formatField: "select",
    },
    {
      endpoint: backend_url.my_balance_filter_values,
      queryKeyToTyping: "EXT_CATEGORY",
      title: t("MyBalance.EXT_CATEGORY"),
      inputText: t("MyBalance.EXT_CATEGORY_INPUT"),
      firstKeyOption: "EXT_CATEGORY",
      secondKeyOption: "",
      valueFilter: applyFilters("EXT_CATEGORY", "mybalance"),
      changingArray: [],
      formatField: "select"
    },
    {
      endpoint: backend_url.my_balance_filter_values,
      queryKeyToTyping: "EXT_CLASS",
      title: t("MyBalance.EXT_CLASS"),
      inputText: t("MyBalance.EXT_CLASS_INPUT"),
      firstKeyOption: "EXT_CLASS",
      secondKeyOption: "",
      valueFilter: applyFilters("EXT_CLASS", "mybalance"),
      changingArray: [],
      formatField: "select"
    },
    {
      endpoint: backend_url.my_balance_filter_values,
      queryKeyToTyping: "EXT_SUBCLASS",
      title: t("MyBalance.EXT_SUBCLASS"),
      inputText: t("MyBalance.EXT_SUBCLASS_INPUT"),
      firstKeyOption: "EXT_SUBCLASS",
      secondKeyOption: "",
      valueFilter: applyFilters("EXT_SUBCLASS", "mybalance"),
      changingArray: [],
      formatField: "select"
    },
    {
      endpoint: backend_url.my_balance_filter_values,
      queryKeyToTyping: "EXT_SPEND_TYPE",
      title: t("MyBalance.EXT_SPEND_TYPE"),
      inputText: t("MyBalance.EXT_SPEND_TYPE_INPUT"),
      firstKeyOption: "EXT_SPEND_TYPE",
      secondKeyOption: "",
      valueFilter: applyFilters("EXT_SPEND_TYPE", "mybalance"),
      changingArray: [],
      formatField: "select"
    },
    {
      endpoint: backend_url.my_balance_filter_values,
      queryKeyToTyping: "AO_STATUS",
      title: t("MyBalance.AO_STATUS"),
      inputText: t("MyBalance.AO_STATUS_INPUT"),
      firstKeyOption: "AO_STATUS",
      secondKeyOption: "",
      valueFilter: applyFilters("AO_STATUS", "mybalance"),
      changingArray: [],
      formatField: "select"
    },

    {
      endpoint: backend_url.my_balance_filter_values,
      queryKeyToTyping: "BP",
      title: t("MyBalance.BUSINESS_PARTNER_NAME"),
      inputText: t("MyBalance.BUSINESS_PARTNER_NAME_INPUT"),
      firstKeyOption: "BP_NR",
      secondKeyOption: "BUSINESS_PARTNER_NAME",
      valueFilter: applyFilters("BP_NR", "mybalance"),
      changingArray: [],
      atLeastThreeLetter: true,
      formatField: "select"
    },
    {
      endpoint: backend_url.my_balance_filter_values,
      queryKeyToTyping: "MATERIAL_NR",
      title: t("MyBalance.MATERIAL_NR"),
      inputText: t("MyBalance.MATERIAL_NR_INPUT"),
      firstKeyOption: "MATERIAL_NR",
      secondKeyOption: "",
      valueFilter: applyFilters("MATERIAL_NR", "mybalance"),
      changingArray: [],
      atLeastThreeLetter: true,
      formatField: "select"
    },
    {
      endpoint: backend_url.my_balance_filter_values,
      queryKeyToTyping: "BUSINESS_SEGMENT",
      title: t("MyBalance.BUSINESS_SEGMENT"),
      inputText: t("MyBalance.BUSINESS_SEGMENT_INPUT"),
      firstKeyOption: "BUSINESS_SEGMENT",
      secondKeyOption: "",
      valueFilter: applyFilters("BUSINESS_SEGMENT", "mybalance", [], json2array("BUSINESS_SEGMENT")),
      changingArray: json2array("BUSINESS_SEGMENT"),
      translateJSON: true,
      formatField: "select"
    },
    
  ])


  
  const changeFilterValues = (state) => {
    setFilter(state)
    setRows([])
    setPage(0)
  }

  const addLoadedRows = useCallback((items) => {
    const changeIdRow = items.map((d) => {
      return {
        id: d.AO_ID + "_" + d.ACTIVITY_ID + "_" + d.EXT_CATEGORY + "_" + d.EXT_CLASS + "_" + d.EXT_SPEND_TYPE +
          "_" + d.EXT_SPEND_TYPE + "_" + d.EXT_SUBCLASS + "_" + d.BP_ROLE + "_" + d.BP_NR +
          "_" + d.MATERIAL_NR + "_" + d.AO_LINE_ID,
        ao_id: d.AO_ID,
        ...d,
        BUSINESS_PARTNER_NAME: d.BP_ROLE + d.BP_NR + " - " + d.BUSINESS_PARTNER_NAME
      }
    })

    setRows((old) => {
      return [...old, ...changeIdRow]
    })

  }, [])
  

  useEffect(() => {
    
    setLoading(true)
    
    const queryParams = {
      business_unit: value_bu,
      limit: 100,
      skip: page,
    }

    filter.map((key) => {
      const { valueFilter, firstKeyOption, formatField, translateJSON } = key

      switch (formatField) {
        case "select":
          if (valueFilter.length) {

            if (translateJSON) {
              
              const getKey = valueFilter.map(jsonkey => Object.keys(jsonkey))
              queryParams[firstKeyOption] = getKey

            } else {
              queryParams[firstKeyOption] = valueFilter
            }
            
          }
          break
        case "date":
          if (valueFilter) {
            queryParams[firstKeyOption] = valueFilter
          }
          break
        default:
          return null
      }
    })
    let cachedfilters = localStorage.getItem("filters")
    let filterObj = {
      mybalance: queryParams
    }
    if (cachedfilters && Object.keys(cachedfilters).length) { 
        filterObj = JSON.parse(cachedfilters)
        filterObj["mybalance"] = queryParams
    }
    localStorage.setItem("filters", JSON.stringify(filterObj))

    const queryString = getParam(queryParams).toString()
    
    setExportQuery(queryString)

    request.fetchData(
      `${backend_url.my_balance}?${queryString}`,
      "GET",
      null,
      false,
      tokenBpxRequest,
      false
    )
    .then(data => {
      setLoading(false)
      addLoadedRows(data)
      setCount(data.length)
    })
  }, [filter, page])

  useEffect(() => {
    return apiRef.current.subscribeEvent('columnHeaderDragEnd', (params) => {
        silentUpdateUserPrefs()
    })
  }, [apiRef])

  const handleOnRowsScrollEnd = (params) => {
    if (params.viewportPageSize&&!loading) {
      if (count >= 100) {
        return setPage(page + 100)
      } else {
        return null
      }
    }
  }

  const columns = React.useMemo(() => {
    const allColumns = {
      id: { 
          field: "id", 
          headerName: "id" 
      },
      ACTIVITY_ID: {
          field: "ACTIVITY_ID",
          headerName: t("MyBalance.ACTIVITY_ID"),
          hideable: false,
          flex: 0.07,
          minWidth: 50,
          renderCell: (params) => {
            if (checkActivityID) {
              return (
                <div className="MuiDataGrid-cellContent">
                  <Link
                    style={{ color: "#f69e80" }}
                    to={`/my-activities/accrual_object/${params.row.ao_id}`}
                    className="dark-mode-link-color"
                  >
                    {params.value}
                  </Link>
                </div>
              )
            }
          },
      },
      EXT_CATEGORY: { 
          field: "EXT_CATEGORY", 
          headerName: t("MyBalance.EXT_CATEGORY"), 
          flex: 0.08,
          minWidth: 50, 
          renderHeader: ({colDef})=>{
            if(colDef.width <= 100){
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Investment type" 
                data-mui-internal-clone-element="true">
                    {t("MyBalance.EXT_CATEGORY_SHORT")}
                </div>
              )
            }
            return (
              <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Investment type" 
                data-mui-internal-clone-element="true">
                  {t("MyBalance.EXT_CATEGORY")}
              </div>
            )
          },
      },
      EXT_CLASS: { 
          field: "EXT_CLASS", 
          headerName: t("MyBalance.EXT_CLASS"), 
          flex: 0.09,
          minWidth: 50, 
          renderHeader: ({colDef})=>{
            if(colDef.width < 100){
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Investment description" 
                data-mui-internal-clone-element="true">
                    {t("MyBalance.EXT_CLASS_SHORT")}
                </div>
              )
            }
            return (
              <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Investment description" 
                data-mui-internal-clone-element="true">
                  {t("MyBalance.EXT_CLASS")}
              </div>
            )
          },
      },
      EXT_SUBCLASS: { 
          field: "EXT_SUBCLASS", 
          headerName: t("MyBalance.EXT_SUBCLASS"), 
          flex: 0.06,
          minWidth: 50, 
          renderHeader: ({colDef})=>{
            if(colDef.width <= 100){
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Investment subclass" 
                data-mui-internal-clone-element="true">
                    {t("MyBalance.EXT_SUBCLASS_SHORT")}
                </div>
              )
            }
            return (
              <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Investment subclass" 
                data-mui-internal-clone-element="true">
                  {t("MyBalance.EXT_SUBCLASS")}
              </div>
            )
          },
      },
      EXT_SPEND_TYPE: {
          field: "EXT_SPEND_TYPE",
          headerName: t("MyBalance.EXT_SPEND_TYPE"),
          flex: 0.05,
          minWidth: 50, 
      },
      PLAN_ACCOUNT_NAME: {
          field: "PLAN_ACCOUNT_NAME",
          headerName: t("MyBalance.PLAN_ACCOUNT_NAME"),
          flex: 0.07,
          minWidth: 50, 
          renderHeader: ({colDef})=>{
            if(colDef.width < 100){
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Plan Account" 
                data-mui-internal-clone-element="true">
                    {t("MyBalance.PLAN_ACCOUNT_NAME_SHORT")}
                </div>
              )
            }
            return (
              <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Plan Account" 
                data-mui-internal-clone-element="true">
                  {t("MyBalance.PLAN_ACCOUNT_NAME")}
              </div>
            )
          },
      },  
      // INV_SPEND_TYPE: { field: 'INV_SPEND_TYPE', headerName: t("MyBalance.INV_SPEND_TYPE"), flex: 1 },
      AO_TYPE: { 
          field: "AO_TYPE", 
          headerName: t("MyBalance.AO_TYPE"), 
          flex: 0.08,
          minWidth: 50, 
          renderHeader: ({colDef})=>{
            if(colDef.width <= 100){
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Acrual Object Type" 
                data-mui-internal-clone-element="true">
                    {t("MyBalance.AO_TYPE_SHORT")}
                </div>
              )
            }
            return (
              <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Acrual Object Type" 
                data-mui-internal-clone-element="true">
                  {t("MyBalance.AO_TYPE")}
              </div>
            )
          },
      },
      AO_LINE_ID: { 
          field: "AO_LINE_ID", 
          headerName: t("MyBalance.AO_LINE_ID"), 
          flex: 0.05,
          minWidth: 50, 
      },
      //AOL_KEY_ATTRIBUTES: { field: 'AOL_KEY_ATTRIBUTES', headerName: t("MyBalance.AOL_KEY_ATTRIBUTES"), flex: 1 },
      //AOL_TYPE:{ field: 'AOL_TYPE', headerName: t("MyBalance.AOL_TYPE"), flex: 1},
      BUSINESS_PARTNER_NAME: {
          field: "BUSINESS_PARTNER_NAME",
          headerName: t("MyBalance.BUSINESS_PARTNER_NAME"),
          flex: 0.10,
          minWidth: 50, 
          renderHeader: ({colDef})=>{
            if(colDef.width < 100){
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Business Partner" 
                data-mui-internal-clone-element="true">
                    {t("MyBalance.BUSINESS_PARTNER_NAME_SHORT")}
                </div>
              )
            }
            return (
              <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Business Partner" 
                data-mui-internal-clone-element="true">
                  {t("MyBalance.BUSINESS_PARTNER_NAME")}
              </div>
            )
          },
      },
      MATERIAL_NR: { 
          field: "MATERIAL_NR", 
          headerName: t("MyBalance.MATERIAL_NR"), 
          flex: 0.05,
          minWidth: 50, 
      },
      BUSINESS_SEGMENT: { 
          field: "BUSINESS_SEGMENT", 
          headerName: t("MyBalance.BUSINESS_SEGMENT"), 
          flex: 0.07,
          minWidth: 50, 
          renderHeader: ({colDef})=>{
            if(colDef.width <= 100){
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Business Segment" 
                data-mui-internal-clone-element="true">
                    {t("MyBalance.BUSINESS_SEGMENT_SHORT")}
                </div>
              )
            }
            return (
              <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Business Segment" 
                data-mui-internal-clone-element="true">
                  {t("MyBalance.BUSINESS_SEGMENT")}
              </div>
            )
          },
          renderCell: ({value})=>{
            if(!value){
              return ''
            }
            return(
              <div className="MuiDataGrid-cellContent">
               {value} - {t("BUSINESS_SEGMENT." + value, { ns: 'mars_dictionaries' })}
              </div>
            )
          }
      },
      AO_BUDGET: { 
          field: "AO_BUDGET", 
          headerName: t("MyBalance.AO_BUDGET"), 
          flex: 0.06,
          minWidth: 50, 
          cellClassName:'wrapCell',
          align: "right",
          renderCell: ({ value }) => numberFormatter(prefsData.decimal_notation).format(value).replaceAll(' ', ' '),
      },        
      CURRENCY: { 
          field: "CURRENCY", 
          headerName: t("MyBalance.CURRENCY"), 
          flex: 0.03,
          minWidth: 50, 
      },
      ACCRUALS_CALCULATED: {
          field: "ACCRUALS_CALCULATED",
          headerName: t("MyBalance.ACCRUALS_CALCULATED"),
          flex: 0.06,
          minWidth: 50, 
          renderCell: ({ value }) => <div className="MuiDataGrid-cellContent">{numberFormatter(prefsData.decimal_notation).format(value)}</div>,
          align: "right",
          renderHeader: ({colDef})=>{
            if(colDef.width <= 100){
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Accruals Calculated" 
                data-mui-internal-clone-element="true">
                    {t("MyBalance.ACCRUALS_CALCULATED_SHORT")}
                </div>
              )
            }
            return (
              <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Accruals Calculated" 
                data-mui-internal-clone-element="true">
                  {t("MyBalance.ACCRUALS_CALCULATED")}
              </div>
            )
          },
      },
      ACCRUALS_SENT: {
          field: "ACCRUALS_SENT",
          headerName: t("MyBalance.ACCRUALS_SENT"),
          flex: 0.06,
          minWidth: 50, 
          renderCell: ({ value }) => <div className="MuiDataGrid-cellContent">{numberFormatter(prefsData.decimal_notation).format(value)}</div>,
          align: "right",
          renderHeader: ({colDef})=>{
            if(colDef.width <= 100){
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Accruals Sent" 
                data-mui-internal-clone-element="true">
                    {t("MyBalance.ACCRUALS_SENT_SHORT")}
                </div>
              )
            }
            return (
              <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Accruals Sent" 
                data-mui-internal-clone-element="true">
                  {t("MyBalance.ACCRUALS_SENT")}
              </div>
            )
          },
      },
      ACCRUALS_CONFIRMED: {
          field: "ACCRUALS_CONFIRMED",
          headerName: t("MyBalance.ACCRUALS_CONFIRMED"),
          flex: 0.06,
          minWidth: 50, 
          renderCell: ({ value }) => <div className="MuiDataGrid-cellContent">{numberFormatter(prefsData.decimal_notation).format(value)}</div>,
          align: "right",
          renderHeader: ({colDef})=>{
            if(colDef.width <= 100){
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Accruals Confirmed" 
                data-mui-internal-clone-element="true">
                    {t("MyBalance.ACCRUALS_CONFIRMED_SHORT")}
                </div>
              )
            }
            return (
              <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Accruals Confirmed" 
                data-mui-internal-clone-element="true">
                  {t("MyBalance.ACCRUALS_CONFIRMED")}
              </div>
            )
          },
      },
      AO_STATUS: { 
          field: "AO_STATUS", 
          headerName: t("MyBalance.AO_STATUS"), 
          flex: 0.05,
          minWidth: 50, 
      },
      actions: {
          field: "actions",
          type: "actions",
          headerName: t("MyBalance.ACTIONS"),
          headerAlign: 'left',
          //flex: 0.05,
          width: 65,
          minWidth: 50,
          getActions: (params) => {
            
            const go_to_ao = (
              <Link
                style={{ color: "#f69e80" }}
                to={`/my-activities/accrual_object/${params.row.ao_id}`}
                className="dark-mode-link-color"
              >
                {t("MyBalance.GO_TO_AO")}
              </Link>
            )
    
            const go_to_ap = (
              <Link
                style={{ color: "#f69e80" }}
                className="dark-mode-link-color"
                to={`/accrual-balance/accrual_posting?activity_id=${params.row.ACTIVITY_ID}&spend_type=${params.row.EXT_SPEND_TYPE}`}
              >
                {t("MyBalance.GO_TO_AP")}
              </Link>
            )
    
            return [
              <CustomLinkTable
                id={params.row.id}
                links={
                  [
                    checkActivityID ? go_to_ao : null,
                    go_to_ap,
                  ].filter(t => t !== null)
                }
              />
            ]
          },
      }
    }

    return orderedColumns.length?orderedColumns.reduce((acc, field) => {
      return [...acc, allColumns[field]];
    }, []):Object.values(allColumns);
  }, [orderedColumns]);

  const objBreadcrumb = [
    { label: t("Breadcrumb.home"), link: "/" },
    { label: t("Breadcrumb.accrualBalance"), link: "" },
    { label: t("Menu.myBalance"), link: "/accrual-balance/my-balance" },
  ]

  const handleColumnOrderChange = React.useCallback((params) => {
    setOrderedColumns((prevOrderedColumns) => {
      const newOrderedColumns = [...prevOrderedColumns];
      const oldIndex = params.oldIndex;
      const targetIndex = params.targetIndex;
      const oldColumn = prevOrderedColumns[oldIndex];
      newOrderedColumns.splice(oldIndex, 1);
      newOrderedColumns.splice(targetIndex, 0, oldColumn);
      saveUserPrefsWithoutRefresh({
        ...prefsData,
        reports_column_order: {
          ...prefsData.reports_column_order,
          My_Balance: newOrderedColumns
        }
      })
      return newOrderedColumns;
    });
  }, []);

  const onChangeFontSize = (value)=>{
    setFontSize(value)
    saveUserPrefs({
      ...prefsData,
      reports_column_size:{
        ...prefsData.reports_column_size,
        My_Balance: value
      }
    })
  }

  return (
    <div className="tableContainer">
      <DataGridPro
        apiRef={apiRef}
        sx={{ 
          '& .MuiDataGrid-row': {
            minHeight: `${checkSize(fontSize, 'row_height')}px !important`,
            height: 'auto !important'
          },
          '& .MuiDataGrid-cell': {
            whiteSpace: 'nowrap',
            overflow: 'hidden'
          },
          '& .MuiDataGrid-main':{
            fontSize: checkSize(fontSize, 'font_size')
          }
        }} 
        rows={rows}
        columns={columns}
        loading={loading}
        disableSelectionOnClick={true}
        sortingOrder={['desc', 'asc']}
        disableColumnFilter
        getRowHeight={() => 'auto'}
        headerHeight={checkSize(fontSize, 'header_height')}
        onColumnOrderChange={handleColumnOrderChange}
        onRowsScrollEnd={handleOnRowsScrollEnd}
        onColumnVisibilityModelChange={saveColumnVisibility}
        onPreferencePanelClose={()=> updateUserPrefsOnClose()}
        initialState={{
          columns: {
            columnVisibilityModel:columnVisibility
          },
        }}
        localeText={{
          toolbarColumns: t("Buttons.Columns"),
          toolbarExport: t("Buttons.Export"),
          noRowsLabel: t("Common.noRows")
        }}
        components={{
          Toolbar: CustomToolbar,
          LoadingOverlay: CustomLoadingOverlay,
          Panel: CustomPanel
        }}
        componentsProps={{
          toolbar: {
            bread: objBreadcrumb,
            title: t("Title.MyBalance"),
            displayColumn: true,
            isFontSize: true,
            fontSize: fontSize,
            onChangeFontSize: onChangeFontSize,
            filters:
              <CustomMultipleFilter 
                onFilter={changeFilterValues} 
                dataFilter={filter} 
                preload={backend_url.my_balance_filter_values}
                loading={loading}
              />,
            exportSetting: {
              endpoint: backend_url.my_balance_excel_file + '?' + exportQuery,
              endpointCount: backend_url.my_accrual_excel_count + '?' + exportQuery,
              fileName: "My Balance Report"
            } 
          },
        }}
      />
    </div>
  );
}
