import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import EastIcon from '@mui/icons-material/East'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'
import { styled } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'
import { canProceed } from '@okta/okta-auth-js'
import { useFetch } from "../../hooks"
import { backend_url } from "../../settings"
import CircularProgress from '@mui/material/CircularProgress'
import SnackbarUtils from '../../utils/SnackbarUtils'


const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(0),
  },
  '& .MuiInputBase-input': {
    borderRadius: 5,
    width: '18.5px !important',
    height: '18px !important',
    minHeight: '18px !important',
    position: 'relative',
    border: '2px solid #ced4da',
    padding: '1px !important',
    transition: theme.transitions.create(['border-color', 'opacity']),
    '&:focus': {
      borderColor: '#e59073',
      boxShadow: "none",
    }
  },
}))


function CustomLinkTableActions ({ id, links, adjustments=false, postingList = false, updateState, state, updatePreventState }) {

  const value_bu = localStorage.getItem("bu_bpx")
  const tokenBpxRequest = localStorage.getItem("token_bpx")
  
  const { t } = useTranslation('translation')
  const [option, setOption] = useState("")

  const request = useFetch()
  const [trigger, setTrigger] = useState(state)

  useEffect(() => {}, [trigger])

  const adjustmentLinks = links.map((link, index) =>
      <div key={index}>
        <MenuItem className='customOptionTable' key={index} value={index}>
          <EastIcon sx={{ color: "#696969", height: "0.9rem", marginRight: "3px" }} />
          <span>{link}</span>
        </MenuItem>
        {(link.props.children !== t("Adjustments.EXECUTE") && link.props.children !== t("Adjustments.ADJUSTMENTS_LOGS") && link.props.children !== t("Adjustments.ACCRUAL_POSTINGS_PP1")) }
      </div>
  );

  const listLinks = links.map((link, index) =>
      <MenuItem className='customOptionTable' key={index} value={index} style={{ padding: "0 0 0 16px"}}>
        <EastIcon sx={{ color: "#696969", height: "0.9rem", marginRight: "3px" }} />
        <span className='customOptionTableSpan'>{link}</span>
      </MenuItem>
  );

  const handleChange = (e) => {
      setOption(e.target.value)
  }

  const handleUpdateTablePosting = () => {
    updateState(!trigger)
  }

  const displayStatus = adjustments ? adjustmentLinks : listLinks

  return [
    <React.Fragment key={id} >
      <FormControl size="small">
        {/* <InputLabel className="customLabelTable">{t("BPList.ACTIONS")}</InputLabel> */}
        <Select
          className='custom-link-table'
          value={option}
          variant="outlined"
          onOpen={(event) => {
            
            if (postingList) {
              request.fetchData(
                `${backend_url.staging_accrual_posting_header_status}/${id}?business_unit=${value_bu}`,
                "GET",
                null,
                false,
                tokenBpxRequest,
                false
              )
              .then(data => {
                
                if (data.length) {

                  data.map(item => {
                    const { name, status } = item

                    if (status === "new" || status === "processing") {
                      updatePreventState({
                        action: true,
                        inProgress: name,
                        statusText: status
                      })
                    }

                    if (status === "closed") {
                      handleUpdateTablePosting()
                      setTrigger(!trigger)
                    }
                    
                  })
  
                } else {
                  updatePreventState({
                    action: false,
                    inProgress: "",
                    statusText: ""
                  })
                }

              })
              .catch(err => {
                updatePreventState({
                  action: false,
                  inProgress: "",
                  statusText: ""
                })
                SnackbarUtils.error(JSON.stringify("Something is wrong with the server"))
              })

            }

          }}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "right"
            },
          }}
          // onChange={handleChange}
          IconComponent={(props) =><DoubleArrowIcon fontSize='small' {...props} />}
          input={<BootstrapInput />}
        >
          { displayStatus }
        </Select>
      </FormControl>
    </React.Fragment>
  ]


}

export default CustomLinkTableActions
