import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Box from '@mui/material/Box'
import { DataGridPro, GridOverlay } from '@mui/x-data-grid-pro'
import Button from '@mui/material/Button'
import { LinearProgress, Typography } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { useTranslation } from 'react-i18next'
import { useFetch } from '../../hooks'
import { getParam } from '../../utils/utils'
function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  )
}

function DialogChangeLogs({ open, onCloseHandle, data, dataLogs }) {

  const { url, endpoint, columns, title, value, endpointName, checkToHide, prevHide, skip, limit } = dataLogs
  
  const { t } = useTranslation('translation')
  const request = useFetch()
  const value_bu = localStorage.getItem('bu_bpx')
  const tokenBpxRequest = localStorage.getItem('token_bpx')

  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0)
  const [count, setCount] = useState(0);
  const [columnVisibility, setColumnVisibility] = useState(prevHide || {})

  const [loadBtn, setLoadBtn] = useState(false)

  const createUrl = () => {
    const obj = {}
    endpoint.map(it => {
      obj[it.field] = data[it.name]
    })
    return Object.values(obj).join('/');
  }


  useEffect(() => {
    setLoading(true)
    if (open) {
      const queryParams = {
        business_unit: value_bu,
        limit,
        skip: page,
      }

      const searchParam = getParam(queryParams).toString()

      request.fetchData(
        `${url}/${createUrl()}${endpointName}?${searchParam}`,
        'GET',
        null,
        false,
        tokenBpxRequest,
        false
      )
        .then(data => {
          if (value === 'AO_ID') {
            if (data.length && !data.map(it => it.Version).filter(it => it.includes('_1')).length) {
              setLoadBtn(true)
            } else {
              setLoadBtn(false)
            }

            setRows(prev => [...prev, ...data.map((it, id) => {
              const Version = it.ID.length ? {
                VERSION: it.Version,
                MATERIAL_NR: it.ID
              } :
                {
                  VERSION: it.Version
                }
              return (
                {
                  ...it,
                  id: prev.length + id,
                  Version
                }
              )
            })])
          } else {
            setRows(prev => [...prev, ...data])
          }


          setCount(data.length);
          if (data.length) {
            const hiddenColumn = {}
            checkToHide.map(itemCheck => {
              if (!data.map(it => it[itemCheck]).filter(it => !!it).length) {
                hiddenColumn[itemCheck] = false;
              }
            })
            setColumnVisibility(hiddenColumn)
          } else {
            setColumnVisibility({})
          }
          setLoading(false)
        })
        .catch(() => {
          setRows([]);
          setLoading(false);
        })
    } else {
      setPage(0)
      setRows([])
    }
  }, [open, page])

  const handleOnRowsScrollEnd = (params) => {
    if (params.viewportPageSize && value !== 'AO_ID') {
      if (count >= limit) {
        return setPage(page + skip)
      } else {
        return null
      }
    }
  }

  const displayBlockForm = () => {

    return (
      <>
        <DialogTitle sx={{ textAlign: "center", paddingBottom: 0, paddingTop: '32px' }}>
          <HighlightOffIcon sx={{ cursor: 'pointer', float: 'right', color: "#9d9d9d" }} onClick={onCloseHandle} />
          <Typography sx={{ textAlign: "center", fontWeight: 'bold' }} id="modal-modal-title" variant="body1">
            {t("CHANGE_LOGS.Title")}</Typography>
          <hr />

        </DialogTitle>
        <DialogContent>
          <p style={{ margin: '0', marginTop: "10px", fontSize: '12px' }}>{title}: <strong>{data[value]}</strong></p>
          <br />
          <DataGridPro
            sx={{ '& .MuiDataGrid-main': { height: rows.length < 8 ? '100%' : '50vh' } }}
            autoHeight={Boolean(rows.length < 8)}
            className='bpx-change-log'
            rows={rows}
            columns={columns}
            loading={loading}
            experimentalFeatures={{ newEditingApi: true }}
            columnVisibilityModel={columnVisibility}
            onRowsScrollEnd={handleOnRowsScrollEnd}
            hideFooter={true}
            components={{
              LoadingOverlay: CustomLoadingOverlay
            }}
            localeText={{
              noRowsLabel: t("Common.noRows")
            }}
          />
          {
            loadBtn &&
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}>
              <Button
                sx={{ marginTop: '10px', }}
                onClick={() => setPage(p => p + skip)}
              >
                {t("MODAL.BUTTONS.LOAD_MORE")}
              </Button>
            </Box>

          }

        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseHandle} autoFocus variant="outlined" color="primary">
            {t("MODAL.BUTTONS.CLOSE")}
          </Button>
        </DialogActions>
      </>
    )
  }


  return (
    <Dialog
      open={open}
      onClose={onCloseHandle}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ '& .MuiDialog-paper': { maxWidth: '80%', width: '100%' } }}
    >
      {displayBlockForm()}
    </Dialog>
  )
}

export default DialogChangeLogs;
