import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { backend_url } from '../../settings'
import { FlexibleTabs, NumberLinearProgress, DialogChangeLogs } from '../../components'
import { useTranslation } from 'react-i18next'
import { useFetch, usePermissions } from '../../hooks'
import { dateTimeFormatter } from '../../utils/utils'
import { useUserPrefs } from '../UserPreferences/ProvideUserPrefs'
import { checkObjectKey } from "../../utils/utils"

import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';

import RouterBlocker from 'react-route-blocker'


export default function BPDetail() {
  
  const { t } = useTranslation('translation')
  const value_bu = localStorage.getItem('bu_bpx')
  const tokenBpxRequest = localStorage.getItem('token_bpx')
  const userRoles = localStorage.getItem('userRoles')?localStorage.getItem('userRoles').split(","):''

  const { prefsData } = useUserPrefs()
  const [dataChannel, setDataChannel] = useState({
    BP_CHANNEL: '',
    BP_SUB_CHANNEL: ''
  })


  let params = useParams()
  const request = useFetch()

  const { obj, childObj } = usePermissions()
  const checkChannel = checkObjectKey(obj, childObj, 'BP_Channel_and_SubChannel');

  const [data, setData] = useState([])
  const [open, setOpen] = useState(false)

  const [dataChannelWatch, setDataChannelWatch] = useState({
    BP_CHANNEL: false,
    BP_SUB_CHANNEL: false
  })
  const [checkUpload, setCheckUpload] = useState(true)

  useEffect(() => {
    request.fetchData(
      `${backend_url.bp_detail}/${params.role}/${params.id}/detail?business_unit=${value_bu}`,
      'GET',
      null,
      false,
      tokenBpxRequest
    )
    .then(data => {
      
      setData(data)
      setDataChannel({
        BP_CHANNEL: data.BP_CHANNEL||'',
        BP_SUB_CHANNEL: data.BP_SUB_CHANNEL||''
      })
    })

  }, [])

  const onSaveChannel = ()=>{
    request.fetchData(
      `${backend_url.bp_detail}/${params.role}/${params.id}/detail?business_unit=${value_bu}`,
      'PATCH',
      JSON.stringify(dataChannel),
      false,
      tokenBpxRequest, 
      true
    )
    .then(msg => {
      setCheckUpload(false)
    })
  }


  const title = data.NAME1

  const dataConvert = [
    {
      title: t('BPDetail.BASIC_DATA'),
      children: [
        {
          label: 'BP ID',
          key: 'BP_NR',
          value: data.BP_NR,
          tooltip: 'Message BP_NR',
          editable: false,
          splitWord: false,
          spacer: false,
        },
        {
          label:  t('BPDetail.BP_ROLE'),
          key: 'BP_ROLE',
          value: data.BP_ROLE,
          tooltip: 'Message BP_ROLE',
          editable: false,
          splitWord: false,
          spacer: false,
        },
      ]
    },
    {
      title: t('BPDetail.ADDRESS_DATA'),
      children: [
        {
          label: t('BPDetail.NAME'),
          key: 'NAME1',
          value: data.NAME1,
          tooltip: 'Message NAME1',
          editable: false,
          splitWord: false,
          spacer: false,
        },
        {
          label: t('BPDetail.ADDRESS'),
          key: 'STREET_HOUSE_NUM',
          value: data.STREET_HOUSE_NUM,
          tooltip: 'Message STREET_HOUSE_NUM',
          editable: false,
          splitWord: false,
          spacer: false,
        },
        {
          label: t('BPDetail.CITY'),
          key: 'CITY',
          value: data.CITY,
          tooltip: 'Message CITY',
          editable: false,
          splitWord: false,
          spacer: false,
        },
        {
          label: t('BPDetail.POSTAL_CODE'),
          key: 'POSTAL_CODE',
          value: data.POSTAL_CODE,
          tooltip: 'Message POSTAL_CODE',
          editable: false,
          splitWord: false,
          spacer: false,
        },
        {
          label: t('BPDetail.COUNTRY'),
          key: 'COUNTRY',
          value: data.COUNTRY,
          tooltip: 'Message COUNTRY',
          editable: false,
          splitWord: false,
          spacer: false,
        },
      ]
    },
    {
      title: t('BPDetail.CLASSIFICATIONS'),
      isButton: true,
      onHandleClick: onSaveChannel,
      isAccess: checkChannel,
      children: [
        {
          label: t('BPDetail.BP_CHANNEL'),
          key: 'BP_CHANNEL',
          value: dataChannel.BP_CHANNEL,
          tooltip: 'Message Status',
          editable: false,
          splitWord: false,
          type: 'singleSelect',
          valueOptions: ["BDR", "ESR", "FAR", "LAR"],
          onHandleInput: (e)=> {
            setDataChannel(pr=>({...pr,BP_CHANNEL: e.target.value}))
            setDataChannelWatch({
              ...dataChannelWatch,
              BP_CHANNEL: true
            })
            setCheckUpload(true)
          },
          onHandleClear: (e) => {
            setDataChannel(pr=>({...pr,BP_CHANNEL: ''}))
            setDataChannelWatch({
              ...dataChannelWatch,
              BP_CHANNEL: true
            })
            setCheckUpload(true)
          },
          status_id: data.BP_NR,
          endpoint: `${backend_url.bp_detail}/${data.BP_ROLE}`,
          name_component: 'Business Partner'
        },
        {
          label: t('BPDetail.BP_SUB_CHANNEL'),
          key: 'BP_SUB_CHANNEL',
          value: dataChannel.BP_SUB_CHANNEL,
          tooltip: 'Message LAST_CHANGED_ON',
          type: 'singleSelect',
          onHandleInput: (e)=> {
            setDataChannel(pr=>({...pr,BP_SUB_CHANNEL: e.target.value}))
            setDataChannelWatch({
              ...dataChannelWatch,
              BP_SUB_CHANNEL: true
            })
            setCheckUpload(true)
          },
          onHandleClear: (e) => {
            setDataChannel(pr=>({...pr,BP_SUB_CHANNEL: ''}))
            setDataChannelWatch({
              ...dataChannelWatch,
              BP_SUB_CHANNEL: true
            })
            setCheckUpload(true)
          },
          valueOptions: [
            "Cash & Carry",
            "Direct Small Format",
            "Eurocash Group",
            "Indirect E/C",
            "Indirect Local Retail",
            "Indirect RH",
            "LW Direct",
            "E-Commerce",
            "Pet Trade Wholesale",
            "Drugstores",
            "Hypers & Supers",
            "Hard Discounters",
            "No Food Discounters",
            "Soft Discounters"
          ],
          editable: false,
          splitWord: false,
        }
      ]
    },
    {
      title: t('BPDetail.PARAMETERS'),
      children: [
        {
          label: 'Status',
          key: 'BP_STATUS',
          value: data.BP_STATUS,
          tooltip: 'Message Status',
          editable: false,
          splitWord: false,
          status_id: data.BP_NR,
          endpoint: `${backend_url.bp_detail}/${data.BP_ROLE}`,
          name_component: 'Business Partner'
        },
        {
          label: t('BPDetail.LAST_CHANGED_ON'),
          key: 'LAST_CHANGED_ON',
          value: data.LAST_CHANGED_ON && dateTimeFormatter(data.LAST_CHANGED_ON, prefsData.date_format, prefsData.time_format, prefsData.time_zone, true),
          tooltip: 'Message LAST_CHANGED_ON',
          editable: false,
          splitWord: false,
          spacer: true,
        },
        {
          label: t('BPDetail.LAST_CHANGED_BY'),
          key: 'LAST_CHANGED_BY',
          value: data.LAST_CHANGED_BY,
          tooltip: 'Message LAST_CHANGED_BY',
          editable: false,
          splitWord: false,
        },
      ]
    },
  ]

  const dataColumns = {
    relative_distribution_rates: [
      { 
        field: 'BP_DISTRIBUTOR_NR',
        headerName: t('BPDetail.RDR.BP_Distributor_Nr'), 
        flex: 1, 
        minWidth: 230,
        renderCell: (params) => {
          if (params.value) {
            return (
              <span>
                  {params.row.BP_DISTRIBUTOR_NR} - {params.row.BP_DISTRIBUTOR_NAME}
              </span>  
            )
          }
        } 
      },
      { 
        field: 'BP_DISTRIBUTOR_ROLE',
        headerName: t('BPDetail.RDR.BP_Distributor_Role'), 
        flex: 1, 
        minWidth: 50
      },
      { 
        field: 'RDR', 
        headerName: t('BPDetail.RDR.Relative_Distribution_Rate'), 
        flex: 1, 
        minWidth: 230,
        renderCell: (params) => {
          if (params.value) {
            return <NumberLinearProgress value={params.value}/>
          }
        }
      },
      { 
        field: 'VALID_FROM', 
        headerName: t('BPDetail.RDR.Valid_from'), 
        flex: 1, 
        minWidth: 230,  
      }
    ],
    master_company_codes: [
      { 
        field: 'COMPANY_CODE', 
        headerName: t('BPDetail.CC.COMPANY_CODE'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'POSTING_BLOCK', 
        headerName: t('BPDetail.CC.POSTING_BLOCK'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'COMPANY_CODE_DELETION_FLAG', 
        headerName: t('BPDetail.CC.COMPANY_CODE_DELETION_FLAG'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'RECONCILIATION_ACCOUNT', 
        headerName: t('BPDetail.CC.RECONCILIATION_ACCOUNT'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'ACCOUNT_NUMBER', 
        headerName: t('BPDetail.CC.ACCOUNT_NUMBER'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'CUSTOMER_OR_VENDOR_SHIPP_ACC_NUM', 
        headerName: t('BPDetail.CC.CUSTOMER_OR_VENDOR_SHIPP_ACC_NUM'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'ALTERNATIVE_PAYEE_ACC_NUM', 
        headerName: t('BPDetail.CC.ALTERNATIVE_PAYEE_ACC_NUM'), 
        flex: 1, 
        minWidth: 230 
      },
    ],
    master_partner_functions: [
      { 
        field: 'BUSINESS_AREA', 
        headerName: t('BPDetail.PF.BUSINESS_AREA'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'PARTNER_FUNCTION', 
        headerName: t('BPDetail.PF.PARTNER_FUNCTION'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'PARTNER_COUNTER', 
        headerName: t('BPDetail.PF.PARTNER_COUNTER'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'BUSINESS_PARTNER_CUST_NUM', 
        headerName: t('BPDetail.PF.BUSINESS_PARTNER_CUST_NUM'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'DEFAULT_PARTNER', 
        headerName: t('BPDetail.PF.DEFAULT_PARTNER'), 
        flex: 1, 
        minWidth: 230 
      },
    ],
    master_sales_data: [
      { 
        field: 'BUSINESS_AREA', 
        headerName: t('BPDetail.SD.BUSINESS_AREA'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'SALES_DELETION_FLAG', 
        headerName: t('BPDetail.SD.SALES_DELETION_FLAG'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'PRICING_PROCEDURE', 
        headerName: t('BPDetail.SD.PRICING_PROCEDURE'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'CUSTOMER_GROUP', 
        headerName: t('BPDetail.SD.CUSTOMER_GROUP'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'SALES_DISTRICT', 
        headerName: t('BPDetail.SD.SALES_DISTRICT'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'PRICE_GROUP', 
        headerName: t('BPDetail.SD.PRICE_GROUP'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'PRICE_LIST_TYPE', 
        headerName: t('BPDetail.SD.PRICE_LIST_TYPE'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'SHIPPER_ACCOUNT_NUM', 
        headerName: t('BPDetail.SD.SHIPPER_ACCOUNT_NUM'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'CURRENCY', 
        headerName: t('BPDetail.SD.CURRENCY'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'CUSTOMER_ACCOUNT_ASSIGNMENT', 
        headerName: t('BPDetail.SD.CUSTOMER_ACCOUNT_ASSIGNMENT'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'TERMS_OF_PAYMENT_KEY', 
        headerName: t('BPDetail.SD.TERMS_OF_PAYMENT_KEY'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'DELIVERING_PLANT', 
        headerName: t('BPDetail.SD.DELIVERING_PLANT'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'SALES_GROUP', 
        headerName: t('BPDetail.SD.SALES_GROUP'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'SALES_OFFICE', 
        headerName: t('BPDetail.SD.SALES_OFFICE'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'EXCHANGE_RATE_TYPE', 
        headerName: t('BPDetail.SD.EXCHANGE_RATE_TYPE'), 
        flex: 1, 
        minWidth: 230 
      },
    ],
    master_tax_indicators: [
      { 
        field: 'DEPARTURE_COUNTRY', 
        headerName: t('BPDetail.TI.DEPARTURE_COUNTRY'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'TAX_CATEGORY', 
        headerName: t('BPDetail.TI.TAX_CATEGORY'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'TAX_CLASSIFICATION', 
        headerName: t('BPDetail.TI.TAX_CLASSIFICATION'), 
        flex: 1, 
        minWidth: 230 
      },
    ]
  }


  const accordion = [
    { 
      id: "1", 
      label: t('BPDetail.Tabs.relative_distribution_rates'), 
      dataKey: 'relative_distribution_rates', 
      fetching: true,
      notFetchingExpand: true,
      url: `${backend_url.relative_distribution_rates}/${params.role}/${params.id}?business_unit=${value_bu}`,
      CRUD: true,
      endpointCRUD: backend_url.relative_distribution_rates,
    },
    { 
      id: "2", 
      label: t('BPDetail.Tabs.masterCompany'), 
      dataKey: 'master_company_codes', 
      idField: 'COMPANY_CODE', 
      collapse: 'master_company_codes',
      fetching: false
    },
    { 
      id: "3", 
      label: t('BPDetail.Tabs.masterPartner'), 
      dataKey: 'master_partner_functions',
      fetching: false 
    },
    { 
      id: "4", 
      label: t('BPDetail.Tabs.masterSalesData'), 
      dataKey: 'master_sales_data',
      fetching: false 
    },
    { 
      id: "5", 
      label: t('BPDetail.Tabs.masterTax'), 
      dataKey: 'master_tax_indicators',
      fetching: false 
    },
  ]

  const logs = {
    title:  t("CHANGE_LOGS.TitleBP"),
    value: 'BP_NR',
    url: backend_url.bp_list,
    endpoint: [{field: 'BP_ROLE', name:'BP_ROLE'}, {field: 'BP_NR', name:'BP_NR' }],
    endpointName: '/change_log',
    checkToHide: ['AE_USER_ID', 'EXT_USER_ID', 'DOC_NUMBER'],
    skip: 100, 
    limit: 100,
    prevHide: {
      "EXT_USER_ID": false,
      "DOC_NUMBER": false
    },
    columns: [
      { 
        field: 'TIMESTAMP', 
        headerName: t("CHANGE_LOGS.ChangeTime"),
        flex: 1,
        minWidth: 150,
        renderCell: ({ value }) =>{
          if(value){
            return  dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone, true, true, true)
          }
          return value
        },
      },
      { field: 'AREA', headerName: t("CHANGE_LOGS.Area"), flex: 1 },
      { field: 'FIELD_OF_CHANGE', headerName: t("CHANGE_LOGS.Attribute"), flex: 1 },
      { 
        field: 'BUSINESS_AREA_PRIMARY_KEY', 
        headerName: t("CHANGE_LOGS.PrimaryKey"), 
        minWidth: 220,
        renderCell: ({ value }) =>{

          const lines = Object.entries(value).map(it=> `${it[0]}: ${it[1]}`);
          return(
            <div style={{ whiteSpace: 'pre-line' }}>
              {lines.map((line, index) => (
                <div key={index}>{line}</div>
              ))}
            </div>
          );
        },
      },
      { field: 'FROM', headerName: t("CHANGE_LOGS.OldValue"), flex: 1 },
      { field: 'TO', headerName: t("CHANGE_LOGS.NewValue"), flex: 1 },
      { field: 'AE_USER_ID', headerName: t("CHANGE_LOGS.AeUserId"), flex: 1 },
      {
        field: 'EXT_USER_ID',
        headerName: t("CHANGE_LOGS.ExtUserId"),
        flex: 1,
       },
      { field: 'DOC_NUMBER', headerName: t("CHANGE_LOGS.MessageId"), flex: 1 },
    ]
  }


  const breadCrumb = [
    { label: t("Breadcrumb.home"), link: '/' },
    { label: t("Breadcrumb.masterData"), link: '/master-data/business_partners' },
    { label: t("Breadcrumb.businessPartners"), link: '/master-data/business_partners' },
    { label: params.id, link: '' },
  ]
  
  const handleClickChangeLog = ()=>{
    setOpen(true)
  }

  const onClose = ()=>{
    setOpen(false)
  }


  const closePopupDialog = (callback) => {
    setDataChannelWatch({
      BP_CHANNEL: false,
      BP_SUB_CHANNEL: false
    })
    return callback()
  }


  const saveInPopup = (callback) => {
    onSaveChannel()
    return callback()
  }


  return (
    <>
      <FlexibleTabs
        data={data}
        title={title}
        dataConvert={dataConvert}
        dataColumns={dataColumns}
        accordion={accordion}
        breadCrumb={breadCrumb}
        handleClickChangeLog={handleClickChangeLog}
        userRoles={userRoles}
      />
      
      <RouterBlocker
        when={
          dataChannelWatch.BP_CHANNEL && checkUpload ||
          dataChannelWatch.BP_SUB_CHANNEL && checkUpload
        }
        bodyRender={({ isActive, onCancel, onConfirm }) => {
          return (
            <Dialog
              onClose={() => closePopupDialog(onCancel)}
              aria-labelledby="customized-dialog-title"
              className='bpx-popupdialog-bg'
              open={isActive}
            >
              <div className='bpx-popupdialog-block'>

                  <div className='bpx-popupdialog-header'>
                    <div className='bpx-popupdialog-name'>
                      Business Partner Classifications Change
                    </div>
                    <CloseIcon onClick={() => closePopupDialog(onCancel)} className='bpx-popupdialog-closeicon' />
                  </div>

                  <div className='bpx-popupdialog-content'>
                    <p>Are you sure you want to leave this page?</p>
                    <p>
                      You have unsaved changes <br/>
                      in Business Partner Classifications.
                    </p>
                  </div>

                  <div className='bpx-popupdialog-footer'>
                    <Button onClick={() => closePopupDialog(onCancel)} className='bpx-popupdialog-btn' variant="outlined" size='small'>
                      Cancel
                    </Button>
                    <Button onClick={onConfirm} className='bpx-popupdialog-btn' variant="outlined" size='small'>
                      Discard Changes
                    </Button>
                    <Button onClick={() => saveInPopup(onConfirm)} className='bpx-popupdialog-btn bgc-saving' variant="contained" size='small'>
                      Save Changes
                    </Button>
                  </div>

              </div>
            </Dialog> 
          )
        }}
      />
      <DialogChangeLogs open={open} onCloseHandle={onClose} data={data} dataLogs={logs}/>
    </>   
  );
}
