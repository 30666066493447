import React, { useEffect, useState, useRef, useCallback } from 'react'
import {  TextField } from '@mui/material'
import { useFetch } from '../../../../hooks'
import { backend_url } from '../../../../settings'
import Box from '@mui/material/Box';
import { getParam } from "../../../../utils/utils"
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import debounce from 'lodash.debounce';

export default function AutoCompleteComponent({state, data, onChangeHandler, prevState}) {

  const listElem = useRef();
  const mounted = useRef();
  const { t } = useTranslation(['translation'])

  const tokenBpxRequest = localStorage.getItem('token_bpx')
  const request = useFetch()

  const { required, label } = data;
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [valueOption, setValueOption] = useState([{
    CUSTOMER_HIERARCHY: '',
    BUSINESS_PARTNER: 'all',
    type: 'all',
    name: t("UserDetail.BUSINESS_PARTNER")
  }])
  const [loadingOption, setLoadingOption] = useState(false);
  const [position, setPosition] = useState(0);
  const [page, setPage] = useState(0);
  const [ valueOptionsPartner, setValueOptionsPartner] = useState('');
  const [ isCustomer, setIsCustomer ] = useState(true);

  useEffect( () => {
    if (!mounted.current){
       mounted.current = true;
    }
    else if (position && listElem.current) {
      listElem.current.scrollTop = position - listElem.current.offsetHeight;
    } 
  })


  useEffect(()=>{
    if(page>0){
        getOptionsCustomer(valueOptionsPartner, true)
    } 
  },[page]);


  useEffect(()=>{
    if(valueOptionsPartner){
      getOptionsCustomer(valueOptionsPartner)
    }
  },[valueOptionsPartner]);


  useEffect(()=>{
    if(prevState){
      setSelectedOptions(prevState);
    } else {
      setSelectedOptions([]);
    }

  },[prevState])

  const getUrl = (endpointForGet, params=[], skip=0)=>{
    let param = {
      limit: 20,
      skip
    };

    if(endpointForGet){
      endpointForGet.forEach(it=>{
        if(localStorage.getItem(it.name)){
          param[it.field] = localStorage.getItem(it.name);
        }
      })
    }
    if(params){
      params.map(it=>{
        param[it.field] = it.value;
        return it;
      })
    }
    const queryString = getParam(param).toString();
    return '?'+queryString;
  }

  const fetchOptionsCustomer = (value, isAdd)=>{
    return request.fetchData(
      backend_url.cust_hier_roots_list + getUrl([{field:'business_unit', name:'bu_bpx' }],[{field: 'parent_bp_nr', value}, {field: 'date', value: moment(new Date()).format("YYYY-MM-DD")}], page),
      'GET',
      null,
      false,
      tokenBpxRequest,
      false,
      false
    ).then(data=>{
      if(!data.length){
        setIsCustomer(false);
        setPage(0);
        return []
      }
      const prevValueOption = isAdd ? valueOption: []
      let  newArr = [];
        data.map(it=>{
          if(
            !newArr.filter(item=> item.CUSTOMER_HIERARCHY===it.PARENT_BP_NR).length&&
            !prevValueOption.filter(item => item.CUSTOMER_HIERARCHY===it.PARENT_BP_NR).length
            ){
            newArr.push({CUSTOMER_HIERARCHY: it.PARENT_BP_NR,BUSINESS_PARTNER:it.PARENT_BP_NR, type:'CUSTOMER_HIERARCHY', name: it.PARENT_BP_NAME});
          } 
          if(
            !newArr.filter(item=> item.CUSTOMER_HIERARCHY===it.PARENT_BP_NR&&it.BP_NR===item.BUSINESS_PARTNER).length&&
            !prevValueOption.filter(item=> item.CUSTOMER_HIERARCHY===it.PARENT_BP_NR&&it.BP_NR===item.BUSINESS_PARTNER).length
          ){
            newArr.push({CUSTOMER_HIERARCHY: it.PARENT_BP_NR,BUSINESS_PARTNER:it.BP_NR, type: 'BUSINESS_PARTNER', name: it.bp.NAME1, parentName: it.PARENT_BP_NAME });
          }    
          return it;      
        })
        return newArr;
    })
    .catch(()=>[])
  }

  const fetchOptionsPartners = (value, prevCust, isAdd)=>{
    return  request.fetchData(
      backend_url.cust_hier + getUrl([{field:'BUSINESS_UNIT', name:'bu_bpx' }],[{field: 'BP_NR', value}, {field: 'date', value: moment(new Date()).format("YYYY-MM-DD")}], page),
      'GET',
      null,
      false,
      tokenBpxRequest,
      false,
      false
    )
    .then(data=>{
      let  newArr = [];
      const prevValueOption = isAdd ? valueOption: []
      data.map(it=>{
  
        if(
          !newArr.filter(item=> item.CUSTOMER_HIERARCHY===it.PARENT_BP_NR).length&&
          !prevValueOption.filter(item => item.CUSTOMER_HIERARCHY===it.PARENT_BP_NR).length&&
          !prevCust.filter(item => item.CUSTOMER_HIERARCHY===it.PARENT_BP_NR).length
          ){
            newArr.push({CUSTOMER_HIERARCHY: it.PARENT_BP_NR,BUSINESS_PARTNER:it.PARENT_BP_NR, type: 'CUSTOMER_HIERARCHY', name: it.PARENT_BP_NAME});
        } 
        if(
          !newArr.filter(item=> item.CUSTOMER_HIERARCHY===it.PARENT_BP_NR&&it.BP_NR===item.BUSINESS_PARTNER).length&&
          !prevValueOption.filter(item=> item.CUSTOMER_HIERARCHY===it.PARENT_BP_NR&&it.BP_NR===item.BUSINESS_PARTNER).length&&
          !prevCust.filter(item=> item.CUSTOMER_HIERARCHY===it.PARENT_BP_NR&&it.BP_NR===item.BUSINESS_PARTNER).length
          ){
            newArr.push({CUSTOMER_HIERARCHY: it.PARENT_BP_NR,BUSINESS_PARTNER:it.BP_NR, type: 'BUSINESS_PARTNER', name: it.bp.NAME1, parentName: it.PARENT_BP_NAME });
        }   
        return it       
      })
      return newArr
    })
    .catch(()=>[])
  }

  const fetchOptionsBps = (value, prevCust, isAdd)=>{
    return  request.fetchData(
      backend_url.bp_list + getUrl([{field:'business_unit', name:'bu_bpx' }],[{field: 'BP_NR', value}, {field: 'date', value: moment(new Date()).format("YYYY-MM-DD")}], page),
      'GET',
      null,
      false,
      tokenBpxRequest,
      false,
      false
    ).then(data=>{
      const newArr = []
      const prevValueOption = isAdd ? valueOption: []
      data.map(it=>{
        if(
          !newArr.filter(item=> it.BP_NR===item.BUSINESS_PARTNER).length&&
          !prevValueOption.filter(item=> it.BP_NR===item.BUSINESS_PARTNER).length&&
          !prevCust.filter(item => it.BP_NR === item.BUSINESS_PARTNER).length
          ){
            if(it.ACCOUNT_GROUP==='0012'){
              newArr.push({CUSTOMER_HIERARCHY:it.BP_NR, BUSINESS_PARTNER:it.BP_NR, type: 'CUSTOMER_HIERARCHY', name: it.NAME1, parentName: 'None' });
            }else{
              newArr.push({CUSTOMER_HIERARCHY:'None',BUSINESS_PARTNER:it.BP_NR, type: 'BUSINESS_PARTNER', name: it.NAME1, parentName: 'None' });
            }
            
        }   
      })
      return newArr
    })
    .catch(()=>[])
  }



  const getOptionsCustomer = async (value, isAdd=false) => { 
    setLoadingOption(true);
    
    const customers = []
    if(isCustomer){
      customers.push(...await fetchOptionsCustomer(value, isAdd))
    } 
    console.log(customers)
    if(!customers.length){      
      customers.push(...await fetchOptionsPartners(value, customers, isAdd))
      customers.push(...await fetchOptionsBps(value, customers, isAdd))
    }
    if(isAdd){
      setValueOption(arr=>[...arr, ...customers])
    }else if(customers.length){
      setValueOption(customers)
    }

    setLoadingOption(false);
  }

  const changeHandler = (event, newValue)=>{
    setPage(0);
    setIsCustomer(true)
        if (newValue.length < 3) {
          setValueOptionsPartner('')
          setValueOption([
            {
              CUSTOMER_HIERARCHY: '',
              BUSINESS_PARTNER: 'all',
              type: 'all',
              name: t("UserDetail.BUSINESS_PARTNER")
            }
          ])
        } else {
          setValueOptionsPartner(newValue)
        }
  }

  const debouncedChangeHandler = useCallback(
    debounce(changeHandler, 500)
  , []);


  const handleChange = (value)=>{
    const chValues = value.filter(it => it.type === "CUSTOMER_HIERARCHY");
    const bpValues = value
      .filter(it =>
        it.type === 'BUSINESS_PARTNER' ||
        it.type === 'all')
      .filter(it => !chValues.map(it => it.CUSTOMER_HIERARCHY).includes(it.CUSTOMER_HIERARCHY));
    const allValues = value.filter(it => it.type === 'all');

    if (allValues.length) {

      setSelectedOptions(allValues);
      onChangeHandler('CUSTOMER_HIERARCHY', [])
      onChangeHandler('BUSINESS_PARTNER', allValues.map(it => it.BUSINESS_PARTNER))
    } else {

      onChangeHandler('CUSTOMER_HIERARCHY', chValues.map(it => it.BUSINESS_PARTNER))
      onChangeHandler('BUSINESS_PARTNER', bpValues.map(it => it.BUSINESS_PARTNER))
      setSelectedOptions([...chValues, ...bpValues])
    }

    setValueOptionsPartner('')
  }

  return (
    <Autocomplete
      value={selectedOptions}
      loading={loadingOption}
      loadingText='Loading…'
      id="grouped-demo"
      className='bpx-userid-select'
      multiple
      ListboxProps={{
        ref: listElem,
        onScroll: ({ currentTarget }) => {
          const scrollPosition = currentTarget.scrollTop + currentTarget.clientHeight;
          if (currentTarget.scrollHeight - scrollPosition <= 1 && !loadingOption) {
            setLoadingOption(true);
            setPosition(scrollPosition);
            setPage(pg => pg + 20);
          }
        }
      }}

      options={valueOption.slice().sort((a, b) => {
        if (a.CUSTOMER_HIERARCHY < b.CUSTOMER_HIERARCHY) return -1;
        if (a.CUSTOMER_HIERARCHY > b.CUSTOMER_HIERARCHY) return 1;
        return 0;
      })
        .sort((a, b) => {
          if (a.CUSTOMER_HIERARCHY === b.CUSTOMER_HIERARCHY) {
            return a.BUSINESS_PARTNER === a.CUSTOMER_HIERARCHY ? -1 : 1
          }
          return 0
        })}

      groupBy={(option) => option.CUSTOMER_HIERARCHY}

      getOptionLabel={(option) => {
        if (option.type === 'CUSTOMER_HIERARCHY') {
          return `Customer: ${option.CUSTOMER_HIERARCHY}, name: ${option.name}`
        } else {
          return `Customer: ${option.CUSTOMER_HIERARCHY}, ${t("UserDetail.BUSINESS_PARTNER")}: ${option.BUSINESS_PARTNER},  name: ${option.name}, parentName: ${option.parentName}`
        }
      }}

      isOptionEqualToValue={(option, value) => {
        return option.BUSINESS_PARTNER === value.BUSINESS_PARTNER
      }}

      renderInput={(params) =>
        <TextField   
          {...params}
          label={label}
          size="small"
          inputProps={{
            ...params.inputProps,
            required: selectedOptions.length === 0
          }}
          required={required}
        />
      }

      renderGroup={(params) => {
        const parent = valueOption.find(it => it.BUSINESS_PARTNER === params.group);
        let name = ''
        if (parent) {
          name = ` - ${parent.name}`
        }
        return (
          <li key={params.key}>
            <Box sx={{ color: 'primary.dark', paddingLeft: '10px' }}>
            {t("UserDetail.CUSTOMER_HIERARCHY")} : {params.group}{name}
            </Box>
            <Box>
              <ul>
                {params.children}
              </ul>
            </Box>
          </li>
        )
      }}

      getOptionDisabled={(option) => (
        option.BUSINESS_PARTNER !== 'all' &&
        state.BUSINESS_PARTNER.includes('all')) ||
        state.CUSTOMER_HIERARCHY.includes(option.CUSTOMER_HIERARCHY
        )}

      renderOption={(props, option, { selected }) => (
        <li {...props} >
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={
              state.BUSINESS_PARTNER.includes(option.BUSINESS_PARTNER) ||
              state.CUSTOMER_HIERARCHY.includes(option.BUSINESS_PARTNER) ||
              state.BUSINESS_PARTNER.includes('all') ||
              state.CUSTOMER_HIERARCHY.includes(option.CUSTOMER_HIERARCHY)
            }
          />
          <span><b>{option.BUSINESS_PARTNER}</b> - {option.name}</span>
        </li>
      )}

      renderTags={(values, getTagProps, ownerState) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
          {
            values.map((value, ind) => (
              <Chip
                key={ind}
                sx={{
                  height: 'auto',
                  '& .MuiChip-label': {
                    display: 'block',
                    whiteSpace: 'normal',
                  },
                }}
                onDelete={() => {
                  const newValue = values.filter(entry => entry.BUSINESS_PARTNER !== value.BUSINESS_PARTNER);
                  handleChange(newValue);
     
                }}
                label={
                  value.type === 'BUSINESS_PARTNER' ?
                    `${value.BUSINESS_PARTNER} - ${t("UserDetail.BUSINESS_PARTNER")} - ${value.name}`:
                    value.type === 'all' ?
                      `${value.BUSINESS_PARTNER} - ${t("UserDetail.BUSINESS_PARTNER")}` :
                      `${value.BUSINESS_PARTNER} - ${t("UserDetail.CUSTOMER_HIERARCHY")} - ${value.name}`
                }
              />
            ))
          }
        </Box>
      )}

      onInputChange={debouncedChangeHandler}

      onChange={(event, value) => {
        handleChange(value)
      }}
    />
  )
}
