import React, { useState } from 'react'
import { LoggedIn } from '../../../../components'
import { Box, Button } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person';
import { useTranslation } from 'react-i18next'
import FontSize from '../../../../components/CustomToolbar/FontSize'

function Toolbar(props) {

  const { 
    bread, 
    title, 
    displayColumn = false, 
    filters,
    handleClickOpen,
    isFontSize=false,
    fontSize=null,
    onChangeFontSize=()=>{}
  } = props 
 
  const { t } = useTranslation()

  function displayColumnAndExport () {

    if (displayColumn) {
      return (
        <div className='toolbarSection toolbarIconsBlock'>

          <div className='toolbarIconSection toolbarGridColumnsButton'>
            <button onClick={handleClickOpen}>
              <span className="MuiButton-startIcon" >
                <PersonIcon sx={{ fontSize: "18px" }} /> 
              </span>      
               {t("MODAL.BUTTONS.NEW_USER")}         
              <span className="MuiTouchRipple-root">
               
              </span>
            </button>
            </div>
        </div>
      )
    }
    
  }

  
  
  return (
    <div className='toolbarContainerBlock'>
      
      <div className='toolbarborderBlock'>
        <div className='toolbarBlock'>
          <LoggedIn breadcrumbs={bread} />
          { displayColumnAndExport() }
        </div>
      </div>

      {
        isFontSize?
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <h2 className='toolbarTitle'>{title}</h2> 
          <FontSize size={fontSize} onChange={onChangeFontSize}/> 
        </Box>:
        <h2 className='toolbarTitle'>{title}</h2>  
      } 

      { filters && (<Box sx={{ mb: 2 }}>{filters}</Box>) }
      
    </div>
  )
}

export default Toolbar
