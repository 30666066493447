import React, { useState } from 'react'
import { LoggedIn } from '../../../../components'
import { Box, Button } from '@mui/material'
import ModalResetPassword from '../Modal/ModalResetPassword'
import { useTranslation } from 'react-i18next'
import LockResetIcon from '@mui/icons-material/LockReset';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import FontSize from '../../../../components/CustomToolbar/FontSize'

function ToolbarDetail(props) {

  const { 
    bread, 
    title, 
    displayColumn = false, 
    filters,
    Component,
    userData,
    warning,
    handleClickAddNewRole,
    handleClickChangeUserData,
    isFontSize=false,
    fontSize=null,
    onChangeFontSize=()=>{}
  } = props

  const { t } = useTranslation()
  
  const [open, setOpen] = useState(false)

  function displayColumnAndExport () {

    if (displayColumn) {
      return (
        <div className='toolbarSection toolbarIconsBlock'>
          <div className='toolbarIconSection toolbarGridColumnsButton'>
              <button onClick={handleClickChangeUserData}>
                <span className="MuiButton-startIcon">
                  <ManageAccountsIcon sx={{ fontSize:  "1rem" }} />  
                </span>
                {t("MODAL.BUTTONS.EDIT_USER")}
                <span className="MuiTouchRipple-root"></span>
              </button>
            </div>
          <div className='toolbarIconSection toolbarGridColumnsButton'>
              <button onClick={()=>setOpen(true)}> 
                <span className="MuiButton-startIcon">
                  <LockResetIcon sx={{ fontSize:  "1rem" }} />  
                </span>
                {t("MODAL.BUTTONS.RESET_PASSWORD")}
                <span className="MuiTouchRipple-root"></span>
              </button>
            </div>
            <div className='toolbarIconSection toolbarGridColumnsButton'>
              <button onClick={handleClickAddNewRole}>
                <span className="MuiButton-startIcon">
                  <PlaylistAddIcon sx={{ fontSize:  "1rem" }} />  
                </span>
                {t("MODAL.BUTTONS.NEW_ROLE")}
                <span className="MuiTouchRipple-root"></span>
              </button>
            </div>
        </div>
      )
    }
    
  }

  
  return (
    <div className='toolbarContainerBlock'>
      
      <div className='toolbarborderBlock'>
        <div className='toolbarBlock'>
          <LoggedIn breadcrumbs={bread} />
          { displayColumnAndExport() }
        </div>
      </div>

      {
        isFontSize?
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <h2 className='toolbarTitle'>{title}</h2> 
          <FontSize size={fontSize} onChange={onChangeFontSize}/> 
        </Box>:
        <h2 className='toolbarTitle'>{title}</h2>  
      }  

      { filters.length && (<Box sx={{ mb: 2 }}>{filters}</Box>) }

      { Component && Component({userData, warning}) }     
      
      <ModalResetPassword open={open} handleClose={()=>setOpen(false)} idUser={userData.USER_ID}/> 
    </div>
  )
}

export default ToolbarDetail
