import { Typography } from "@mui/material"
import { dateTimeFormatter } from "../../utils/utils"
import { useUserPrefs } from "../UserPreferences/ProvideUserPrefs"

export const UserGenInfo = ({filterValue}) => {

    const { prefsData } = useUserPrefs();
    const value_bu = localStorage.getItem("bu_bpx")
    const bu_data = JSON.parse(localStorage.getItem("b_units"))
    const currentUser = JSON.parse(localStorage.getItem("current_user"))
    const generatedDate = new Date().toISOString()
    const chosen_bu = (filterValue[0].valueFilter && filterValue[0].valueFilter["BUSINESS_UNIT"]) ? filterValue[0].valueFilter["BUSINESS_UNIT"] : value_bu
    const desc = bu_data.filter((bu) => (bu.BUSINESS_UNIT === chosen_bu) && bu.DESCRIPTION)

    return (
        <div style={{marginLeft: '20px'}}>
            <Typography variant="subtitle2" component="h2">
                Generated on: <strong>{dateTimeFormatter(generatedDate, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}</strong>
            </Typography>
            <Typography variant="subtitle2" component="h2">
                Generated by: <strong>{currentUser.name} {currentUser.surname} ({currentUser.username})</strong>
            </Typography>
            <Typography variant="subtitle2" component="h2">
                Business Unit: <strong>{filterValue[0].valueFilter && filterValue[0].valueFilter["BUSINESS_UNIT"] ? filterValue[0].valueFilter["BUSINESS_UNIT"] : value_bu} {desc[0].DESCRIPTION}</strong>
            </Typography>
            <br/>
        </div>
    )
}