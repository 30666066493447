import React, { useState, useEffect, useCallback, useRef } from "react"
import { DataGridPro, GridOverlay, useGridApiRef } from "@mui/x-data-grid-pro"
import { LinearProgress } from "@mui/material"
import { backend_url } from "../../settings"
import { getParam, numberFormatter, dateTimeFormatter, checkSize } from "../../utils/utils"
import { useTranslation } from "react-i18next"
import { CustomToolbarReconciliation } from "../../components"
import CustomMultipleFilter from '../../mui-components/CustomMultipleFilter'
import CustomPanel from '../../mui-components/CustomPanel'
import { useFetch, usePermissions } from "../../hooks"
import { useUserPrefs } from "../UserPreferences/ProvideUserPrefs"
import { applyFilters } from '../../utils/utils'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import CloseIcon from '@mui/icons-material/Close'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import TextField from '@mui/material/TextField'
import SnackbarUtils from '../../utils/SnackbarUtils'
import CircularProgress from '@mui/material/CircularProgress'
import moment from "moment"



function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}

function TotalTable({ total, columnVisibility, fontSize }) {
  const { checkYear, lastRefreshed, totalTable, loading } = total

  const { t } = useTranslation('translation')
  const apiRef = useGridApiRef()
  const { prefsData } = useUserPrefs()

  const columns = [
    { 
      field: 'gsv', 
      headerName: "GSV", 
      flex: 0.15,
      minWidth: 50,
      sortable: false,
      align: 'right',
      renderCell: ({ value }) => {
        if (isNaN(value)) {
          return <div className="MuiDataGrid-cellContentw">
            <div className="trade-header-name">{value}</div>
            <div className='trade-header-separator'><svg><path d="M11 19V5h2v14z"></path></svg></div>
          </div>
        }
        return (
          <div className="MuiDataGrid-cellContent">
            {numberFormatter(prefsData.decimal_notation).format(value)}
          </div>
        )
      }
    },
    {
      field: 'niv',
      headerName: t("TradeExpeditiureMonitor.NIV"),
      minWidth: 50,
      flex: 0.15,
      hideable: false,
      sortable: false,
      align: "right",
      renderCell: ({ value }) => {
        if (isNaN(value)) {
          return <div className="MuiDataGrid-cellContentw">
            <div className="trade-header-name">{value}</div>
            <div className='trade-header-separator'><svg><path d="M11 19V5h2v14z"></path></svg></div>
          </div>
        }
        return (
          <div className="MuiDataGrid-cellContent">
            {numberFormatter(prefsData.decimal_notation).format(value)}
          </div>
        )
      }
    },
    { 
      field: 'accruals', 
      headerName: "Accruals", 
      flex: 0.15,
      minWidth: 50,
      sortable: false,
      align: 'right',
      renderCell: ({ value }) => {
        if (isNaN(value)) {
          return <div className="MuiDataGrid-cellContent">
            <div className="trade-header-name">{value}</div>
            <div className='trade-header-separator'><svg><path d="M11 19V5h2v14z"></path></svg></div>
          </div>
        }
        return (
          <div className="MuiDataGrid-cellContent">
            {numberFormatter(prefsData.decimal_notation).format(value)}
          </div>
        )
      }
    },
    { 
      field: 'payments', 
      headerName: "Payments", 
      flex: 0.15,
      minWidth: 50,
      sortable: false,
      align: 'right',
      renderCell: ({ value }) => {
        if (isNaN(value)) {
          return <div className="MuiDataGrid-cellContent">
            <div className="trade-header-name">{value}</div>
            <div className='trade-header-separator'><svg><path d="M11 19V5h2v14z"></path></svg></div>
          </div>
        }
        return (
          <div className="MuiDataGrid-cellContent">
            {numberFormatter(prefsData.decimal_notation).format(value)}
          </div>
        )
      }
    },
    { 
      field: 'balance', 
      headerName: "Balance", 
      flex: 0.15,
      minWidth: 50,
      sortable: false,
      align: 'right',
      renderCell: ({ value }) => {
        if (isNaN(value)) {
          return <div className="MuiDataGrid-cellContent">
            <div className="trade-header-name">{value}</div>
            <div className='trade-header-separator'><svg><path d="M11 19V5h2v14z"></path></svg></div>
          </div>
        }
        return (
          <div className="MuiDataGrid-cellContent">
            {numberFormatter(prefsData.decimal_notation).format(value)}
          </div>
        )
      }
    },
    { 
      field: 'number_of_accruals', 
      headerName: "# Acc. Postings", 
      flex: 0.15,
      minWidth: 50,
      sortable: false,
      align: 'right'
    },
    { 
      field: 'number_of_payments', 
      headerName: "# Payments", 
      flex: 0.15,
      minWidth: 50,
      sortable: false,
      align: 'right'
    },
  ]

  const checklastRefreshDate = lastRefreshed ? (
    <div className="bpx-totaltable-lastrefreshDate">
        <div>
          {dateTimeFormatter(
            lastRefreshed.REPORT_DATE, prefsData.date_format, prefsData.time_format, prefsData.time_zone
          )}
        </div>
    </div>
  ) : null


  return (
    <div className='bpx-totaltable-block'>
      <div className='bpx-totaltable-content'>
        <div className='bpx-totaltable-left'>
          <div className='bpx-totaltable-reportyear'>
            Reconciliation Report for Year: {checkYear}
          </div>
          <div className='bpx-totaltable-lastrefresh'>
            Last Refreshed: {checklastRefreshDate}
          </div>
        </div>
        <div className='bpx-totaltable-right'>
          <h3>Totals:</h3>
          <DataGridPro
            sx={{
              '& .MuiDataGrid-row': {
                minHeight: `${checkSize(fontSize, 'row_height')}px !important`,
                height: 'auto !important'
              },
              '& .MuiDataGrid-cell': {
                whiteSpace: 'nowrap',
                overflow: 'hidden'
              },
              '& .MuiDataGrid-main':{
                height: checkSize(fontSize, 'row_height') + checkSize(fontSize, 'header_height') + 'px',
                fontSize: checkSize(fontSize, 'font_size')
              }
            }}
            apiRef={apiRef}
            autoHeight
            rows={totalTable}
            columns={columns}
            loading={loading}
            getRowId={() => Math.random() * 1000}
            disableSelectionOnClick={true}
            hideFooterRowCount={true}
            disableColumnFilter
            columnVisibilityModel={columnVisibility}
            getRowHeight={() => 'auto'}
            initialState={{
              columns: {
                columnVisibilityModel:columnVisibility
              },
            }}
            headerHeight={checkSize(fontSize, 'header_height')}
            localeText={{
              noRowsLabel: t("Common.noRows")
            }}
            components={{
              LoadingOverlay: CustomLoadingOverlay,
            }}
          />
        </div>
      </div>

      <h3 className='bpx-totaltable-bottom-title'>
        Details: 
      </h3>
    </div>
  )

}


export default function Reconciliation() {

  const apiRef = useGridApiRef()
  const refWidth = useRef(null)

  const { t } = useTranslation("translation")
  const value_bu = localStorage.getItem("bu_bpx")
  const tokenBpxRequest = localStorage.getItem("token_bpx")

  const request = useFetch()
  
  const [rows, setRows] = useState([])
  const [page, setPage] = useState(0)
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(false)  

  const [gridWidth, setGridWidth] = useState("")

  const { prefsData, saveUserPrefs, updateUserPrefsOnClose, saveUserPrefsWithoutRefresh, silentUpdateUserPrefs } = useUserPrefs();
  // const [orderedColumns, setOrderedColumns] = useState(prefsData.reports_column_order.My_Balance ? prefsData.reports_column_order.My_Balance : []);
  const [fontSize, setFontSize] = useState(prefsData.reports_column_size&&prefsData.reports_column_size.Reconciliation || 'm');
  const columnVisibility = {};
  if(prefsData.reports_column_visibilty&&prefsData.reports_column_visibilty.Reconciliation){
   prefsData.reports_column_visibilty.Reconciliation.forEach(it=>columnVisibility[it]=false);
  }
 

  const saveColumnVisibility = (data)=>{
    saveUserPrefs({
      ...prefsData,
      reports_column_visibilty:{
        ...prefsData.reports_column_visibilty,
        Reconciliation: Object.entries(data).filter(it=>!it[1]).map(it=>it[0])
      }
    })
  }
  
  const initialLoading = JSON.parse(localStorage.getItem("rec-is-request"))
  const [endpointYear, setEndpointYear] = useState("")
  const [open, setOpen] = useState(false)
  

  const currentYear = new Date().getFullYear().toString()
  const checkYear  = applyFilters("YEAR", "reconciliationEndpoint").length ? applyFilters("YEAR", "reconciliationEndpoint") : currentYear

  const firstDayOfYear = new Date(Number(checkYear), 0, 1)
  const lastDayOfYear = new Date(Number(checkYear), 11, 31)
  const checkMaxDate = currentYear === checkYear ? new Date() : lastDayOfYear
  const checkNextYear = currentYear < checkYear ? null : checkMaxDate
  const [refreshReportDate, setRefreshReportDate] = useState(checkMaxDate)
  const [loadingReport, setLoadingReport] = useState(initialLoading ? initialLoading[checkYear] ? initialLoading[checkYear].isRequest : false : false)


  const [total, setTotal] = useState({
    checkYear: checkYear,
    lastRefreshed: "",
    totalTable: [],
    loading: true
  })

  const [filter, setFilter] = useState([
    {
      firstKeyOption: "YEAR",
      title: t("AOHeaderList.VALIDITY_FROM"),
      valueFilter: checkYear,
      formatField: "date",
      year: true,
      notQueryParam: true
    },
    {
      endpoint: backend_url.full_reconciliation_filter_values,
      queryKeyToTyping: "VERSION",
      title: "Version",
      firstKeyOption: "VERSION",
      valueFilter: {},
      changingArray: [],
      destructDisplay: [
        { id: "1", field: "Version:", valueKey: "VERSION", type: "text" },
        { id: "2", field: "", valueKey: "REPORT_DATE", type: "date" },
        { id: "3", field: "", valueKey: "REQUESTED_BY", type: "text" }
      ],
      formatField: "autoSelect",
      notQueryParam: true
    },
    {
      endpoint: `${backend_url.full_reconciliation_filter_values}`,
      queryKeyToTyping: "PLAN_ACCOUNT",
      title: t("AOHeaderList.PLAN_ACCOUNT_BP_NR"),
      inputText: t("AOHeaderList.PLAN_ACCOUNT_BP_NR_INPUT"),
      firstKeyOption: "plan_account_bp_nr",
      secondKeyOption: "plan_account_bp_name",
      valueFilter: applyFilters("PLAN_ACCOUNTS", "reconciliationList"),
      changingArray: [],
      atLeastThreeLetter: true,
      isBPUppercase: true,
      arrayObject: true,
      uppercaseRequestQueryParent: "PLAN_ACCOUNTS",
      uppercaseRequestQuery: [
        { lowercase: "plan_account_bp_nr", field: "PLAN_ACCOUNT_BP_NR", valueQuery: ""},
        { lowercase: "plan_account_bp_role", field: "PLAN_ACCOUNT_BP_CAT", valueQuery: ""}
      ],
      formatField: "select"
    },
    {
      firstKeyOption: "DATE_FROM_EXFACTORY",
      title: t("AOHeaderList.VALIDITY_FROM"),
      valueFilter: applyFilters("DATE_FROM_EXFACTORY", "reconciliationList"),
      formatField: "date"
    },
    {
      firstKeyOption: "DATE_TO_EXFACTORY",
      title: t("AOHeaderList.VALIDITY_TO"),
      valueFilter: applyFilters("DATE_TO_EXFACTORY", "reconciliationList"),
      formatField: "date"
    }
  ])

  const changeFilterValues = (state) => {
    setFilter(state)
    setRows([])
    setPage(0)
  }

  const addLoadedRows = useCallback((items) => {
    const changeIdRow = items.map((d) => {
      return {
        id: Math.random(1 * 1000), 
        ...d
      }
    })

    setRows((old) => {
      return [...old, ...changeIdRow]
    })

  }, [])


  useEffect(() => {
    
    if (endpointYear) {
      const queryParams = {
        BUSINESS_UNIT: value_bu,
        limit: 100,
        skip: page,
        filter: "VERSION"
      }
      const queryString = getParam(queryParams).toString()
      
      request.fetchData(
        `${backend_url.full_reconciliation_filter_values}/${endpointYear}?${queryString}`,
        "GET",
        null,
        false,
        tokenBpxRequest,
        false
      )
      .then(data => {

        if (data.length) {
          
          const sorted = data.reduce(function (r, a) {
            return r.REPORT_DATE > a.REPORT_DATE ? r : a;
          })

          localStorage.setItem("rec-version", JSON.stringify({
            VERSION: sorted.VERSION,
            YEAR: endpointYear
          }))
          
          setFilter(prevFilter => prevFilter.map(filterItem => {
            if (filterItem.firstKeyOption === "VERSION") {
                return {
                    ...filterItem,
                    valueFilter: sorted,
                    changingArray: data,
                };
            }
            return filterItem;
          }))
  
          setTotal(prev => ({
            ...prev,
            lastRefreshed: sorted,
            checkYear: endpointYear
          }))
          
        } else {

          localStorage.setItem("rec-version", JSON.stringify({
            VERSION: 0,
            YEAR: endpointYear
          }))
          
          setFilter(prevFilter => prevFilter.map(filterItem => {
            if (filterItem.firstKeyOption === "VERSION") {
                return {
                    ...filterItem,
                    valueFilter: {},
                    changingArray: [],
                };
            }
            return filterItem;
          }))

  
          setTotal(prev => ({
            ...prev,
            lastRefreshed: "",
            checkYear: endpointYear,
            loading: false
          }))
  
        }
        
      })
    }

  }, [endpointYear])
  

  useEffect(() => {

    let version = JSON.parse(localStorage.getItem("rec-version"))
    let previousValue = JSON.parse(localStorage.getItem("rec-is-request"))
      
    if (!previousValue) {
      previousValue = {}
    }
  
    if (open) {

      if (version && previousValue) {
        
        if (previousValue.hasOwnProperty(endpointYear)) {
          
          if (previousValue[endpointYear].isRequest) {
            if (previousValue[endpointYear].NEXTVERSION === version.VERSION) {
              localStorage.setItem("rec-is-request", JSON.stringify({
                ...previousValue,
                [endpointYear]: {
                  isRequest: false,
                }
              }))
              setLoadingReport(false)
              return
            } 
            setLoadingReport(true)
          } else {
            setLoadingReport(false)
          }
          
        } else {
          setLoadingReport(false)
        }

      } 

    }
    
    let convertDate = moment(checkNextYear).format("YYYY-MM-DD")
    setRefreshReportDate(convertDate === "Invalid date" ? null : convertDate)

  }, [open])

  useEffect(() => {
    
    setLoading(true)

    const queryParams = {
      BUSINESS_UNIT: value_bu,
      limit: 100,
      skip: page,
    }
    const queryEndpoint = {}

    filter.map((key) => {
      const { 
        valueFilter, firstKeyOption, formatField, translateJSON, notQueryParam, arrayObject, 
        uppercaseRequestQueryParent, uppercaseRequestQuery 
      } = key
      
      switch (formatField) {
        case "select":
          if (valueFilter.length) {

            if (translateJSON) {
              const getKey = valueFilter.map(jsonkey => Object.keys(jsonkey))
              queryParams[firstKeyOption] = getKey

            } else if (arrayObject) {

              const uppercasedKeys = valueFilter.map(nested => {
                const uppercased = uppercaseRequestQuery.map(child => {
                  let checkCase = Object.keys(nested).join('')

                  if (checkCase === checkCase.toUpperCase())
                    return { [child.field]: nested[child.field] }
                  else 
                    return { [child.field]: nested[child.lowercase] }
                  
                })
                
                return Object.assign({}, ...uppercased )
              })
              
              queryParams[uppercaseRequestQueryParent] = uppercasedKeys
              
            } else {
              queryParams[firstKeyOption] = valueFilter
            }
            
          }
          break
        case "date":
          if (valueFilter) {       
            if (notQueryParam) {
              queryEndpoint[firstKeyOption] = valueFilter
              if (page === 0) { setRows([]) }
              return
            }    
            queryParams[firstKeyOption] = valueFilter
          }
          break
        case "autoSelect": 
          if (valueFilter) {
            queryEndpoint[firstKeyOption] = valueFilter
          }
          break
        default:
          return null
      }
    })

    let cachedfilters = localStorage.getItem("filters")
    let filterObj = {
      reconciliationList: queryParams,
      reconciliationEndpoint: queryEndpoint
    }
    if (cachedfilters && Object.keys(cachedfilters).length) { 
        filterObj = JSON.parse(cachedfilters)
        filterObj["reconciliationList"] = queryParams
        filterObj["reconciliationEndpoint"] = queryEndpoint
    }
    localStorage.setItem("filters", JSON.stringify(filterObj))
    const queryString = getParam(queryParams, undefined, true).toString()

    setEndpointYear(filterObj.reconciliationEndpoint.YEAR)

    if (queryEndpoint.VERSION.VERSION) {

      request.fetchData(
        `${backend_url.full_reconciliation}/${filterObj.reconciliationEndpoint.YEAR}/${queryEndpoint.VERSION.VERSION}?${queryString}&TOTAL=${false}`,
        "GET",
        null,
        false,
        tokenBpxRequest,
        false
      )
      .then(data => {
        setLoading(false)
        addLoadedRows(data)
        setCount(data.length)
      })
  
      request.fetchData(
        `${backend_url.full_reconciliation}/${filterObj.reconciliationEndpoint.YEAR}/${queryEndpoint.VERSION.VERSION}?${queryString}&TOTAL=${true}`,
        "GET",
        null,
        false,
        tokenBpxRequest,
        false
      )
      .then(data => {
  
        setTotal(prev => ({
          ...prev,
          totalTable: data,
          loading: false
        }))
        
      })
      
    } else {
      setLoading(false)
    }

  }, [filter, page])

  useEffect(() => {
    return apiRef.current.subscribeEvent('columnHeaderDragEnd', (params) => {
        silentUpdateUserPrefs()
    })
  }, [apiRef])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOnRowsScrollEnd = (params) => {
    if (params.viewportPageSize&&!loading) {
      if (count >= 100) {
        return setPage(page + 100)
      } else {
        return null
      }
    }
  }

    // const columns = React.useMemo(() => {

      // const allColumns = {
      //   plan_account: { 
      //     field: 'plan_account', 
      //     headerName: "Plan Account", 
      //     flex: 0.15,
      //     minWidth: 50,
      //     sortable: false,
      //     renderCell: ({ row }) => {
      //       const { plan_account_bp_nr, plan_account_bp_name } = row
            
      //       return (
      //         <div className="MuiDataGrid-cellContent">
      //           {`${plan_account_bp_nr} - ${plan_account_bp_name}`}
      //         </div>
      //       )
      //     }
      //   },
      //   date: { 
      //     field: 'date', 
      //     headerName: "Date", 
      //     flex: 0.15,
      //     minWidth: 50,
      //     type: "date",
      //     renderCell: ({ value }) => {
      //       if (!value) {
      //         return <div className="MuiDataGrid-cellContent">{value}</div>
      //       }
      //       return (
      //         <div className="MuiDataGrid-cellContent">
      //           {dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}
      //         </div>
      //       )
      //     }
      //   },
      //   gsv: { 
      //     field: 'gsv', 
      //     headerName: "GSV", 
      //     flex: 0.15,
      //     type: "number",
      //     headerAlign: 'left',
      //     renderCell: ({ value }) => {
      //       if (isNaN(value)) {
      //         return <div className="MuiDataGrid-cellContent">
      //           <div className="trade-header-name">{value}</div>
      //           <div className='trade-header-separator'><svg><path d="M11 19V5h2v14z"></path></svg></div>
      //         </div>
      //       }
      //       return (
      //         <div className="MuiDataGrid-cellContent">
      //           {numberFormatter(prefsData.decimal_notation).format(value)}
      //         </div>
      //       )
      //     }
      //   },
      //   niv: {
      //     field: 'niv',
      //     headerName: t("TradeExpeditiureMonitor.NIV"),
      //     minWidth: 50,
      //     width: 90,
      //     //flex: 0.10,
      //     align: "right",
      //     type: "number",
      //     headerAlign: 'left',
      //     renderCell: ({ value }) => {
      //       if (isNaN(value)) {
      //         return <div className="MuiDataGrid-cellContent">
      //           <div className="trade-header-name">{value}</div>
      //           <div className='trade-header-separator'><svg><path d="M11 19V5h2v14z"></path></svg></div>
      //         </div>
      //       }
      //       return (
      //         <div className="MuiDataGrid-cellContent">
      //           {numberFormatter(prefsData.decimal_notation).format(value)}
      //         </div>
      //       )
      //     }
      //   },
      //   accruals: { 
      //     field: 'accruals', 
      //     headerName: "Accruals", 
      //     flex: 0.15,
      //     minWidth: 50,
      //     type: "number",
      //     headerAlign: 'left',
      //     renderCell: ({ value }) => {
      //       if (isNaN(value)) {
      //         return <div className="MuiDataGrid-cellContent">
      //           <div className="trade-header-name">{value}</div>
      //           <div className='trade-header-separator'><svg><path d="M11 19V5h2v14z"></path></svg></div>
      //         </div>
      //       }
      //       return (
      //         <div className="MuiDataGrid-cellContent">
      //           {numberFormatter(prefsData.decimal_notation).format(value)}
      //         </div>
      //       )
      //     }
      //   },
      //   payments: { 
      //     field: 'payments', 
      //     headerName: "Payments", 
      //     flex: 0.15,
      //     minWidth: 50,
      //     type: "number",
      //     headerAlign: 'left',
      //     renderCell: ({ value }) => {
      //       if (isNaN(value)) {
      //         return <div className="MuiDataGrid-cellContent">
      //           <div className="trade-header-name">{value}</div>
      //           <div className='trade-header-separator'><svg><path d="M11 19V5h2v14z"></path></svg></div>
      //         </div>
      //       }
      //       return (
      //         <div className="MuiDataGrid-cellContent">
      //           {numberFormatter(prefsData.decimal_notation).format(value)}
      //         </div>
      //       )
      //     }
      //   },
      //   balance: { 
      //     field: 'balance', 
      //     headerName: "Balance", 
      //     flex: 0.15,
      //     minWidth: 50,
      //     type: "number",
      //     headerAlign: 'left',
      //     renderCell: ({ value }) => {
      //       if (isNaN(value)) {
      //         return <div className="MuiDataGrid-cellContent">
      //           <div className="trade-header-name">{value}</div>
      //           <div className='trade-header-separator'><svg><path d="M11 19V5h2v14z"></path></svg></div>
      //         </div>
      //       }
      //       return (
      //         <div className="MuiDataGrid-cellContent">
      //           {numberFormatter(prefsData.decimal_notation).format(value)}
      //         </div>
      //       )
      //     }
      //   },
      //   number_of_accruals: { 
      //     field: 'number_of_accruals', 
      //     headerName: "# Acc. Postings", 
      //     flex: 0.15,
      //     minWidth: 50,
      //     type: "number",
      //     headerAlign: 'left'
      //   },
      //   number_of_payments: { 
      //     field: 'number_of_payments', 
      //     headerName: "# Payments", 
      //     flex: 0.15,
      //     minWidth: 50,
      //     type: "number",
      //     headerAlign: 'left'
      //   },
      // }

    //   return orderedColumns.reduce((acc, field) => {
    //     return [...acc, allColumns[field]]
    //   }, [])
      
      
    // }, [orderedColumns])

  
  const [columns, setColumns] = useState([
    { 
      field: 'plan_account', 
      headerName: "Plan Account", 
      // flex: 0.15,
      // minWidth: 50,
      width: Math.floor((gridWidth * 11.40) / 100),
      sortable: false,
      align: "right",
      renderCell: ({ row }) => {
        const { plan_account_bp_nr, plan_account_bp_name } = row
        
        return (
          <div className="MuiDataGrid-cellContent">
            {`${plan_account_bp_nr} - ${plan_account_bp_name}`}
          </div>
        )
      }
    },
    { 
      field: 'date', 
      headerName: "Date", 
      // flex: 0.15,
      // minWidth: 50,
      width: Math.floor((gridWidth * 11.40) / 100),
      type: "date",
      align: "right",
      renderCell: ({ value }) => {
        if (!value) {
          return <div className="MuiDataGrid-cellContent">{value}</div>
        }
        return (
          <div className="MuiDataGrid-cellContent">
            {dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}
          </div>
        )
      }
    },
    { 
      field: 'gsv', 
      headerName: "GSV", 
      flex: 0.15,
      type: "number",
      headerAlign: 'left',
      renderCell: ({ value }) => {
        if (isNaN(value)) {
          return <div className="MuiDataGrid-cellContent">
            <div className="trade-header-name">{value}</div>
            <div className='trade-header-separator'><svg><path d="M11 19V5h2v14z"></path></svg></div>
          </div>
        }
        return (
          <div className="MuiDataGrid-cellContent">
            {numberFormatter(prefsData.decimal_notation).format(value)}
          </div>
        )
      }
    },
    {
      field: 'niv',
      headerName: t("TradeExpeditiureMonitor.NIV"),
      minWidth: 50,
      flex: 0.15,
      align: "right",
      type: "number",
      headerAlign: 'left',
      renderCell: ({ value }) => {
        if (isNaN(value)) {
          return <div className="MuiDataGrid-cellContent">
            <div className="trade-header-name">{value}</div>
            <div className='trade-header-separator'><svg><path d="M11 19V5h2v14z"></path></svg></div>
          </div>
        }
        return (
          <div className="MuiDataGrid-cellContent">
            {numberFormatter(prefsData.decimal_notation).format(value)}
          </div>
        )
      }
    },
    { 
      field: 'accruals', 
      headerName: "Accruals", 
      flex: 0.15,
      minWidth: 50,
      type: "number",
      headerAlign: 'left',
      renderCell: ({ value }) => {
        if (isNaN(value)) {
          return <div className="MuiDataGrid-cellContent">
            <div className="trade-header-name">{value}</div>
            <div className='trade-header-separator'><svg><path d="M11 19V5h2v14z"></path></svg></div>
          </div>
        }
        return (
          <div className="MuiDataGrid-cellContent">
            {numberFormatter(prefsData.decimal_notation).format(value)}
          </div>
        )
      }
    },
    { 
      field: 'payments', 
      headerName: "Payments", 
      flex: 0.15,
      minWidth: 50,
      type: "number",
      headerAlign: 'left',
      renderCell: ({ value }) => {
        if (isNaN(value)) {
          return <div className="MuiDataGrid-cellContent">
            <div className="trade-header-name">{value}</div>
            <div className='trade-header-separator'><svg><path d="M11 19V5h2v14z"></path></svg></div>
          </div>
        }
        return (
          <div className="MuiDataGrid-cellContent">
            {numberFormatter(prefsData.decimal_notation).format(value)}
          </div>
        )
      }
    },
    { 
      field: 'balance', 
      headerName: "Balance", 
      flex: 0.15,
      minWidth: 50,
      type: "number",
      headerAlign: 'left',
      renderCell: ({ value }) => {
        if (isNaN(value)) {
          return <div className="MuiDataGrid-cellContent">
            <div className="trade-header-name">{value}</div>
            <div className='trade-header-separator'><svg><path d="M11 19V5h2v14z"></path></svg></div>
          </div>
        }
        return (
          <div className="MuiDataGrid-cellContent">
            {numberFormatter(prefsData.decimal_notation).format(value)}
          </div>
        )
      }
    },
    { 
      field: 'number_of_accruals', 
      headerName: "# Acc. Postings", 
      flex: 0.15,
      minWidth: 50,
      type: "number",
      headerAlign: 'left'
    },
    { 
      field: 'number_of_payments', 
      headerName: "# Payments", 
      flex: 0.15,
      minWidth: 50,
      type: "number",
      headerAlign: 'left',
    }
  ])

  useEffect(() => {
    const getDimensions = () => {
      return refWidth.current.offsetWidth
    }

    const handleResize = () => {
      setGridWidth(getDimensions())

      const changeMinWidth = columns.map(item => {
        if (item.field === "plan_account" || item.field === "date") 
          return {...item, width: Math.floor((getDimensions() * 11.40) / 100)}
        else 
          return {...item}
      })
      setColumns(changeMinWidth)

    }

    if (refWidth.current) {
      setGridWidth(getDimensions())

      const changeMinWidth = columns.map(item => {
        if (item.field === "plan_account" || item.field === "date") 
          return {...item, width: Math.floor((getDimensions() * 11.40) / 100)}
        else 
          return {...item}
      })
      setColumns(changeMinWidth)
      
    }    

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [refWidth])

  const objBreadcrumb = [
    { label: t("Breadcrumb.home"), link: '/' },
    { label: t("Breadcrumb.accrualBalance"), link: '' },
    { label: "Full Reconciliation Report", link: '/accrual-balance/reconciliation' },
  ]

  // const handleColumnOrderChange = React.useCallback((params) => {
  //   setOrderedColumns((prevOrderedColumns) => {
  //     const newOrderedColumns = [...prevOrderedColumns];
  //     const oldIndex = params.oldIndex;
  //     const targetIndex = params.targetIndex;
  //     const oldColumn = prevOrderedColumns[oldIndex];
  //     newOrderedColumns.splice(oldIndex, 1);
  //     newOrderedColumns.splice(targetIndex, 0, oldColumn);
  //     saveUserPrefsWithoutRefresh({
  //       ...prefsData,
  //       reports_column_order: {
  //         ...prefsData.reports_column_order,
  //         My_Balance: newOrderedColumns
  //       }
  //     })
  //     return newOrderedColumns;
  //   });
  // }, [])


  const handleRequestRefresh = () => {

    const queryParams = {
      BUSINESS_UNIT: value_bu,
      CUSTOMER_HIERARCHY_DATE: refreshReportDate,
    }

    const queryString = getParam(queryParams).toString()
    request.fetchData(
      `${backend_url.full_reconciliation}/${checkYear}?${queryString}`,
      "POST",
      null,
      false,
      tokenBpxRequest,
      false
    )
    .then(data => {
      if (data.todo_id) {
        SnackbarUtils.success(JSON.stringify("We are preparing your Full Reconciliation Report. Please refresh page in a few minutes to check if new version is ready."))
        setOpen(false)

        const recReport = JSON.parse(localStorage.getItem("rec-version"))
        let previousValue = JSON.parse(localStorage.getItem("rec-is-request"));
    
        if (!previousValue) {
          previousValue = {}
        }
    
        localStorage.setItem("rec-is-request", JSON.stringify({
          ...previousValue,
          [endpointYear]: {
            isRequest: true,
            VERSION: recReport.VERSION,
            NEXTVERSION: recReport.VERSION + 1
          } 
        }))
        setLoadingReport(true)
        
        
      }
    })
    .catch(err => {
      SnackbarUtils.error(JSON.stringify("An error occurred, please try later"))
    })

  }

  const datePickerRefresh = () => {
    
    return (
      <div className='modal-rates-section'>
        <LocalizationProvider dateAdapter={AdapterDateFns} >
          <DesktopDatePicker
            inputFormat={prefsData && prefsData.date_format}
            required={true}
            value={refreshReportDate}  
            onChange={(value, typing) => setRefreshReportDate(value)}
            onAccept={(value) => {
              let convertDate = moment(new Date(value)).format("YYYY-MM-DD")
              setRefreshReportDate(convertDate)              
            }}
            renderInput={(params) =>
              <TextField
                {...params} 
                size="small"
                onKeyDown={e => e.preventDefault()}
                inputProps={
                  {
                    ...params.inputProps,
                    placeholder: "Date"
                  }
                }
              />
            }
          />
        </LocalizationProvider>
      </div>
    )

  }

  const dialogRefresh = () => {
    

    return (
      <Dialog
        className="bpx-refresh-bg"
        onClose={handleClose}
        open={open}
      >
        <div className="bpx-refresh-block">
            <div className="bpx-refresh-header">
              <div className="bpx-refresh-title">
                Refresh Report
              </div>
              <div className="bpx-refresh-close" onClick={handleClose}>
                <CloseIcon />
              </div>
            </div>
            {
              loadingReport ? (
                <div className="bpx-refresh-loading-content">
                  <div className="bpx-refresh-loading">
                    <CircularProgress />
                  </div>
                  <div className="bpx-refresh-loading-text">
                    New version of report for Reconciliation { checkYear } is being prepared. You can close this window and continue your work. Please refresh report in a few minutes to check if new version is now ready.
                  </div>
                </div>  
              ) : (
                <div>
                  <div className="bpx-refresh-content">
              
                    <div className="bpx-refresh-center">
                        Request report for { checkYear }
                    </div>
                    
                    <div className="bpx-refresh-line">
                        <span className="bpx-refresh-line-left">
                          BP Hierarchy On:
                        </span>
                        <span className="bpx-refresh-line-right">
                          { datePickerRefresh() }
                        </span>
                    </div>
  
                  </div>
                  <div className="bpx-refresh-footer">
                    <div className="bpx-info-footer" style={{ width: "100%"}}>
                      Please note that this might take few <br />
                      minutes for report to be prepared.
                    </div>
                    <Button variant="outlined" className="bpx-refresh-cancel" onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button variant="contained" className="bpx-refresh-send" onClick={() => handleRequestRefresh("2024-03-13")}>
                      OK
                    </Button>
                  </div>
                </div>
              )
            }
        </div>
      </Dialog>
    )
  }

  const onChangeFontSize = (value)=>{
    setFontSize(value)
    saveUserPrefs({
      ...prefsData,
      reports_column_size:{
        ...prefsData.reports_column_size,
        Reconciliation: value
      }
    })
  }


  return (
    <div className="tableContainer">
      { open && dialogRefresh() }
      
      <div ref={refWidth}>
        <CustomToolbarReconciliation 
          bread={objBreadcrumb}
          title="Full Reconciliation Report"
          displayColumn={true}
          displayColumnReconciliation={true}
          handleClickRefresh={handleClickOpen}
          isFontSize={true}
          fontSize={fontSize}
          onChangeFontSize={onChangeFontSize}
          filters={
            <CustomMultipleFilter 
              onFilter={changeFilterValues} 
              dataFilter={filter} 
              preload={backend_url.my_balance_filter_values}
              loading={loading}
              checkYear={checkYear}
            />
          }
          additionalTable={
            <TotalTable 
              total={total}
              columnVisibility={columnVisibility}
              fontSize={fontSize}
            /> 
          }
          apiRef={apiRef}
        />
        
        <DataGridPro
          apiRef={apiRef}
          sx={{ 
            '& .MuiDataGrid-row': {
              minHeight: `${checkSize(fontSize, 'row_height')}px !important`,
              height: 'auto !important'
            },
            '& .MuiDataGrid-cell': {
              whiteSpace: 'nowrap',
              overflow: 'hidden'
            },
            "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator": {
              display: "none"
            },
            '& .MuiDataGrid-main':{
              fontSize: checkSize(fontSize, 'font_size')
            }
          }} 
          rows={rows}
          columns={columns}
          loading={loading}
          disableSelectionOnClick={true}
          // sortingOrder={['desc', 'asc']}
          disableColumnFilter
          getRowHeight={() => 'auto'}
          headerHeight={checkSize(fontSize, 'header_height')}
          // onColumnOrderChange={handleColumnOrderChange}
          onRowsScrollEnd={handleOnRowsScrollEnd}
          onColumnVisibilityModelChange={saveColumnVisibility}
          onPreferencePanelClose={()=> updateUserPrefsOnClose()}
          localeText={{
            noRowsLabel: t("Common.noRows")
          }}
          initialState={{
            columns: {
              columnVisibilityModel:columnVisibility
            },
          }}
          components={{
            LoadingOverlay: CustomLoadingOverlay,
            Panel: CustomPanel
          }}
        />
      </div>

    </div>
  )

}
