import React, { useState, useEffect } from 'react'
import { DataGridPro, GridOverlay } from "@mui/x-data-grid-pro"
import { LinearProgress } from "@mui/material"
import { CustomToolbar } from "../../components"
import { useParams } from 'react-router-dom'
import { backend_url } from '../../settings'
import { useTranslation } from 'react-i18next'
import { getParam, numberFormatter, dateTimeFormatter, checkSize } from "../../utils/utils"
import { Link } from "react-router-dom"
import moment from 'moment'
import { useFetch } from '../../hooks'
import { useUserPrefs } from "../UserPreferences/ProvideUserPrefs";


function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}


//moment(data.AO_CREATED_ON).format("DD-MM-YYYY")

export default function AccrualPostingDetail() {

  const { t } = useTranslation('translation')
  const value_bu = localStorage.getItem('bu_bpx')
  const tokenBpxRequest = localStorage.getItem('token_bpx')
  let paramsHook = useParams()

  const request = useFetch()

  const [rows, setRows] = useState([])
  const [lines, setLines] = useState([])
  const { prefsData, saveUserPrefs } = useUserPrefs()
  const [fontSize, setFontSize] = useState(prefsData.reports_column_size&&prefsData.reports_column_size.Accrual_Posting_Detail || 'm');
  
  useEffect(() => {
    localStorage.setItem("currentUrl", window.location.pathname)

    const queryParams = {
      BUSINESS_UNIT: value_bu,
      message_id: paramsHook.id,
      limit: 100,
    }
    const queryString = getParam(queryParams).toString()

    request.fetchData(
      `${backend_url.accrual_posting}/${paramsHook.id}?${queryString}`,
      'GET',
      null,
      false,
      tokenBpxRequest
    )
    .then(data => {
      
      setRows(
        [data].map((d) => {
          return {
            id: d.MESSAGE_ID,
            ...d,
          }
        })
      )
       
    })


    request.fetchData(
      `${backend_url.accrual_posting_detial}/${paramsHook.id}?business_unit=${value_bu}`,
      'GET',
      null,
      false,
      tokenBpxRequest,
      false
    )
    .then(
      name => setLines(
        name.map((d) => {
          return {
            id: d.MESSAGE_ID + d.BP_NR + d.BP_ROLE + d.MATERIAL_NR,
            ...d,
          }
        })
      )
    )
    

  }, [])


  const columns = [
    { 
      field: "id", 
      headerName: "id" 
    },
    {
      field: "ACTIVITY_ID",
      headerName: t("AccrualPostingList.activity_id"),
      hideable: false,
      minWidth: 145,
      renderCell: (params) => (
        <Link
          style={{ color: "#f69e80" }}
          to={`/my-activities/accrual_object/${params.row.AO_ID}`}
          className="dark-mode-link-color"
        >
          {params.value}
        </Link>
      ),
    },
    { 
      field: "EXT_SPEND_TYPE", 
      headerName: t("AccrualPostingList.ext_spend_type"), 
      flex: 1 
    },
    { 
      field: "SALES_AREA", 
      headerName: t("AccrualPostingList.sales_area"), 
      flex: 1 
    },
    { 
      field: "PLAN_ACCOUNT", 
      headerName: t("AccrualPostingList.plan_account"), 
      flex: 1 
    },
    { 
      field: "PERIOD_ID", 
      headerName: t("AccrualPostingList.period"), 
      flex: 1 
    },
    { 
      field: "DATE_ACCRUAL", 
      headerName: t("AccrualPostingList.date_accrual"), 
      flex: 1,
      renderCell: ({ value }) => dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone),
    },
    { 
      field: "DATE_POSTING", 
      headerName: t("AccrualPostingList.date_posting"), 
      flex: 1,
      renderCell: ({ value }) => dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone),
    },
    { 
      field: "CURRENCY", headerName: t("AccrualPostingList.currency"), flex: 1 
    },
    { 
      field: "AMOUNT", 
      headerName: t("AccrualPostingList.amount"), 
      flex: 1,
      renderCell: ({ value }) => numberFormatter(prefsData.decimal_notation).format(value),
    },
    { 
      field: "SAP_DOC_NO", 
      headerName: t("AccrualPostingList.sap_doc_no"), 
      flex: 1 
    },
    { 
      field: "STATUS", 
      headerName: t("AccrualPostingList.status"), 
      flex: 1,
      renderCell: ({ value }) => {
        switch(value) {
          case "Success":
            return (
              <div style={{ color: "#00a651" }}>
                {value}
              </div>
            )
          case "Pending":
            return (
              <div style={{ color: "#f66d38" }}>
                {value}
              </div>
            )
          case "Processing":
            return (
              <div style={{ color: "#f66d38" }}>
                {value}
              </div>
            )
          case "Error":
            return (
              <div style={{ color: "#de7300" }}>
                {value}
              </div>
            )
          default:
            return (
              <div style={{ color: "#000" }}>
                {value}
              </div>
            )
        }
      } 
    },
  ]

  const objBreadcrumb = [
    { label: t("Breadcrumb.home"), link: "/" },
    { label: t("AccrualPostingDetail.Accrual_Balance"), link: "" },
    { label: t("Menu.accrualPostingList"), link: "/accrual-balance/accrual_posting" },
    { label: t("AccrualPostingDetail.Posting_Lines"), link: window.location.pathname },
  ]

  const linesColumn = [
    { field: "BP_NR", headerName: t("AccrualPostingDetail.BP_NR"), flex: 1 },
    { field: "MATERIAL_NR", headerName: t("AccrualPostingDetail.MATERIAL_NR"), flex: 1 },
    { field: "AMOUNT", 
      headerName: t("AccrualPostingDetail.AMOUNT"), 
      flex: 1,
      renderCell: ({ value }) => numberFormatter(prefsData.decimal_notation).format(value),
    },
    { field: "GL_ACCOUNT", 
      headerName: t("AccrualPostingDetail.GL_ACCOUNT"), 
      flex: 1
    },
    { field: "TEXT", headerName: t("AccrualPostingDetail.TEXT"), flex: 1 },
  ]

  const onChangeFontSize = (value)=>{
    setFontSize(value)
    saveUserPrefs({
      ...prefsData,
      reports_column_size:{
        ...prefsData.reports_column_size,
        Accrual_Posting_Detail: value
      }
    })
  }

  return (
    <div className="tableContainer">
      <div className="block-lines">

        <div>
          <DataGridPro
            sx={{
              '& .MuiDataGrid-row': {
                height: `${checkSize(fontSize, 'row_height')}px !important`,
              },
              '& .MuiDataGrid-cell': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              },
              "& .MuiDataGrid-main" : {
                height: Math.min((rows.length||1) * checkSize(fontSize, 'row_height') + checkSize(fontSize, 'header_height'), 3 * checkSize(fontSize, 'row_height') + checkSize(fontSize, 'header_height')) + 'px',
                fontSize: checkSize(fontSize, 'font_size')
              },
              "& .MuiCheckbox-root": {
                transform: `scale(${checkSize(fontSize, 'scale')})`
              }
            }}
            rows={rows}
            columns={columns}
            disableSelectionOnClick={true}
            disableColumnFilter
            hideFooter={true}
            getRowHeight={() => 'auto'}
            headerHeight={checkSize(fontSize, 'header_height')}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
            }}
            localeText={{
              noRowsLabel: t("Common.noRows")
            }}
            components={{
              Toolbar: CustomToolbar,
            }}
            componentsProps={{
              toolbar: {
                bread: objBreadcrumb,
                title: t("AccrualPostingDetail.title1"),
                displayColumn: false,
                isFontSize: true,
                fontSize: fontSize,
                onChangeFontSize: onChangeFontSize,
              }
            }}
          />
        </div>

        <div className="block-lines-bottom">
          <h2 className="toolbarTitle">{t("AccrualPostingDetail.title2")}</h2>
          <DataGridPro
            sx={{ 
              '& .MuiDataGrid-row': {
                minHeight: `${checkSize(fontSize, 'row_height')}px !important`,
                height: 'auto !important'
              },
              '& .MuiDataGrid-cell': {
                whiteSpace: 'nowrap',
                overflow: 'hidden'
              },
              '& .MuiDataGrid-main':{
                fontSize: checkSize(fontSize, 'font_size')
              }      
            }} 
            rows={lines}
            columns={linesColumn}
            disableSelectionOnClick={true}
            disableColumnFilter={true}
            hideFooter={true}
            getRowHeight={() => 'auto'}
            headerHeight={checkSize(fontSize, 'header_height')}
            localeText={{
              noRowsLabel: t("Common.noRows")
            }}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
            }}
          />
        </div>

      </div>
      

      
    </div>
  )
}
