import React, { useState, useEffect, useCallback } from 'react'
import { DataGridPro, GridOverlay, useGridApiRef } from '@mui/x-data-grid-pro'
import { LinearProgress } from "@mui/material"
import { backend_url } from '../../settings'
import { useTranslation } from 'react-i18next'
import { CustomToolbar } from '../../components'
import CustomMultipleFilter from '../../mui-components/CustomMultipleFilter'
import CustomPanel from '../../mui-components/CustomPanel'
import { useFetch } from '../../hooks'
import { getParam, numberFormatter, dateTimeFormatter, applyFilters, checkSize } from '../../utils/utils'
import { useUserPrefs } from "../UserPreferences/ProvideUserPrefs";

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}


export default function ActualDataInternal() {

  const apiRef = useGridApiRef()

  const { t, i18n } = useTranslation('translation')
  const value_bu = localStorage.getItem("bu_bpx")
  const tokenBpxRequest = localStorage.getItem('token_bpx')
  const request = useFetch()

  const [rows, setRows] = useState([])
  const [page, setPage] = useState(0)
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(false)

  const [exportQuery, setExportQuery] = useState("")
  
  const { prefsData, saveUserPrefs, updateUserPrefsOnClose, saveUserPrefsWithoutRefresh, silentUpdateUserPrefs } = useUserPrefs();
  const [orderedColumns, setOrderedColumns] = useState(prefsData.reports_column_order && prefsData.reports_column_order.Volumes || []);
  const [fontSize, setFontSize] = useState(prefsData.reports_column_size&&prefsData.reports_column_size.Volumes || 'm');
  const columnVisibility = {};
  if(prefsData.reports_column_visibilty&&prefsData.reports_column_visibilty.Volumes){
    prefsData.reports_column_visibilty.Volumes.forEach(it=>columnVisibility[it]=false);
  }
  

  const saveColumnVisibility = (data)=>{
    saveUserPrefs({
      ...prefsData,
      reports_column_visibilty:{
        ...prefsData.reports_column_visibilty,
        Volumes: Object.entries(data).filter(it=>!it[1]).map(it=>it[0])
      }
    })
  }

  const [filter, setFilter] = useState([
    {
      endpoint: backend_url.actual_data_internal_filter,
      queryKeyToTyping: "SUBTOTAL_CODE",
      title: t("ActualDataInternal.SUBTOTAL_TYPE"),
      inputText: t("ActualDataInternal.SUBTOTAL_TYPE_INPUT"),
      firstKeyOption: "SUBTOTAL_CODE",
      secondKeyOption: "SUBTOTAL_NAME",
      valueFilter: applyFilters("SUBTOTAL_CODE", "actualDataInternal"),
      changingArray: [],
      formatField: "select",
    },
    {
      endpoint: backend_url.actual_data_internal_filter,
      queryKeyToTyping: "BP_NR",
      title: t("ActualDataInternal.BP_NR"),
      inputText: t("ActualDataInternal.BP_NR_INPUT"),
      firstKeyOption: "BP_NR",
      secondKeyOption: "NAME1",
      valueFilter: applyFilters("BP_NR", "actualDataInternal"),
      changingArray: [],
      atLeastThreeLetter: true,
      formatField: "select"
    },
    {
      endpoint: backend_url.actual_data_internal_filter,
      queryKeyToTyping: "MATERIAL_NR",
      title: t("ActualDataInternal.MATERIAL_NR"),
      inputText: t("ActualDataInternal.MATERIAL_NR_INPUT"),
      firstKeyOption: "MATERIAL_NR",
      secondKeyOption: "",
      valueFilter: applyFilters("MATERIAL_NR", "actualDataInternal"),
      changingArray: [],
      atLeastThreeLetter: true,
      formatField: "select"
    },
    {
      firstKeyOption: "DATE_FROM",
      title: t("ActualDataInternal.DATE_FROM"),
      valueFilter: applyFilters("DATE_FROM", "actualDataInternal"),
      formatField: "date"
    },
    {
      firstKeyOption: "DATE_TO",
      title: t("ActualDataInternal.DATE_TO"),
      valueFilter: applyFilters("DATE_TO", "actualDataInternal"),
      formatField: "date"
    }
  ])

  const changeFilterValues = (state) => {
    setFilter(state)
    setRows([])
    setPage(0)
  }

  const addLoadedRows = useCallback((items) => {
    const changeIdRow = items.map(d => {
      
      const materialEN = d.material.material_short_texts.find(elem =>
        elem.LANGUAGE_CODE === i18n.language.toLocaleUpperCase()
      )
      const materialFisrt = d.material.material_short_texts.shift()
      const materialDesc = materialEN ? materialEN.MATERIAL_DESCRIPTION : materialFisrt.MATERIAL_DESCRIPTION

      
      return {
        id: d.ae_uid,
        BUSINESS_UNIT_DESCRIPTION: d.config_business_unit.DESCRIPTION,
        SUBTOTAL_TYPE: d.config_subtotal.SUBTOTAL_CODE,
        SUBTOTAL_TYPE_DESCRIPTION: d.config_subtotal.SUBTOTAL_NAME,
        MATERIAL_NAME: materialDesc,
        QUANTITY: d.config_subtotal.ALG_CODE === 'VOLUME' ? d.SUBTOTAL_VAL : '',
        VALUE: (d.config_subtotal.ALG_CODE === 'VALUE' || d.config_subtotal.ALG_CODE === 'VALUE_RED') ? d.SUBTOTAL_VAL : '',
        ...d,
        BP_NR: d.BP_NR + " - " + d.business_partner.NAME1,
      }
    })

    setRows((old) => {
      return [...old, ...changeIdRow]
    })

  }, [])

  useEffect(() => {
    setLoading(true)

    const queryParams = {
      business_unit: value_bu,
      limit: 100,
      skip: page,
    }

    filter.map((key) => {
      const { valueFilter, firstKeyOption, formatField } = key

      switch(formatField) {
        case "select":
          if (valueFilter.length) {
            queryParams[firstKeyOption] = valueFilter
          }
          break
        case "date":
          if (valueFilter) {
            queryParams[firstKeyOption] = valueFilter
          }
          break
        default: 
          return null
      }
    })

    let cachedfilters = localStorage.getItem("filters")
    let filterObj = {
      actualDataInternal: queryParams
    }
    if (cachedfilters && Object.keys(cachedfilters).length) { 
        filterObj = JSON.parse(cachedfilters)
        filterObj["actualDataInternal"] = queryParams
    }
    localStorage.setItem("filters", JSON.stringify(filterObj))

    const queryString = getParam(queryParams).toString()

    setExportQuery(queryString)

    request.fetchData(
      `${backend_url.actual_data_internal}?${queryString}`,
      'GET',
      null,
      false,
      tokenBpxRequest,
      false
    ).then(data => {
      setLoading(false)
      addLoadedRows(data)
      setCount(data.length)
    })

  }, [filter, page]) 

  useEffect(() => {
      return apiRef.current.subscribeEvent('columnHeaderDragEnd', (params) => {
          silentUpdateUserPrefs()
      })
  }, [apiRef])

  const handleOnRowsScrollEnd = (params) => {
    if (params.viewportPageSize&&!loading) {
      if (count >= 100) {
        return setPage(page + 100)
      } else {
        return null
      }
    }
  }

  const columns = React.useMemo(() => {
    const allColumns = {
      id: { 
          field: 'id', 
          headerName: t("ActualDataInternal.ID"),
      },
      BUSINESS_UNIT: { 
          field: 'BUSINESS_UNIT', 
          headerName: t("ActualDataInternal.BUSINESS_UNIT"), 
          flex: 0.07,
          minWidth: 50,
          renderHeader: ({colDef})=>{ 
            if(colDef.width < 100){
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Business Unit" 
                data-mui-internal-clone-element="true">
                    {t("ActualDataInternal.BUSINESS_UNIT_SHORT")}
                </div>
              )
            }
            return (
              <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Business Unit" 
                data-mui-internal-clone-element="true">
                  {t("ActualDataInternal.BUSINESS_UNIT")}
              </div>
            )
          },
      },
      BUSINESS_UNIT_DESCRIPTION: { 
          field: 'BUSINESS_UNIT_DESCRIPTION', 
          headerName: t("ActualDataInternal.BUSINESS_UNIT_DESCRIPTION"), 
          flex: 0.08,
          minWidth: 50,
          renderHeader: ({colDef})=>{ 
            if(colDef.width < 100){
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Business Unit Description" 
                data-mui-internal-clone-element="true">
                    {t("ActualDataInternal.BUSINESS_UNIT_DESCRIPTION_SHORT")}
                </div>
              )
            }
            return (
              <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Business Unit Description" 
                data-mui-internal-clone-element="true">
                  {t("ActualDataInternal.BUSINESS_UNIT_DESCRIPTION")}
              </div>
            )
          },
      },
      SUBTOTAL_TYPE: { 
          field: 'SUBTOTAL_TYPE', 
          headerName: t("ActualDataInternal.SUBTOTAL_TYPE"), 
          flex: 0.08,
          minWidth: 50,
          renderHeader: ({colDef})=>{ 
            if(colDef.width < 100){
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Subtotal Type" 
                data-mui-internal-clone-element="true">
                    {t("ActualDataInternal.SUBTOTAL_TYPE_SHORT")}
                </div>
              )
            }
            return (
              <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Subtotal Type" 
                data-mui-internal-clone-element="true">
                  {t("ActualDataInternal.SUBTOTAL_TYPE")}
              </div>
            )
          },
      },
      SUBTOTAL_TYPE_DESCRIPTION: { 
          field: 'SUBTOTAL_TYPE_DESCRIPTION', 
          headerName: t("ActualDataInternal.SUBTOTAL_TYPE_DESCRIPTION"), 
          flex: 0.08,
          minWidth: 50,
          renderHeader: ({colDef})=>{ 
            if(colDef.width < 100){
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Subtotal Type Description" 
                data-mui-internal-clone-element="true">
                    {t("ActualDataInternal.SUBTOTAL_TYPE_DESCRIPTION_SHORT")}
                </div>
              )
            }
            return (
              <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Subtotal Type Description" 
                data-mui-internal-clone-element="true">
                  {t("ActualDataInternal.SUBTOTAL_TYPE_DESCRIPTION")}
              </div>
            )
          },
      },
      DATE: { 
          field: 'DATE', 
          headerName: t("ActualDataInternal.DATE"), 
          flex: 0.09,
          minWidth: 50,
          renderCell: ({ value }) => <div className="MuiDataGrid-cellContent">{dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}</div>,
      },
      BP_NR: { 
          field: 'BP_NR', 
          headerName: t("ActualDataInternal.BP_NR_CONCAT"), 
          flex: 0.16,
          minWidth: 50,
          renderHeader: ({colDef})=>{ 
            if(colDef.width < 100){
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Business Partner" 
                data-mui-internal-clone-element="true">
                    {t("ActualDataInternal.BP_NR_SHORT")}
                </div>
              )
            }
            return (
              <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Business Partner" 
                data-mui-internal-clone-element="true">
                  {t("ActualDataInternal.BP_NR")}
              </div>
            )
          },
      },
      MATERIAL_NR: { 
          field: 'MATERIAL_NR', 
          headerName: t("ActualDataInternal.MATERIAL_NR"), 
          flex: 0.05,
          minWidth: 50,
      },
      MATERIAL_NAME: { 
          field: 'MATERIAL_NAME', 
          headerName: t("ActualDataInternal.MATERIAL_NAME"), 
          flex: 0.16,
          minWidth: 50,
      },
      QUANTITY: { 
          field: 'QUANTITY', 
          headerName: t("ActualDataInternal.QUANTITY"), 
          flex: 0.08,
          minWidth: 50,
      },
      UOM: { 
          field: 'UOM', 
          headerName: t("ActualDataInternal.UOM"), 
          flex: 0.08,
          minWidth: 50,
      },
      VALUE: { 
          field: 'VALUE', 
          headerName: t("ActualDataInternal.VALUE"), 
          flex: 0.08,
          minWidth: 50,
          renderCell: ({ value }) => <div className="MuiDataGrid-cellContent">{numberFormatter(prefsData.decimal_notation).format(value)}</div>,
          align: "right"
      },
      CURRENCY: { 
          field: 'CURRENCY', 
          headerName: t("ActualDataInternal.CURRENCY"), 
          flex: 0.06,
          minWidth: 50,
      },
    }

    return orderedColumns.length?orderedColumns.reduce((acc, field) => {
      return [...acc, allColumns[field]];
    }, []):Object.values(allColumns);
  }, [orderedColumns]);

  const objBreadcrumb = [
    { label: t("Breadcrumb.home"), link: '/' },
    { label: t("Breadcrumb.volumes"), link: '' },
    { label: t("Breadcrumb.actualDataInternal"), link: '/volumes/actual-data/internal' },
  ]

  const handleColumnOrderChange = React.useCallback((params) => {
    setOrderedColumns((prevOrderedColumns) => {
      const newOrderedColumns = [...prevOrderedColumns];
      const oldIndex = params.oldIndex;
      const targetIndex = params.targetIndex;
      const oldColumn = prevOrderedColumns[oldIndex];
      newOrderedColumns.splice(oldIndex, 1);
      newOrderedColumns.splice(targetIndex, 0, oldColumn);
      saveUserPrefsWithoutRefresh({
        ...prefsData,
        reports_column_order: {
          ...prefsData.reports_column_order,
          Volumes: newOrderedColumns
        }
      })
      return newOrderedColumns;
    });
  }, []);

  const onChangeFontSize = (value)=>{
    setFontSize(value)
    saveUserPrefs({
      ...prefsData,
      reports_column_size:{
        ...prefsData.reports_column_size,
        Volumes: value
      }
    })
  }

  return (
    <div className="tableContainer">
      <DataGridPro
        apiRef={apiRef}
        sx={{ 
          '& .MuiDataGrid-row': {
            minHeight: `${checkSize(fontSize, 'row_height')}px !important`,
            height: 'auto !important'
          },
          '& .MuiDataGrid-cell': {
            whiteSpace: 'nowrap',
            overflow: 'hidden'
          },
          '& .MuiDataGrid-main':{
            fontSize: checkSize(fontSize, 'font_size')
          }
        }} 
        rows={rows}
        columns={columns}
        loading={loading}
        disableSelectionOnClick={true}
        sortingOrder={['desc', 'asc']}
        getRowHeight={() => 'auto'}
        headerHeight={checkSize(fontSize, 'header_height')}
        disableColumnFilter
        onColumnOrderChange={handleColumnOrderChange}
        onRowsScrollEnd={handleOnRowsScrollEnd}
        onColumnVisibilityModelChange={saveColumnVisibility}
        onPreferencePanelClose={()=> updateUserPrefsOnClose()}
        initialState={{
          columns: {
            columnVisibilityModel:columnVisibility
          },
        }}
        localeText={{
          toolbarColumns: t("Buttons.Columns"),
          toolbarExport: t("Buttons.Export"),
          noRowsLabel: t("Common.noRows")
        }}
        components={{
          Toolbar: CustomToolbar,
          LoadingOverlay: CustomLoadingOverlay,
          Panel: CustomPanel
        }}
        componentsProps={{
          toolbar: {
            bread: objBreadcrumb,
            title: t("Title.actualDataInternal"),
            displayColumn: true,
            isFontSize: true,
            fontSize: fontSize,
            onChangeFontSize: onChangeFontSize,
            filters: 
              <CustomMultipleFilter 
                onFilter={changeFilterValues} 
                dataFilter={filter} 
                loading={loading}
              />,
            exportSetting: {
              endpoint: backend_url.actual_data_internal_excel + '?' + exportQuery,
              endpointCount: backend_url.actual_data_internal_count + '?' + exportQuery,
              fileName: "Actual Data Internal Report"
            }
          },
        }}
      />
    </div>
  );
}
