import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { LoadingWithText } from '../../components'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'

import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'


export default function ModalDelete({ open, onSubmit, onClose, loading, warning }) {
  const { t } = useTranslation('translation');

  const [loadingPage, setLoadingPage] = useState(false);

  useEffect(()=>{
    setLoadingPage(loading)
  }, [loading])

  const displayBlockForm = () => {
    return (
      <>
        <DialogTitle className='bpx-modal-delete-item'>
          {t("MODAL.DELETE")}
          <div className="close-for-modal-window cancel-delete-config">
            <IconButton onClick={onClose} className="wrap-cancel-modal">
              <CloseIcon />
            </IconButton>
          </div>
          { warning &&
            <div className=" toolbarTitle bpx-auth-warning">
              <span><b>Warning:</b> AE Business Unit Basic role is mandatory</span>
            </div>
          }
        </DialogTitle>
    
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={onClose}
          >
            {t("MODAL.BUTTONS.CANCEL")}
          </Button> 
          <Button
            onClick={onSubmit}
            variant="contained"
            color="error"
          >
            {t("MODAL.BUTTONS.OK")}
          </Button>
        </DialogActions>
      </>
    )
  }

  const displayLoading = (
    loadingPage
      ? <LoadingWithText text="Your request is being processed, please wait." size={50} />
      : displayBlockForm()
  )

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {displayLoading}
      
    </Dialog>
  )
}
