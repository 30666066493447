import React, { useEffect, useState, useRef } from 'react'
import { TextField } from '@mui/material'
import { useFetch } from '../../../hooks'
import { backend_url } from '../../../settings'
import Box from '@mui/material/Box';
import { getParam } from "../../../utils/utils"
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next'
import moment from 'moment'


export default function AutoComplete({ data, onChangeHandler }) {

  const { url, title, isTreeChar, endpoint, field, values, keysForInput } = data;

  const tokenBpxRequest = localStorage.getItem('token_bpx')
  const request = useFetch()

  const listElem = useRef();
  const mounted = useRef();

  const [selectedValue, setSelectedValue] = useState(null);
  const [page, setPage] = useState(0)
  const [options, setOptions] = useState([])
  const [inputValue, setInputValue] = useState('');
  const [position, setPosition] = useState(0);
  const [loadingOption, setLoadingOption] = useState(false);

  useEffect(() => {
    if (inputValue.length) {
      fetchOptions();
    } else {
      setOptions([])
    }
  }, [inputValue])

  useEffect(() => {
    if (page > 0) {
      fetchOptions(true)
    }
  }, [page]);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    }
    else if (position && listElem.current) {
      listElem.current.scrollTop = position - listElem.current.offsetHeight;
    }
  })

  const getUrl = (endpointForGet, params = []) => {
    let param = {
      limit: 20,
      skip: page
    };

    if (endpointForGet) {
      endpointForGet.forEach(it => {
        if (localStorage.getItem(it.name)) {
          param[it.field] = localStorage.getItem(it.name);
        }
      })
    }
    if (params) {
      params.map(it => {
        param[it.field] = it.value;
        return it;
      })
    }
    const queryString = getParam(param).toString();
    return '?' + queryString;
  }


  const fetchOptions = (isAdd = false) => {
    request.fetchData(
      url + getUrl([{ field: 'business_unit', name: 'bu_bpx' }], [{ field, value: inputValue }]),
      'GET',
      null,
      false,
      tokenBpxRequest,
      false,
      false
    ).then(data => {
      setLoadingOption(false);
      if (isAdd) {
        setOptions(prev => [...prev, ...data])
      } else {
        setOptions(data)
      }
    }).catch(() => {
      setLoadingOption(false);
      setOptions([])
    })

  }

  const handleInputChange = (event, newValue) => {
    if (newValue) {
      setSelectedValue(newValue);
      onChangeHandler(newValue);
    } else {
      setSelectedValue('')
      onChangeHandler('')
    }

  };

  return (
    <Autocomplete
      value={selectedValue}
      onChange={(event, newValue) => handleInputChange(event, newValue)}
      options={options}
      loading={loadingOption}
      loadingText='Loading…'
      ListboxProps={{
        ref: listElem,
        onScroll: ({ currentTarget }) => {
          const scrollPosition = currentTarget.scrollTop + currentTarget.clientHeight;
          if (currentTarget.scrollHeight - scrollPosition <= 1 && !loadingOption) {
            setLoadingOption(true);
            setPosition(scrollPosition);
            setPage(pg => pg + 20);
          }
        }
      }}

      getOptionLabel={(option) => {
        if (typeof option === 'string' && !option.length) {
          return ''
        }
        return option[keysForInput[0]] + ' - ' + option[keysForInput[1]]
      }
      }
      renderInput={(params) => <TextField {...params} label={title} variant="outlined" />}
      renderOption={(props, option, { selected }) => (
        <li {...props} >
          <span><b>{option[keysForInput[0]]}</b> {option[keysForInput[2]]? ' - '+ option[keysForInput[2]] : null} - {option[keysForInput[1]]}</span>
        </li>
      )}
      onInputChange={(event, newValue, condition) => {
        if (isTreeChar) {
          setPage(0);
          if (newValue.length < 3) {
            setInputValue('')
          } else {
            setInputValue(newValue)
          }
        }
      }}
    />
  );
}
