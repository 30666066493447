import AddIcon from '@mui/icons-material/Add';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import { LinearProgress } from "@mui/material";
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { DataGridPro, GridActionsCellItem, GridOverlay } from '@mui/x-data-grid-pro';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalAddData, ModalDelete } from '../../../components';
import { useFetch, usePermissions } from '../../../hooks';
import { checkObjectKey, getParam } from "../../../utils/utils";
import {SelectedComponent} from './index'

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  )
}

function ConfigTable({ table, configuration, onHandleClick, business_unit }) {

  const {
    url, endpoint, titleTable, idReplace, selected, select, parserForPost, parserForGet, excludeColumn,
    columnsTable, notistack, columnSingleSelect, configurationDepend, value, endpointForGet, endpointForPost, urlSelected, isSelectedComponent
  } = table

  let randomId = () => Math.random() * 100000
  const tokenBpxRequest = localStorage.getItem('token_bpx')

  const request = useFetch()

  const { t } = useTranslation('translation')

  const { obj, childObj } = usePermissions()
  const checkConfiguration = checkObjectKey(obj, childObj, 'Configuration_Change');

  const [rows, setRows] = useState([]);
  const [filterRows, setFilterRows] = useState([])
  const [state, setState] = useState([]);
  const [modal, showModal] = useState({ 'addData': false, 'warning': false});
  const [editTable, setEditTable] = useState(null);
  const [deleteRow, setDeleteRow] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingForm, setLoadingForm] = useState(false);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);

  const filterData = (data) => {
    if (configurationDepend.length) {
      data = data.filter(it => {
        return configuration[configurationDepend] === it[configurationDepend[0]]
      })
    }
    return data;
  }

  useEffect(() => {
    setFilterRows(filterData(rows));
  }, [rows])

  const getUrl = (skip, endpoint = endpointForGet, filters = []) => {
    let param = {
      limit: 100,
      skip
    };

    if (endpoint) {
      endpoint.forEach(it => {
        if (localStorage.getItem(it.name)) {
          param[it.field] = localStorage.getItem(it.name);
        }
      })
    }

    if (configurationDepend) {
      configurationDepend.forEach(it => {
        if (it.field) {
          if (configuration[it.field]) {
            param[it.name] = configuration[it.field];
          }
        } else if (configuration[it]) {
          param[it] = configuration[it];
        }
      })
    }
    if (filters.length) {
      filters.map(it => {
        param[it.field] = it.value
      })
    }
    const queryString = getParam(param).toString();
    return '?' + queryString;
  }

  useEffect(() => {
    setLoading(true)
    setPage(0)
    request.fetchData(
      url + getUrl(0),
      'GET',
      null,
      false,
      tokenBpxRequest,
      false,
      notistack
    )
      .then(data => {
        setRows(() =>
          data.map(d => ({
            id: !idReplace ? randomId() : d.id,
            ...d
          }))
        )
        setCount(data.length);
        setLoading(false)
      })
      .catch(() => {
        setRows([])
        setLoading(false)
      })
  }, [configuration, business_unit])

  useEffect(() => {
    setLoading(true)
    if (page > 0) {
      request.fetchData(
        url + getUrl(page),
        'GET',
        null,
        false,
        tokenBpxRequest,
        false,
        notistack
      )
        .then(data => {
          setRows((old) => [...old,
          ...data.map(d => ({
            id: idReplace ? idReplace : randomId(),
            ...d
          }))
          ])
          setCount(data.length);
          setLoading(false)
        })
        .catch(() => setLoading(false))
    }
  }, [page])

  useEffect(() => {
    if (columnSingleSelect) {
      columnSingleSelect.map(ww => {
        const { url, code, field, endpoint } = ww
        request.fetchData(
          url + getUrl(0, endpoint),
          'GET',
          null,
          false,
          tokenBpxRequest,
          false,
          false
        )
          .then(data => {
            let valueArray = data.map(tt => tt[code])
            let unique = valueArray.filter((v, i, a) => a.indexOf(v) === i)
            setState(prev => {
              return { ...prev, [field]: unique }
            })

          })
        return ww
      })
    }

  }, [])



  const singleSelectArray = (arr, obj) => {
    return arr.map(val => {
      const { field } = val
      let condition = Object.keys(obj).find(key => key === field)

      if (condition) {
        val.valueOptions = obj[field]
      }
      return val
    })
  }

  const getUrlArray = (row) => {
    const getUrl = endpoint.filter(tt => tt.field).map(tt => tt.field);
    const param = {};
    let getUrlLine = endpoint.filter(tt => tt.line).map(tt => {
      param[tt.line] = row[tt.line];
      return tt.line
    });

    getUrlLine += endpoint.filter(it => it.value).map((it) => {
      param[it.name] = row[it.value];
      return it
    })

    const filteredUrl = getUrl.map(it => row[it]);

    let convertUrl = Object.values(filteredUrl).join('/');

    if (getUrlLine.length) {
      convertUrl = convertUrl + '?' + getParam(param).toString();
    }
    return convertUrl
  }

  const excludeProperty = (row, exclude) => {
    return Object.keys(row)
      .filter(key => !exclude.includes(key))
      .reduce((obj, key) => Object.assign(obj, { [key]: row[key] }), {})
  }

  const handleClick = (open = true) => {
    showModal({ ...modal, addData: open });
  }

  const handleEditClick = (id) => () => {
    const row = rows.find(it => it.id === id);

    if (row) {
      if(excludeColumn){
        excludeColumn.forEach(it=>{
          delete row[it]
        })
      }
      setEditTable(row);
      handleClick(true);
    }
  }


  const deleteElement = () => {
    const { row, id } = deleteRow;
    setLoadingForm(true)

    request.fetchData(
      url + '/' + getUrlArray(row),
      "DELETE",
      null,
      false,
      tokenBpxRequest,
      true,
      notistack
    )

      .then(data => {
        setRows(rows.filter((row) => row.id !== id));
        setDeleteRow({});
        showModal({ ...modal, warning: false });
        setLoadingForm(false)
      })
      .catch(() => {
        setDeleteRow({});
        showModal({ ...modal, warning: false });
        setLoadingForm(false)
      })

  }

  const handleDeleteClick = (id, row) => () => {
    showModal({ ...modal, warning: true })
    setDeleteRow({ id, row })
  }

  const getUrlForPost = (newRow) => {
    let param = {};

    if (endpointForPost) {
      endpointForPost.forEach(it => {
        if (newRow[it.name]) {
          param[it.field] = newRow[it.name];
        }
      })
    }
    const queryString = getParam(param).toString();
    return queryString ? '?' + queryString : '';
  }

  const processRowUpdate = (newRow) => {
    setLoadingForm(true)
    const oldRow = rows.find(it => it.id === newRow.id)

    let body = parserForPost ? parserForPost(rows, newRow) : excludeProperty(newRow, ['id', 'isNew']);

    const updatedRow = { ...newRow, isNew: false }
    if (newRow.isNew) {
      return request.fetchData(
        url + getUrlForPost(parserForPost ? parserForPost(rows, newRow) : newRow),
        "POST",
        JSON.stringify([body]),
        false,
        tokenBpxRequest,
        true,
        true
      )
        .then(() => {
          setLoadingForm(false)
          setRows([updatedRow, ...rows]);
          showModal({ ...modal, addData: false });
          return updatedRow

        })
        .catch(() => setLoadingForm(false))

    } else {
      body = parserForGet ? parserForGet(newRow) : newRow;

      return request.fetchData(
        url + '/' + getUrlArray(oldRow),
        "PUT",
        JSON.stringify(body),
        false,
        tokenBpxRequest,
        true,
        true
      )
        .then(() => {
          setLoadingForm(false)
          closeModal()
          setEditTable(null)
          setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
          return updatedRow;

        })
        .catch(() => setLoadingForm(false))
    }

  }

  const hancleCheckBox = (e, data) => {
    onHandleClick(value, { checked: e.target.checked, data });
  }

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 130,
      cellClassName: 'actions',
      getActions: ({ id, row }) => {

        const editDeleteArr = checkConfiguration ?
          [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id, row)}
              color="inherit"
            />,
          ] : [];

        if (selected) {
          editDeleteArr.unshift(<Checkbox
            sx={{ '& .MuiSvgIcon-root': { fontSize: 20, color: '#000' } }}
            icon={<PanoramaFishEyeIcon />}
            checkedIcon={<CheckCircleOutlineIcon />}
            checked={configuration[value] ? configuration[value] === row[select] : false}
            onChange={(e) => hancleCheckBox(e, row[select])}
          />)
        }
        return editDeleteArr
      },
    },
    ...singleSelectArray(columnsTable, state),
  ]

  const closeModal = () => {
    handleClick(false)
    setEditTable(null);
  }

  const onRowClick = (e) => {
    if (selected) {
      onHandleClick(value, { checked: configuration[value] ? configuration[value] !== e.row[select] : true, data: e.row[value] });
    }
  }

  const handleOnRowsScrollEnd = (params) => {
    if (params.viewportPageSize&&!loading) {
      if (count >= 100) {
        return setPage(page + 100)
      } else {
        return null
      }
    }
  }

  return (
    <>
      <div className='bpx-config-datagrid'>
        {
            isSelectedComponent?
              <div className="bpx-config-block">                
                <div className="bpx-config-bu">
                  <SelectedComponent onHandleClick={onHandleClick} url={urlSelected} configuration={configuration} value={value}/>
                </div>
              </div>
            :null
          }
        <h3 className='bpx-config-datagrid-title'>{titleTable}</h3>
        <DataGridPro
          sx={{ '& .MuiDataGrid-main': { height: filterRows.length < 8 ? '100%' : '50vh' } }}
          autoHeight={Boolean(filterRows.length < 8)}
          rows={filterRows}
          onRowsScrollEnd={handleOnRowsScrollEnd}
          columns={columns}
          loading={loading}
          // rowModesModel={rowModesModel}
          experimentalFeatures={{ newEditingApi: true }}
          hideFooter={true}
          processRowUpdate={processRowUpdate}
          onRowClick={onRowClick}
          localeText={{
            noRowsLabel: t("Common.noRows")
          }}
          
          components={{
            LoadingOverlay: CustomLoadingOverlay
          }}
        />
        {
          checkConfiguration ?
            (
              <div className="bpx-config-datagrid-bottom">
                <Button
                  className="bpx-config-datagrid-btn"
                  startIcon={<AddIcon />}
                  onClick={() => handleClick()}
                />
              </div>
            )
            : null
        }
        <ModalAddData
          data={columnsTable}
          titleTable={titleTable}
          open={modal.addData}
          onClose={closeModal}
          handleSubmit={processRowUpdate}
          editData={editTable}
          loading={loadingForm} />
        <ModalDelete
          open={modal.warning}
          onClose={(prev) => showModal({ ...prev, warning: false })}
          onSubmit={deleteElement}
          loading={loadingForm} />
      </div>
    </>
  )
}

export default ConfigTable
