import React, { useState, useEffect } from 'react'
import { DataGridPro, GridOverlay, gridColumnsTotalWidthSelector } from "@mui/x-data-grid-pro"
import { LinearProgress } from "@mui/material"
import { CustomToolbar } from "../../../components"
import { useParams } from 'react-router-dom'
import { backend_url } from '../../../settings'
import { useTranslation } from 'react-i18next'
import { getParam, onlyUnique, numberFormatter, dateTimeFormatter,checkSize } from "../../../utils/utils"
import { Link } from "react-router-dom"
import { useFetch } from '../../../hooks'
import { useUserPrefs } from "../../UserPreferences/ProvideUserPrefs";


function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}


//moment(data.AO_CREATED_ON).format("DD-MM-YYYY")

export default function PaymentLines() {

  const { t } = useTranslation('translation')
  const value_bu = localStorage.getItem('bu_bpx')
  const tokenBpxRequest = localStorage.getItem('token_bpx')

  let paramsHook = useParams()
  const request = useFetch()

  const [rows, setRows] = useState([])
  const [selectionModel, setSelectionModel] = useState([])
  const [lines, setLines] = useState([])
  const [documentKeys, setDocumentKeys] = useState([])
  const { prefsData,saveUserPrefs } = useUserPrefs()
  
  const [fontSize, setFontSize] = useState(prefsData.reports_column_size&&prefsData.reports_column_size.Payment_Lines || 'm');

  const createUrl = (arg)=>{
    const queryParams = {
      business_unit: value_bu,
      BUSINESS_UNITS: value_bu,
      limit: 100,
      PAYMENT_ID: paramsHook.id,
      VERSION: paramsHook.path
    }


    const obj = {}
    arg.map(it=>{
      obj[it] = queryParams[it]
    })


    return getParam(obj).toString()
  }

  useEffect(() => {
    localStorage.setItem("currentUrl", window.location.pathname)

    request.fetchData(
      `${backend_url.payments_list}?${createUrl(['BUSINESS_UNITS', 'PAYMENT_ID'])}`,
      'GET',
      null,
      false,
      tokenBpxRequest
    )
    .then(data => {
    
      setRows(
        data.map((wrap) => {
          // setLines(
          //   wrap.lines.map(item => ({
          //     id: item.PAYMENT_ID + "" + item.VERSION + "" + item.AO_ID + "" + item.AO_VERSION,
          //     ACTIVITY_ID: wrap.ACTIVITY_ID,
          //     ...item,
          //     })
          //   )
          // )
          
          return {
            id: wrap.UNIQUE_ID,
            ...wrap,
          }
        })
      )

    })


    request.fetchData(
      `${backend_url.payments_erp}?${createUrl(['business_unit', 'PAYMENT_ID'])}`,
      'GET',
      null,
      false,
      tokenBpxRequest,
      false
    )
    .then(data => {

      const reference_doc = 
        Object
          .values(data)
          .map(k => k["REFERENCE_DOC_KEY"])
          .filter(onlyUnique)
          .join(", ")

      setDocumentKeys(
        reference_doc
      )

    })

  
  }, [])
  

  const handleSelectRow = (ids) => {

    const selectedID = new Set(ids)
    const selectedRowData = rows.filter(item => 
      selectedID.has(item.id.toString())  
    )
    const getLines = selectedRowData.map(wrap => { 
      return wrap.lines.map(item => ({
          id: item.PAYMENT_ID + "" + item.VERSION + "" + item.AO_ID + "" + item.AO_VERSION,
          ACTIVITY_ID: wrap.ACTIVITY_ID,
          ...item,
          })
      )
    })
    setLines(getLines.flat())
    setSelectionModel(ids)
  }

  const columns = [
    { 
      field: "id", 
      headerName: t("Payment.PAYMENT_ID") 
    },
    { 
      field: "AO_ID", 
      headerName: t("Payment.AO_ID"), 
      flex: 1 
    },
    { 
      field: "ACTIVITY_ID", 
      headerName: t("Payment.ACTIVITY_ID"), 
      flex: 1 
    },
    { 
      field: "EXT_REFERENCE", 
      headerName: t("Payment.EXT_REFERENCE"), 
      flex: 1 
    },
    { 
      field: "PAYMENT_TYPE", 
      headerName: t("Payment.PAYMENT_TYPE"), 
      flex: 1 
    },
    { 
      field: "SALES_AREA", 
      headerName: t("Payment.SALES_AREA"), 
      flex: 1 
    },
    { 
      field: "PLAN_ACCOUNT_BP_NR", 
      headerName: t("Payment.PLAN_ACCOUNT_BP_NR"), 
      flex: 1 
    },
    { 
      field: "PLAN_ACCOUNT_BP_CAT", 
      headerName: t("Payment.PLAN_ACCOUNT_BP_CAT"), 
      flex: 1 
    },
    { 
      field: "PAYER", 
      headerName: t("Payment.PAYER"), 
      flex: 1 
    },
    { 
      field: "DOCUMENT_DATE", 
      headerName: t("Payment.DOCUMENT_DATE"), 
      flex: 1,
      renderCell: ({ value }) => dateTimeFormatter(
        value, prefsData.date_format, prefsData.time_format, prefsData.time_zone
      ),
    },
    { 
      field: "POSTING_DATE", 
      headerName: t("Payment.POSTING_DATE"), 
      flex: 1,
      renderCell: ({ value }) => dateTimeFormatter(
        value, prefsData.date_format, prefsData.time_format, prefsData.time_zone
      ),
    },
    { 
      field: "REFERENCE_DOC", 
      headerName: t("Payment.REFERENCE_DOC"), 
      flex: 1 
    },
    { 
      field: "CANCELLED_PAYMENT", 
      headerName: t("Payment.CANCELLED_PAYMENT"), 
      flex: 1,
      renderCell: ({ value }) => {
        if (isNaN(value)) {
          return value
        }
        return numberFormatter(prefsData.decimal_notation).format(value)  
      },
      align: "right"
    },
    {
      field: "ORIGINAL_AMOUNT",
      headerName: t("Payment.ORIGINAL_AMOUNT"),
      flex: 1,
      renderCell: ({ value }) => {
        if (isNaN(value)) {
          return value
        }
        return numberFormatter(prefsData.decimal_notation).format(value)  
      },
      align: "right"
    },
    { 
      field: "ORIGINAL_CURRENCY", 
      headerName: t("Payment.ORIGINAL_CURRENCY"), 
      flex: 1 
    },
    {
      field: "CONFIRMED_AMOUNT",
      headerName: t("Payment.CONFIRMED_AMOUNT"),
      flex: 1,
      renderCell: ({ value }) => {
        if (isNaN(value)) {
          return value
        }
        return numberFormatter(prefsData.decimal_notation).format(value)  
      },
      align: "right"
    },
    { 
      field: "CONFIRMED_CURRENCY", 
      headerName: t("Payment.CONFIRMED_CURRENCY"), 
      flex: 1 
    },
    { 
      field: "CREATED_ON", 
      headerName: t("Payment.CREATED_ON"), 
      flex: 1,
      renderCell: ({ value }) => dateTimeFormatter(
        value, prefsData.date_format, prefsData.time_format, prefsData.time_zone
      ),
    },
    { 
      field: "STATUS", 
      headerName: t("Payment.STATUS"), 
      flex: 1 
    },
  ]

  const objBreadcrumb = [
    { label: t("Breadcrumb.home"), link: "/" },
    { label: t("Breadcrumb.payments"), link: "" },
    { label: t("Breadcrumb.myPayments"), link: "/payments/my-payments" },
    { label: t("Breadcrumb.paymentLines"), link: window.location.pathname },
  ]

  const linesColumn = [
    { 
      field: "VERSION", 
      headerName: t("PaymentLines.VERSION"), 
      flex: 1 
    },
    { 
      field: "AO_ID", 
      headerName: t("PaymentLines.AO_ID"), 
      flex: 1 
    },
    { 
      field: "ACTIVITY_ID", 
      headerName: t("Payment.ACTIVITY_ID"), 
      flex: 1 
    },
    { 
      field: "AO_VERSION", 
      headerName: t("PaymentLines.AO_VERSION"), 
      flex: 1 
    },
    { 
      field: "AE_AO_LINE_ID", 
      headerName: t("PaymentLines.AE_AO_LINE_ID"), 
      flex: 1 
    },
    { 
      field: "EXT_LINE_ID", 
      headerName: t("PaymentLines.EXT_LINE_ID"), 
      flex: 1 
    },
    { 
      field: "ACCR_PRODUCT_ID", 
      headerName: t("PaymentLines.ACCR_PRODUCT_ID"), 
      flex: 1 
    },
    { 
      field: "ORIGINAL_AMOUNT", 
      headerName: t("PaymentLines.ORIGINAL_AMOUNT"), 
      flex: 1,
      renderCell: ({ value }) => {
        if (isNaN(value)) {
          return value
        }
        return numberFormatter(prefsData.decimal_notation).format(value)  
      },
      align: "right"
    },
    { 
      field: "ORIGINAL_CURRENCY", 
      headerName: t("PaymentLines.ORIGINAL_CURRENCY"), 
      flex: 1 
    },
    { 
      field: "CONFIRMED_AMOUNT", 
      headerName: t("PaymentLines.CONFIRMED_AMOUNT"), 
      flex: 1,
      renderCell: ({ value }) => {
        if (isNaN(value)) {
          return value
        }
        return numberFormatter(prefsData.decimal_notation).format(value)  
      },
      align: "right"
    },
    { 
      field: "CONFIRMED_CURRENCY", 
      headerName: t("PaymentLines.CONFIRMED_CURRENCY"), 
      flex: 1 
    },
    { 
      field: "GROUP_NAME", 
      headerName: t("PaymentLines.GROUP_NAME"), 
      flex: 1 
    },
  ]
 
  const totalRows = `Total Rows: ${rows.length}`
  const selectRows = `${selectionModel.length} rows selected of ${rows.length}`

  const displayReferenceKeys = (
    <>
      <div style={{ fontSize: "0.875rem", padding: "0.5rem 0 0 0.5rem"}}>
        {selectionModel.length ? selectRows : totalRows}
      </div>
      {
        documentKeys.length === 0 ? "" : (
          <div className='bpx-reference-keys'>
            <p className='bpx-reference-text'>Reference Document Keys : {documentKeys}</p>
          </div>
        )
      }
    </>
    
  )

  const onChangeFontSize = (value)=>{
    setFontSize(value)
    saveUserPrefs({
      ...prefsData,
      reports_column_size:{
        ...prefsData.reports_column_size,
        Payment_Lines: value
      }
    })
  }
 

  return (
    <div className="tableContainer">
      <div className="block-lines">

        <div>
          <DataGridPro
            sx={{
              '& .MuiDataGrid-row': {
                height: `${checkSize(fontSize, 'row_height')}px !important`,
              },
              '& .MuiDataGrid-cell': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              },
              "& .MuiDataGrid-main" : {
                height: Math.min((rows.length||1) * checkSize(fontSize, 'row_height') + checkSize(fontSize, 'header_height'), 3 * checkSize(fontSize, 'row_height') + checkSize(fontSize, 'header_height')) + 'px',
                fontSize: checkSize(fontSize, 'font_size')
              },
              "& .MuiCheckbox-root": {
                transform: `scale(${checkSize(fontSize, 'scale')})`
              }
              // "& .MuiDataGrid-footerContainer": {
              //   justifyContent: "flex-start"
              // },
              // "& .MuiDataGrid-selectedRowCount": {
              //   order: 1
              // },
              // "& .MuiDataGrid-rowCount": {
              //   order: 2,
              // },
            }}
            localeText={{
              noRowsLabel: t("Common.noRows")
            }}
            // autoHeight
            rows={rows}
            columns={columns}
            disableSelectionOnClick={true}
            disableColumnFilter
            hideFooter={true}
            getRowHeight={() => 'auto'}
            headerHeight={checkSize(fontSize, 'header_height')}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
              
            }}
            components={{
              Toolbar: CustomToolbar,
            }}
            componentsProps={{
              toolbar: {
                bread: objBreadcrumb,
                title: t("Title.PaymentDetail", { payment_id: `${paramsHook.id}`}),
                displayColumn: false,
                isFontSize: true,
                fontSize: fontSize,
                onChangeFontSize: onChangeFontSize,
              }
            }}

            checkboxSelection
            onSelectionModelChange={handleSelectRow}
            selectionModel={selectionModel}
            experimentalFeatures={{ newEditingApi: true }}
          />

          {displayReferenceKeys}
        </div>

        <div className="block-lines-bottom">
          <h2 className="toolbarTitle">{t("Title.PaymentLines")}</h2>
          <DataGridPro
              sx={{ 
                '& .MuiDataGrid-row': {
                  minHeight: `${checkSize(fontSize, 'row_height')}px !important`,
                  height: 'auto !important'
                },
                '& .MuiDataGrid-cell': {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden'
                },
                '& .MuiDataGrid-main':{
                  fontSize: checkSize(fontSize, 'font_size')
                }
      
              }} 
            rows={lines}
            columns={linesColumn}
            disableSelectionOnClick={true}
            disableColumnFilter={true}
            hideFooter={true}
            getRowHeight={() => 'auto'}
            headerHeight={checkSize(fontSize, 'header_height')}
            localeText={{
              noRowsLabel: t("Common.noRows")
            }}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                  VERSION: false,
                  AO_VERSION: false
                },
              },
            }}
          />
        </div>

      </div>
    </div>
  )
}
