import React, { useEffect, useState, useRef } from 'react'
import {
  DataGridPro, GridFooterContainer, GridFooter,
  useGridApiContext, useGridSelector, gridFilteredDescendantCountLookupSelector
} from '@mui/x-data-grid-pro'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { useFetch } from '../../hooks'
import { Loading } from '..'
import { useTranslation } from 'react-i18next'
import SnackbarUtils from '../../utils/SnackbarUtils'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import { backend_url } from '../../settings'
import { ModalRDR, ModalDelete } from '../'
import { RestartAlt } from '@mui/icons-material'


const AccordionFetchGroup = ({
  id, data, columns, dataKey, label,
  collapse, fetching, url, idField, fetchKey,
  CRUD, endpointCRUD, keysCRUD, notFetchingExpand
}) => {

  const request = useFetch()
  const tokenBpxRequest = localStorage.getItem('token_bpx')
  const value_bu = localStorage.getItem('bu_bpx')
  const { t } = useTranslation('translation')
  const [expanded, setExpanded] = useState(notFetchingExpand ? dataKey : "")
  const [loading, setLoading] = useState(false)
  const [loadingTable, setLoadingTable] = useState(false)
  const [rows, setRows] = useState([])
  const [open, setOpen] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [update, setUpdate] = useState(true)
  const [editData, setEditData] = useState(null)
  const [page, setPage] = useState(0)
  const [count, setCount] = useState(0)
  const currentUser = JSON.parse(localStorage.getItem('current_user'))

  useEffect(() => {
    if (!update) {
      return
    }
    if (notFetchingExpand) {
      setLoadingTable(true)
      request.fetchData(
        `${url}&limit=${100}&skip=${0}`,
        "GET",
        null,
        false,
        tokenBpxRequest,
        false,
        false
      )
        .then(item => {
          const convert = item.map(child => {
            return {
              ...child,
              INTERVAL: child.INTERVAL.toString(),
              id: `${child.BP_DISTRIBUTOR_ROLE}-${child.BP_DISTRIBUTOR_NR}-${child.VALID_FROM}`,
            }
          })
          setRows(convert)
          setLoadingTable(false)
          setUpdate(false)
          setCount([...new Set(item.map(it => it.VALID_FROM))].length)
        })
        .catch((e) => {
          setRows([]);
          setLoadingTable(false)
          SnackbarUtils.error(JSON.stringify(`Server Error`));
          setUpdate(false)
        })

    }

  }, [update])

  useEffect(() => {
    if (page === 0) {
      return
    }
    if (notFetchingExpand) {
      setLoadingTable(true)
      request.fetchData(
        `${url}&limit=${100}&skip=${page}`,
        "GET",
        null,
        false,
        tokenBpxRequest,
        false,
        false
      )
        .then(item => {
          const convert = item.map(child => {
            return {
              ...child,
              INTERVAL: child.INTERVAL.toString(),
              id: `${child.BP_DISTRIBUTOR_ROLE}-${child.BP_DISTRIBUTOR_NR}-${child.VALID_FROM}`,
            }
          })
          setRows(prev => [...prev, ...convert])
          setLoadingTable(false)
          setUpdate(false)
          setCount(prev => prev + [...new Set(item.map(it => it.VALID_FROM))].length)
        })
        .catch((e) => {
          setLoadingTable(false)
        })
    }
  }, [page])

  const handleOnRowsScrollEnd = (params) => {
    if (params.viewportPageSize) {
      if (count >= 100) {
        return setPage(page + 100)
      } else {
        return null
      }
    }
  }

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const openEditDialog = (dateEdit) => {
    setEditData(rows.filter(it => it.VALID_FROM === dateEdit))
    setOpen(true)
  }

  const openDeleteDialog = (dateEdit) => {
    setEditData(rows.filter(it => it.VALID_FROM === dateEdit))
    setOpenDelete(true)
  }

  const groupingColDef = {
    headerName: t('BPDetail.RDR.Group_by'),
    renderCell: (params) => {
      function CustomGridTreeDataGroupingCell(props) {
        const { id, field, rowNode } = props
        const apiRef = useGridApiContext()

        const filteredDescendantCountLookup = useGridSelector(
          apiRef,
          gridFilteredDescendantCountLookupSelector
        )
        const filteredDescendantCount =
          filteredDescendantCountLookup[rowNode.id] ?? 0;

        const handleClick = (event) => {
          apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
          apiRef.current.setCellFocus(id, field);
          event.stopPropagation();
        };

        return (
          <div className='bpx-group-block' style={{ marginLeft: rowNode.depth * 4 }}>

            {filteredDescendantCount > 0 ? (
              <>
                <div className='bpx-group-left'>
                  <button onClick={handleClick}>
                    {rowNode.childrenExpanded ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
                  </button>
                  <button>
                    <EditIcon onClick={() => openEditDialog(rowNode.groupingKey)} />
                  </button>
                  <button>
                    <DeleteIcon onClick={() => openDeleteDialog(rowNode.groupingKey)} />
                  </button>
                </div>

                <div className='bpx-group-right'>
                  <div className='bpx-group-text'>
                    <span className='bpx-group-value'>
                      {rowNode.groupingKey}
                    </span>
                    <span className='bpx-group-count'>
                      ({filteredDescendantCount})
                    </span>
                  </div>
                </div>
              </>
            ) : (<></>)}
          </div>
        )
      }
      return <CustomGridTreeDataGroupingCell {...params} />
    },
  }

  const handleClose = () => {
    setEditData(null);
    setOpen(false)
  }

  const handleCloseDelete = () => {
    setEditData(null);
    setOpenDelete(false)
  }

  const table = [
    {
      type: "autoComplete",
      dataAutoComplate: {
        required: true,
        headerName: t("CHANGE_LOGS.TitleBP"),
        multiple: false,
        isTreeChar: true,
        field: 'BP_NR',
        keysForInput: ['BP_NR', 'NAME1', 'BP_ROLE'],
        url: backend_url.bp_list,
        valueParser: (value) => {
          return {
            BP_NR: value.BUSINESS_PARTNER,
            BP_ROLE: value.BP_ROLE,
            field: 'BUSINESS_PARTNER'
          }
        },
        valueFormatted: (value) => {
          return [value]
        },
      }
    },
    {
      type: "slider"
    }
  ]

  const createBody = (state, date) => {
    return {
      ...state,
      'BUSINESS_UNIT': value_bu,
      'BP_NR': data['BP_NR'],
      "BP_ROLE": data['BP_ROLE'],
      'VALID_FROM': date,
      'INTERVAL': 0,
      'LAST_CHANGED_BY': currentUser.email
    }
  }

  const postData = (state, date) => {
    const body = state.map(it => createBody(it, date))
    return request.fetchData(
      `${backend_url.relative_distribution_rates}?business_unit=${value_bu}`,
      "POST",
      JSON.stringify(body),
      false,
      tokenBpxRequest,
      false,
      false
    )
  }

  const putData = ({ state, date, editData }) => {
    const body = createBody(state, date)
    return request.fetchData(
      `${backend_url.relative_distribution_rates}/${value_bu}/${data['BP_NR']}/${data['BP_ROLE']}/${editData['BP_DISTRIBUTOR_NR']}/${editData['BP_DISTRIBUTOR_ROLE']}/${editData['VALID_FROM']}`,
      "PUT",
      JSON.stringify(body),
      false,
      tokenBpxRequest,
      false,
      false
    )
  }

  const deleteData = (editData) => {
    return request.fetchData(
      `${backend_url.relative_distribution_rates}/${value_bu}/${data['BP_NR']}/${data['BP_ROLE']}/${editData['BP_DISTRIBUTOR_NR']}/${editData['BP_DISTRIBUTOR_ROLE']}/${editData['VALID_FROM']}`,
      "DELETE",
      null,
      false,
      tokenBpxRequest,
      false,
      false
    )
  }

  const handleDelete = () => {
    const promisesDelete = []
    editData.map(it => {
      promisesDelete.push(deleteData(it))
    })
    Promise.all(promisesDelete)
      .then(() => {
        setEditData(null);
        setOpenDelete(false)
        setUpdate(true)
        setPage(0)
        SnackbarUtils.success("Successfully fetched")
      })
      .catch(() => {
        SnackbarUtils.error("Server Error")
      })
  }


  const promisesPutAgaing = (promisesPutData, res, state, date, count) => {
    if (!res.every(it => it.reason === 200 || it.reason === 422 || it.status === 'fulfilled')) {
      setLoading(false)
      SnackbarUtils.error("Server Error")
    } else if (res.find(it => it.status === "rejected" && it.reason === 422)) {
      if (count >= 5) {
        setLoading(false)
        SnackbarUtils.error("Server Error. Try again")
        return
      }
      count++
      const promisesPutNew = [];
      const promisesPutNewData = []
      res.map((it, ind) => {
        if (it.status === "rejected" && it.reason === 422) {
          promisesPutNewData.push(promisesPutData[ind])
          promisesPutNew.push(putData(promisesPutData[ind]))
        }
      })
      Promise.allSettled(promisesPutNew).then((res) => {
        promisesPutAgaing(promisesPutNewData, res, state, date, count)
      })
    } else {
      if (!state.filter(it => it.state === 'new').length) {
        handleClose()
        setUpdate(true)
        setPage(0)
        setLoading(false)
        SnackbarUtils.success("Successfully fetched")
        return
      }
      postData(state.filter(it => it.state === 'new'), date)
        .then(() => {
          handleClose()
          setUpdate(true)
          setPage(0)
          setLoading(false)
          SnackbarUtils.success("Successfully fetched")
        })
        .catch(() => {
          setLoading(false)
          SnackbarUtils.error("Server Error")
        })
    }
  }

  const handleSubmit = (condition, state, date) => {
    setLoading(true)
    if (condition === 'create') {
      postData(state, date).then(res => {
        handleClose()
        setUpdate(true)
        setPage(0)
        setEditData(null)
        setLoading(false)
        SnackbarUtils.success("Successfully fetched")
      }).catch(() => {
        SnackbarUtils.error("Server Error")
        setLoading(false)
      })
    } else {
      const promisesDelete = []
      state.map((it, ind) => {
        if (it.state === 'delete') {
          promisesDelete.push(deleteData(editData.find(el => el.id === it.id)))
        }
      })
      Promise.all(promisesDelete).then(() => {
        const promisesPut = []
        const promisesPutData = []

        state.map((it, ind) => {
          if (it.state === 'edit') {
            promisesPutData.push({ state: it, date, editData: editData.find(el => el.id === it.id) })
            promisesPut.push(putData({ state: it, date, editData: editData.find(el => el.id === it.id) }))
          }
        })
        Promise.allSettled(promisesPut).then((res) => {
          if (!res.every(it => it.reason === 200 || it.reason === 422 || it.status === 'fulfilled')) {
            SnackbarUtils.error("Server Error")
            setLoading(false)
          } else if (res.find(it => it.status === "rejected" && it.reason === 422)) {
            let count = 1
            promisesPutAgaing(promisesPutData, res, state, date, count)

          } else {
            if (!state.filter(it => it.state === 'new').length) {
              handleClose()
              setUpdate(true)
              setPage(0)
              setLoading(false)
              SnackbarUtils.success("Successfully fetched")
              return
            }
            postData(state.filter(it => it.state === 'new'), date)
              .then(() => {
                handleClose()
                setUpdate(true)
                setPage(0)
                setLoading(false)
                SnackbarUtils.success("Successfully fetched")
              })
              .catch(() => {
                setLoading(false)
                SnackbarUtils.error("Server Error")
              })
          }
        })
      })
        .catch(() => {
          setLoading(false)
          SnackbarUtils.error("Server Error")
        })
    }
  }
  return (
    <>
      <Accordion expanded={expanded === dataKey} onChange={handleChange(dataKey)}>
        <AccordionSummary expandIcon={<ArrowRightIcon className='bpx-arrow-icon' />} className='bpx-accordion-header'>
          <p>{label}</p>
        </AccordionSummary>
        <AccordionDetails className='bpx-accordion-detail'>
          <div style={{ height: 'auto', width: '100%' }}>
            <DataGridPro
              treeData
              getTreeDataPath={(row) => [row.VALID_FROM, row.id]}
              groupingColDef={groupingColDef}
              sx={{ '& .MuiDataGrid-main': { height: rows.length < 8 ? '100%' : '380px' } }}
              autoHeight={Boolean(rows.length < 8)}
              rows={rows}
              columns={columns}
              loading={loadingTable}
              // pageSize={50}
              onRowsScrollEnd={handleOnRowsScrollEnd}
              rowsPerPageOptions={[20, 50, 100]}
              density="compact"
              hideFooter={true}
              localeText={{
                noRowsLabel: t("Common.noRows")
              }}
              components={{
                LoadingOverlay: Loading
              }}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    id: !Boolean(idField),
                    CONSECUTIVE_NUMBER: false,
                  },
                },
              }}
              disableSelectionOnClick
            />
            <div className="bpx-config-datagrid-bottom">
              <Button
                className="bpx-config-datagrid-btn bpx-fetch-adding-btn"
                startIcon={<AddIcon />}
                sx={{ marginTop: "0 !important" }}
                onClick={(e) => setOpen(true)}
              />

            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <ModalDelete open={openDelete} onClose={handleCloseDelete} onSubmit={handleDelete} />
      <ModalRDR allData={rows} table={table} open={open} onClose={handleClose} handleSubmit={handleSubmit} editData={editData} loading={loading} />
    </>
  )
}

export default AccordionFetchGroup
