import React from "react"
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { Grid, FormHelperText, Typography } from "@mui/material";
import { useTranslation } from "react-i18next"

import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'

export const DialogAdjustments = ({
    dialogOpen, 
    handleClose, 
    dialogType, 
    planAccount, 
    acAd, 
    handleAction,
    isManual = true,
    isZeroBudget = true,
    adjustmentList = true, 
    isDateOutside = false,
    budgetWarning = false,
    isDateExcluded = {dateFrom: false, dateTo: false},
    isDateOverwrite = {dateFrom: false, dateTo: false},
    handleModalClose = null}) => {

    const radioGroupRef = React.useRef(null);
    const { t } = useTranslation("translation")

    const handleEntering = () => {
        if (radioGroupRef.current != null) {
          radioGroupRef.current.focus();
        }
    };

    const handleConfirmButton = () => {
        if (adjustmentList) {
            handleAction(dialogType.toLowerCase(), acAd)
        } else {
            handleAction(dialogType === "Simulation" ? "simulate" : "execute")
            handleModalClose()
        }
        handleClose()
    }

    const showWarnings = () => {
        return !isManual || !isZeroBudget || isDateOutside || budgetWarning
    }

    const buttonName = (dialogType) => {
        return dialogType === "Simulation" ? "Simulate" : "Execute"
    }

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="xs"
            TransitionProps={{ onEntering: handleEntering }}
            open={dialogOpen}
            onClose={handleClose}
        >
            {adjustmentList ? 
                <DialogContent sx={{textAlign: "center"}}>
                    <p>{t("Adjustments.MODAL.Are_you_sure_you_want_to")} <strong>{t(`Adjustments.MODAL.${dialogType}`)}</strong> {t("Adjustments.MODAL.this")} <strong>{t("Adjustments.MODAL.TITLE")}</strong> {t("Adjustments.MODAL.for")} {planAccount}?</p>
                    <div className="close-for-modal-window position-smaller">
                      <IconButton onClick={handleClose} className="wrap-cancel-modal">
                        <CloseIcon />
                      </IconButton>
                    </div>
                </DialogContent> :
              
                <DialogContent sx={{textAlign: "center"}}>
                    <Typography variant="subtitle2">{t("Adjustments.MODAL.title3")} {t(`Adjustments.MODAL.${buttonName(dialogType)}`)} {t("Adjustments.MODAL.Calculation")}</Typography>
                    <hr/>
                    <p>{t("Adjustments.MODAL.Are_you_sure_you_want_to")} <strong>{t(`Adjustments.MODAL.${buttonName(dialogType)}`)}</strong> {t("Adjustments.MODAL.this")} <strong>{t("Adjustments.MODAL.TITLE")}</strong> {t("Adjustments.MODAL.for")} {planAccount}?</p>
                    {showWarnings() && <span>{t("Adjustments.MODAL.unresolved_issues")}:</span>}
                    {!isManual ? <FormHelperText sx={{color: "#ed7d31"}}>{t("Adjustments.MANUAL_WARNING")}</FormHelperText> : null}
                    {isZeroBudget ? null : <FormHelperText sx={{color: "#ed7d31"}}>{t("Adjustments.BUDGET_ZERO_WARNING")}</FormHelperText>}
                    {isDateOutside ? <FormHelperText sx={{color: "#ed7d31"}}>{t("Adjustments.AO_VALIDITY_WARNING")}</FormHelperText>: null}
                    {budgetWarning ? <FormHelperText sx={{color: "#ed7d31"}}>{t("Adjustments.AMOUNTS_WARNING")}</FormHelperText>: null}
                    {(isDateExcluded.dateFrom || isDateExcluded.dateTo) ? <FormHelperText sx={{color: "#ed7d31"}}>{t("Adjustments.DATE_EXCLUDED_WARNING")}</FormHelperText>: null}
                    {(isDateOverwrite.dateFrom || isDateOverwrite.dateTo) ? <FormHelperText sx={{color: "#ed7d31"}}>{t("Adjustments.DATE_OVERWRITE_WARNING")}</FormHelperText>: null}
                </DialogContent>
            }
            <DialogActions> 
                <Button variant="outlined" onClick={handleClose}>
                    {dialogType === "Cancel" ? "No" : t("MODAL.BUTTONS.CANCEL")}
                </Button>
                {!adjustmentList ?
                    <Button variant="contained" sx={{backgroundColor: dialogType === "Simulation" ? "" :"#ed7d31", color: "black"}} onClick={handleConfirmButton}>{showWarnings() ? t(`Adjustments.MODAL.BUTTON.${buttonName(dialogType)}`) + ' ' + t(`Adjustments.MODAL.BUTTON.Anyway`) : t(`Adjustments.MODAL.BUTTON.${buttonName(dialogType)}`)}</Button>
                    :
                    <Button variant="contained" sx={{backgroundColor: "#ed7d31", color: "black"}} onClick={handleConfirmButton}>{dialogType === "Cancel" ? "Yes - Cancel" : "Yes"}</Button>
                }
            </DialogActions>
        </Dialog>
    )
}
