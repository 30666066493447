import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Button, TextField } from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { useFetch } from '../../../../hooks'
import { backend_url } from '../../../../settings'
import SnackbarUtils from '../../../../utils/SnackbarUtils';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import { getParam } from "../../../../utils/utils"
import FormControl from '@mui/material/FormControl'
import moment from 'moment'
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import { LoadingWithText } from '../../../../components'
import { useTranslation } from 'react-i18next'
import AutoCompleteComponent from './AutoCompleteComponent'

import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'


export default function ModalAssignNewRole({ open, handleClose, onHandleSubmit, prevState, loadingFetch }) {

  const tokenBpxRequest = localStorage.getItem('token_bpx')
  const request = useFetch()

  const { t } = useTranslation(['translation'])

  const [loading, setLoading] = useState(null);

  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {

    setLoading(loadingFetch)
  }, [loadingFetch])

  const initialFormAddRole = [
    {
      id: "1",
      label: t("UserDetail.ROLE"),
      name: "ROLE_ID",
      type: "select",
      required: true,
      valueOptions: [],
      value: "",
      disabled: false,
    },
    {
      id: "2",
      label: t("UserDetail.DATE_VALID_FROM"),
      name: "DATE_VALID_FROM",
      type: "date",
      required: true,
      value: ""
    },
    {
      id: "3",
      label: t("UserDetail.DATE_VALID_TO"),
      name: "DATE_VALID_TO",
      type: "date",
      required: true,
      value: ""
    },
    {
      id: "4",
      label: t("UserDetail.BUSINESS_UNIT"),
      name: "BUSINESS_UNIT",
      type: 'hidden',
      required: true,
      valueOptions: [],
      isHidden: true,
      subType: 'select',
      value: []
    },
    {
      id: "5",
      label: t("UserDetail.BUSINESS_PARTNER"),
      name: "BUSINESS_PARTNER",
      type: 'hidden',
      valueOptions: [{
        CUSTOMER_HIERARCHY: '',
        BUSINESS_PARTNER: 'all',
        type: 'all',
        name: 'Business Partner'
      }],
      isHidden: true,
      subType: 'autocomplete',
      required: true,
      value: []
    },
  ]

  const [form, setForm] = useState(initialFormAddRole)

  const [state, setState] = useState(
    {
      ROLE_ID: '',
      DATE_VALID_FROM: null,
      DATE_VALID_TO: null,
      BUSINESS_PARTNER: [],
      BUSINESS_UNIT: '',
      CUSTOMER_HIERARCHY: [],
    }
  )

  const assigmentRole = {
    'AE Business Unit Basic': ['BUSINESS_UNIT']
  }

  const options = [
    {
      field: 'ROLE_ID',
      url: backend_url.roles,
      endpoint: ['USER_ID'],
      code: "",
      endpointLS: [{ field: 'current_user', value: 'username' }],
    },
    {
      field: 'BUSINESS_UNIT',
      url: backend_url.config_business_units,
      code: "BUSINESS_UNIT",
      addItem: 'all'
    },
  ]

  useEffect(() => {

    options.map(ww => {
      const { url, code, field, addItem, endpointLS } = ww

      request.fetchData(
        url + getUrlForGet(endpointLS),
        'GET',
        null,
        false,
        tokenBpxRequest,
        false,
        false
      )
        .then(data => {
          let valueArray = data.map(tt => {
            if (tt[code]) {
              return tt[code]
            }
            return tt;
          })

          let unique = valueArray.filter((v, i, a) => a.indexOf(v) === i);

          if (addItem) {
            unique.push(addItem)
          }

          setForm(form.map((it) => {
            if (it.name === field) {
              it.valueOptions = unique;
            } return it;
          }))
        })
      return ww
    })
  }, [])


  const fetchNameBusinessPartner = (arr, type) => {
    if (!arr.length) {
      Promise.resolve([])
    }
    return arr.map(it => {
      if (it === 'all') {
        return Promise.resolve({
          CUSTOMER_HIERARCHY: '',
          BUSINESS_PARTNER: 'all',
          type: 'all',
          name: 'Business Partner'
        })
      } else {
        return request.fetchData(
          backend_url.cust_hier + getUrl([{ field: 'BUSINESS_UNIT', name: 'bu_bpx' }], [{ field: 'BP_NR', value: it }, { field: 'date', value: moment(new Date()).format("YYYY-MM-DD") }]),
          'GET',
          null,
          false,
          tokenBpxRequest,
          false,
          false
        )
          .then(data => {
            if (data.length) {
              const arr = data.filter(el => el.BP_NR === it)
              if (arr.length) {
                return {
                  BUSINESS_PARTNER: arr[0].BP_NR,
                  CUSTOMER_HIERARCHY: type === 'BUSINESS_PARTNER' ? arr[0].PARENT_BP_NR : arr[0].BP_NR,
                  type,
                  name: arr[0].bp.NAME1,
                }
              } else {
                return {
                  BUSINESS_PARTNER: it,
                  CUSTOMER_HIERARCHY: it,
                  type,
                  name: '',
                }
              }
            } else {
              return request.fetchData(
                backend_url.bp_list + getUrl([{ field: 'business_unit', name: 'bu_bpx' }], [{ field: 'BP_NR', value: it }, { field: 'date', value: moment(new Date()).format("YYYY-MM-DD") }]),
                'GET',
                null,
                false,
                tokenBpxRequest,
                false,
                false
              ).then(data => {
                return {
                  BUSINESS_PARTNER: it,
                  CUSTOMER_HIERARCHY: it,
                  type,
                  name: data[0].NAME1,
                }
              })
            }
          })
      }
    }
    )
  }


  useEffect(() => {
    if (open) {
      setLoading(true);
    }


    if (prevState) {
      setState(prevState);
      Promise.all([
        ...fetchNameBusinessPartner(prevState.BUSINESS_PARTNER, 'BUSINESS_PARTNER'),
        ...fetchNameBusinessPartner(prevState.CUSTOMER_HIERARCHY, 'CUSTOMER_HIERARCHY')
      ])
        .then(data => {
          setLoading(false)
          setSelectedOptions(data);
        })
        .catch(() => {
          setLoading(false)
          setSelectedOptions([]);
        })

      const transform = form.map(item => {
        if (item.name === 'ROLE_ID') {
          return { ...item, disabled: true }
        }
        if (item.isHidden) {
          if (assigmentRole[prevState.ROLE_ID]) {
            return { ...item, type: assigmentRole[prevState.ROLE_ID].includes(item.name) ? item.subType : 'hidden' }
          }
          return { ...item, type: item.subType }
        }

        return item;
      })
      setForm(transform);
    } else {
      setState({
        ROLE_ID: '',
        DATE_VALID_FROM: null,
        DATE_VALID_TO: null,
        BUSINESS_PARTNER: [],
        BUSINESS_UNIT: '',
        CUSTOMER_HIERARCHY: [],
      });
      setSelectedOptions([]);

      const transform = form.map(item => {
        if (item.name === 'ROLE_ID') {
          return { ...item, disabled: false }
        }
        if (item.isHidden) {
          return { ...item, type: 'hidden' }
        }
        return item;
      })
      setForm(transform);
      setLoading(false);
    }
  }, [open])


  const getUrlForGet = (endpointLS = []) => {
    let link = ''
    if (endpointLS) {
      endpointLS.map(it => {
        const localStorageValue = JSON.parse(localStorage.getItem(it.field));
        if (localStorageValue && localStorageValue[it.value]) {
          link += `/${localStorageValue[it.value]}`;
        }
      })
    }
    return link
  }


  const getUrl = (endpointForGet, params = [], skip = 0) => {
    let param = {
      limit: 20,
      skip
    };

    if (endpointForGet) {
      endpointForGet.forEach(it => {
        if (localStorage.getItem(it.name)) {
          param[it.field] = localStorage.getItem(it.name);
        }
      })
    }
    if (params) {
      params.map(it => {
        param[it.field] = it.value;
        return it;
      })
    }
    const queryString = getParam(param).toString();
    return '?' + queryString;
  }

  const formatFunc = (value) => {
    if (!value) {
      return null;
    }
    return moment(new Date(value)).format("YYYY-MM-DD")
  }


  const isValidateDate = (from, to) => {

    if (
      !from || !to ||
      new Date(from) >= new Date(to)
    ) {
      return false;
    }
    return true;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isValidateDate(state.DATE_VALID_FROM, state.DATE_VALID_TO)) {
      SnackbarUtils.error("Please add correct dates")
      return;
    }
    const isNew = !prevState;

    if (isNew) {
      onHandleSubmit({ data: state, action: "POST" })
    } else {
      onHandleSubmit({ data: state, action: "PUT" })
    }
  }



  const onChangeHandler = (name, value) => {

    setState((prevState) => ({ ...prevState, [name]: value }));
    if (name === 'ROLE_ID') {
      const transform = form.map(item => {
        if (item.isHidden) {
          if (assigmentRole[value]) {
            return { ...item, type: assigmentRole[value].includes(item.name) ? item.subType : 'hidden' }
          }
          return { ...item, type: item.subType }
        }

        return item;
      })

      setForm(transform);
      if (assigmentRole[value]) {
        setState(prevState => ({
          ...prevState,
          BUSINESS_PARTNER: [],
          CUSTOMER_HIERARCHY: []
        }));
        setSelectedOptions([])
      }

    }
  }


  const displayForm = (fields) => {
    const mapping = fields.map(item => {
      const { id, label, name, type, required, value, valueOptions, disabled } = item

      switch (type) {
        case "select":
          return (
            <div className='bpx-userid-section' key={id} >
              <div className='bpx-userid-field-new-role'>
                <FormControl size="small">
                  <InputLabel id="demo-simple-select-label">{label}</InputLabel>
                  <Select
                    label={label}
                    name={name}
                    labelId="demo-simple-select-label"
                    value={state[name]}
                    required={required}
                    placeholder={label}
                    disabled={disabled}
                    onChange={(e) => {
                      const { name, value } = e.target;
                      onChangeHandler(name, value)
                    }}
                    className='bpx-userid-select'
                  >
                    {
                      valueOptions ?
                        valueOptions.map((it, i) => {
                          return <MenuItem key={i} value={it}>{it}</MenuItem>
                        }) :
                        null
                    }
                  </Select>
                </FormControl>
              </div>
            </div>
          )
        case "multiSelect":
          return (
            <div className='bpx-userid-section' key={id} >
              <div className='bpx-userid-field-new-role'>
                <FormControl size="small">
                  <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
                  <Select
                    name={name}
                    id="demo-multiple-checkbox"
                    labelId="demo-multiple-checkbox-label"
                    value={state[name]}
                    required={required}
                    onChange={({ name, value }) => onChangeHandler(name, value)}
                    className='bpx-userid-select'
                    placeholder={label}
                    multiple
                    input={<OutlinedInput label={label} />}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value, i) => (
                          <Chip key={i} label={value} />
                        ))}
                      </Box>
                    )}
                  >
                    {
                      valueOptions ?
                        valueOptions.map((it, i) => (
                          <MenuItem key={i} value={it} disabled={it !== 'all' && state[name].includes('all')} >
                            <Checkbox checked={state[name].includes(it) || state[name].includes('all')} />
                            <ListItemText primary={it} />
                          </MenuItem>
                        )) :
                        null
                    }
                  </Select>
                </FormControl>
              </div>
            </div>
          )
        case 'autocomplete':
          return (
            <div className='bpx-userid-section' key={id} >
              <div className='bpx-userid-field-autocomplete'>
                <FormControl size="small">
                  <AutoCompleteComponent state={state} data={item} onChangeHandler={onChangeHandler} prevState={selectedOptions} />
                </FormControl>
              </div>
            </div>
          )
        case "text": case "number":
          return (
            <div className='bpx-userid-section' key={id}>
              <div className='bpx-userid-field-new-role'>
                <TextField
                  type={type}
                  variant="outlined"
                  name={name}
                  size="small"
                  placeholder={label}
                  required={required}
                  value={state[name]}
                  onChange={({ name, value }) => onChangeHandler(name, value)}
                />
              </div>
            </div>
          )
        case "date":
          return (
            <div className='bpx-userid-section' key={id}>
              <div className='bpx-userid-field-new-role'>
                <LocalizationProvider dateAdapter={AdapterDateFns} >
                  <DesktopDatePicker
                    inputFormat="yyyy-MM-dd"
                    mask="____-__-__"
                    label={label}
                    onChange={(e) => { onChangeHandler(name, formatFunc(e)) }}
                    value={state[name] || null}

                    renderInput={(params) =>
                      <TextField
                        {...params}
                        name={name}
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "YYYY-MM-DD",
                        }}
                        error={!state[name]}
                      />
                    }
                  />
                </LocalizationProvider>

              </div>
            </div>
          )
        case "hidden":
          return (
            <input
              key={id}
              name={name}
              type="hidden"
              value={value}
              size="small"
            />
          )
        default:
          return;
      }
    })

    return mapping
  }


  const displayBlockForm = () => {

    return (
      <>
        <DialogTitle className='bpx-userid-title'>
          {prevState ? t("UserDetail.CHANGE_DATA") : t("UserDetail.ADD_DATA")}
          <div className="close-for-modal-window position-smaller">
            <IconButton onClick={handleClose} className="wrap-cancel-modal">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          {displayForm(form)}
        </DialogContent>
        <DialogActions className='bpx-userid-section'>
          <Button onClick={handleClose} color="error" variant="contained">
            {t("MODAL.BUTTONS.CANCEL")}
          </Button>
          <Button
            type="submit"
            autoFocus
            variant="contained"
            color="primary"
          >
            {prevState ? t("MODAL.BUTTONS.SAVE") : t("MODAL.BUTTONS.CREATE")}
          </Button>
        </DialogActions>
      </>
    )
  }

  const displayLoading = (
    loading
      ? <LoadingWithText text="Your request is being processed, please wait." size={50} />
      : displayBlockForm()
  )

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <div className='bpx-userid-modal'>
        <form style={{ height: "100%" }} onSubmit={handleSubmit}>
          {displayLoading}
        </form>
      </div>
    </Dialog>
  )

}
