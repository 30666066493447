import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { SimulationResultsTable } from './SimulationResultsTable'
import { useFetch } from "../../hooks"
import { backend_url } from "../../settings"
import { Grid, Button } from "@mui/material"
import { AdjustmentsLogsTable } from './AdjustmentsLogsTable';
import { useUserPrefs } from '../UserPreferences/ProvideUserPrefs';
import { dateTimeFormatter} from "../../utils/utils"
import { useTranslation } from "react-i18next"
import { DateTime } from 'luxon';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
//@Dos
import SimulationModalExport from './SimulationModalExport'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 1700,
  maxHeight: 1000,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export const AdjustmentResultsModal = ({
    open, 
    handleClose, 
    acAd, 
    eventId1, 
    eventId2, 
    planAccount,    
    simulation, 
    aoId,
    budget1 = null,
    budget2 = null,
    status = null,
    setting
}) => {

    const { t } = useTranslation("translation")
    const value_bu = localStorage.getItem("bu_bpx")
    const tokenBpxRequest = localStorage.getItem("token_bpx")
    const request = useFetch()
    const [adjustmentData, setAdjustmentData] = useState({postingData1: null, postingData2: null})
    const [adjustmentLogs, setAdjustmentLogs] = useState()
    const [currency, setCurrency] = useState()
    const [periods, setPeriods] = useState()
    const { prefsData } = useUserPrefs()
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    //@Dos
    const [openExport, setOpenExport] = useState(false)

    useEffect(() => {
        if (simulation) {
            request.fetchData(
                `${backend_url.accrual_posting_documents}/${eventId1}?business_unit=${value_bu}`,
                "GET",
                null,
                false,
                tokenBpxRequest,
                false
            )
            .then(data => {
                setAdjustmentData((prevState) => (
                    {...prevState, postingData1: data}
                ))
            })
            request.fetchData(
                `${backend_url.accrual_posting_documents}/${eventId2}?business_unit=${value_bu}`,
                "GET",
                null,
                false,
                tokenBpxRequest,
                false
            )
            .then(data => {
                setAdjustmentData((prevState) => (
                    {...prevState, postingData2: data}
                ))
            }
            )
        } else if (acAd) {
            request.fetchData(
                `${backend_url.accrual_adjustment}/${acAd}/versions?business_unit=${value_bu}`,
                "GET",
                null,
                false,
                tokenBpxRequest,
                false
            )
            .then(data => {
                setAdjustmentLogs(data)
            })
        }
    
    }, [eventId1, eventId2, simulation])

    useEffect(() => {
        if (aoId) {
            request.fetchData(
                `${backend_url.accrual_object_header}/${aoId}?business_unit=${value_bu}`,
                'GET',
                null,
                false,
                tokenBpxRequest,
                false
            )
            .then(aoData => {
                setCurrency(aoData.AO_CURRENCY)
                var calendar = "PL01"
                if (aoData.run_plan != null) {
                    calendar = aoData.run_plan.CALENDAR   
                }        
                request.fetchData(
                    `${backend_url.config_calendar_periods}?skip=0&limit=100000&CAL_ID=${calendar}&PERIOD_TYPE=W`,
                    'GET',
                    null,
                    false,
                    tokenBpxRequest,
                    false
                )
                .then(data => {
                    setPeriods(data)
                })
            })
        }   
    }, [aoId])

    //@Dos
    const handleClickOpen = () => {
      setOpenExport(true)
    }

    const checkPeriod = (date) => {  
        var checkedPeriod = ""
        periods.map(period => {
            if (period.DATE_PERIOD_FROM <= DateTime.fromISO(date).toISODate() && DateTime.fromISO(date).toISODate() <= period.DATE_PERIOD_TO) {
                checkedPeriod = period.PERIOD_ID
            }     
        })
        return days[DateTime.fromISO(date).toJSDate().getDay()] + ', ' + checkedPeriod
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} style={{ width: '98%' }}>
                {simulation ?
                <>
                  <IconButton
                      onClick={handleClose}
                      className="wrap-cancel-modal"
                      sx={{
                        position: "absolute",
                        right: "27px",
                        top: "27px",
                        padding: "0.2rem",
                        color: "#9d9d9d",
                      }}
                    >
                      <CloseIcon />
                  </IconButton>
                    <Typography sx={{textAlign: "center", fontWeight: "700"}} id="modal-modal-title" variant="body1" component="h6">
                        {t("Adjustments.MODAL.TITLE")} {acAd} | {(status === "Calculated" || status === "Completed") ? t("Adjustments.EXECUTION"): t("Adjustments.SIMULATION")}
                    </Typography>
                    <hr/>
                    <Grid container>
                        <Grid item xs={6}>
                            <p style={{margin: '0', marginTop: "10px", fontSize: "12px"}}>
                            {t("Adjustments.MODAL.Calculation_Date_From")}:&nbsp;<strong>{adjustmentData.postingData1 
                                && dateTimeFormatter(adjustmentData.postingData1.acd_calc_start_date, prefsData.date_format, prefsData.time_format, prefsData.time_zone, false, false)}</strong>
                                &nbsp;
                                <span style={{fontStyle: "italic"}}>({(adjustmentData.postingData1 && periods) && checkPeriod(adjustmentData.postingData1.acd_calc_start_date)})</span>
                            </p>
                        </Grid>
                        <Grid item xs={6}>
                            <p style={{margin: '0', marginTop: "10px", fontSize: "12px"}}>{t("Adjustments.MODAL.1st_Posting_budget")}: &emsp;<strong>{budget1} {currency}</strong></p>
                        </Grid>
                        <Grid item xs={6}>
                            <p style={{margin: '0', fontSize: "12px"}}>
                            {t("Adjustments.MODAL.Calculation_Date_To")}: &emsp; <strong>{adjustmentData.postingData1 
                                && dateTimeFormatter(adjustmentData.postingData1.acd_calc_end_date, prefsData.date_format, prefsData.time_format, prefsData.time_zone, false, false)}</strong>
                                &nbsp;
                                <span style={{fontStyle: "italic"}}>({(adjustmentData.postingData1 && periods) && checkPeriod(adjustmentData.postingData1.acd_calc_end_date)})</span>
                            </p>
                        </Grid>
                        <Grid item xs={6}>
                            <p style={{margin: '0', fontSize: "12px"}}>{t("Adjustments.MODAL.2nd_Posting_budget")}: &emsp;<strong>{budget2} {currency}</strong></p>
                        </Grid>
                        <Grid item xs={6}>
                            <p style={{margin: '0', marginBottom: "25px",fontSize: "12px"}}>{t("Adjustments.MODAL.Duration")}: &emsp;&emsp;&emsp;&emsp;&emsp;&emsp; <strong>{adjustmentData.postingData1 && adjustmentData.postingData1.acd_calc_days} days</strong></p>
                        </Grid>
                    </Grid>
                    <SimulationResultsTable adjustmentData={adjustmentData}/>
                </>
                :
                <>
                    <IconButton
                      onClick={handleClose}
                      className="wrap-cancel-modal"
                      sx={{
                        position: "absolute",
                        right: "20px",
                        top: "17px",
                        padding: "0.2rem",
                        color: "#9d9d9d",
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                    <Typography sx={{textAlign: "center"}} id="modal-modal-title" variant="body1" component="h6">
                        <strong>{t("Adjustments.MODAL.TITLE")} {acAd} | {t("Adjustments.MODAL.Change_Logs")}</strong>
                    </Typography>
                    <hr/>
                    <Grid spacing={0} container direction="row" justifyContent="flex-start">
                        <Grid item xs={12}>
                            <p style={{margin: '0', marginTop: "10px", fontSize: '12px'}}>{t("Adjustments.PLAN_ACCOUNT")}: &nbsp; &nbsp; &nbsp; &nbsp; <strong>{planAccount}</strong></p>
                        </Grid>
                        <Grid item xs={12}>
                            <p style={{margin: '0', fontSize: '12px'}}>{t("Adjustments.AO_ID")}:  &nbsp;<strong>{aoId}</strong></p>
                        </Grid>
                    </Grid>
                    <br/>
                    <AdjustmentsLogsTable adjustmentLogs={adjustmentLogs}/>
                </>
                }
                <br/>
                <Grid container spacing={2} justifyContent="right">
                    <Grid item>
                        <Button size='small' variant="outlined" onClick={handleClose}>{t("MODAL.BUTTONS.CLOSE")}</Button>
                    </Grid>
                    {simulation &&
                        <Grid item>
                            <SimulationModalExport 
                              open={openExport} 
                              setOpen={setOpenExport}
                              setting={setting.exportSetting}
                            />
                            <Button size='small' onClick={() => handleClickOpen()} variant="contained">
                            {t("Buttons.Export")}
                            </Button>
                        </Grid>
                    }
                </Grid>
            </Box>
        </Modal>
    );
}
