import {
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem
} from '@mui/x-data-grid-pro'
import ExcelExport from './exports/ExcelExport'


const CustomExport = ({pdfExport, fileName, ...props}) => (

    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem 
        sx={{ paddingLeft: "0.5rem", fontSize: "0.8rem" }}
        options={{
          utf8WithBom: true,
          fileName: fileName ? fileName : ""
        }}
      />
      
      {pdfExport && 
        <GridPrintExportMenuItem
          sx={{ paddingLeft: "0.5rem", fontSize: "0.8rem"}}
          options={{
            hideFooter: true,
            pageStyle: `
              @media print {
                @page {
                  size: landscape;
                }
                .MuiDataGrid-toolbar { display: none }
                .MuiDataGrid-root {
                  transform: scale(0.85);
                }
              }`,
            fileName: 'Audit List'
          }}
        />
      }
      {props.setting && <ExcelExport {...props} />}
    </GridToolbarExportContainer>
)

export default CustomExport
