import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { backend_url } from '../../settings'
import { FlexibleTabs, LoadingWithText, DialogChangeLogs } from '../../components'
import { useTranslation } from 'react-i18next'
import { useFetch } from '../../hooks'
import { dateTimeFormatter } from '../../utils/utils';
import { useUserPrefs } from '../UserPreferences/ProvideUserPrefs';

export default function MaterialsDetail({id=null, materialPopup=false, isHideDeleted = false, prevBusinessUnit=null, handlePopup=()=>{}}) {

  const { t, i18n } = useTranslation()
  const value_bu = prevBusinessUnit || localStorage.getItem("bu_bpx")
  const tokenBpxRequest = localStorage.getItem('token_bpx')

  let params =  useParams()
  if(id){
    params.id = id
  }
  const request = useFetch()

  const [data, setData] = useState({})
  const [descType, setDescType] = useState('')
  const [desc, setDesc] = useState('')
  const { prefsData } = useUserPrefs()
  const [loading, setloading] = useState(true)
  const [open, setOpen] = useState(false)

  useEffect(() => {

    request.fetchData(
      `${backend_url.mat_detail}/${value_bu}/${params.id}/detail_with_matclass`,
      'GET',
      null,
      false,
      tokenBpxRequest,
      false
    )
    .then(data => {
      if(data){
        if(isHideDeleted&&data.AE_MATERIAL_STATUS_BU==='Deleted_in_AE'){
          handlePopup(404)
          console.log(data.AE_MATERIAL_STATUS_BU)
          return;
        }
        handlePopup(200)
        setloading(false)
        data.BASE_UOM = t('PRODUCT_UOM.MSEHT.' + data.BASE_UOM, { ns: 'uom' })
        data.material_uom = data.material_uom.map(d => ({
          ...d,
          ALTERNATIVE_UOM_FOR_STOCKKEEPING_UNIT_TEXT: t('PRODUCT_UOM.MSEHT.' + d.ALTERNATIVE_UOM_FOR_STOCKKEEPING_UNIT, { ns: 'uom' }),
          BASE_UOM: data.BASE_UOM
        }))
        data.material_ean = data.material_ean.map(d => ({
          ...d,
          UOM_FOR_DISPLAY: t('PRODUCT_UOM.MSEHT.' + d.UOM_FOR_DISPLAY, { ns: 'uom' })
        }))
        data.material_sales_data = data.material_sales_data.map(d => ({
          ...d,
          SALES_UNIT: t('PRODUCT_UOM.MSEHT.' + d.SALES_UNIT, { ns: 'uom' }),
        }))
        data.material_chars = data.material_chars.map(d => ({
          ...d,
          VALUE: ['BUSINESS_SEGMENT', 'MARKET_SEGMENT'].includes(d.KEY) 
            ? d.VALUE.padStart(2, '0') + ' - ' + t(d.KEY.padStart(2, '0') + '.' + d.VALUE.padStart(2, '0'), { ns: 'mars_dictionaries' }) 
            : ['BRAND_FLAG', 'BRAND_SUB_FLAG'].includes(d.KEY) 
              ? d.VALUE.padStart(3, '0') + ' - ' + t(d.KEY + '.' + d.VALUE.padStart(3, '0'), { ns: 'mars_dictionaries' }) 
              : d.VALUE,
        }))
        data.material_classification = data.material_classification.map(d => {
          
          const techDesc = t(d.ATTR_NAME + '.' + d.VALUE, { ns: 'mars_dictionaries' })
          const endpointDesc = d.DESCRIPTION.map(o => {
            return (o.lang === i18n.language.toUpperCase()) ? o.description : undefined
          }).filter(p => p !== undefined).join("")

          
          return {
            ...d,
            ATTR_GROUP: t("ATTR_GROUP." + d.ATTR_GROUP, { ns: 'material_dictionaries' }),
            ATTR_NAME: t("ATTR_NAME." + d.ATTR_NAME, { ns: 'material_dictionaries' }),
            DESCRIPTION: d.DESCRIPTION.length ? endpointDesc : techDesc,
          }
        })

        setData(data)

        const materialEN = data.material_short_texts.find(elem =>
          elem.LANGUAGE_CODE === i18n.language.toLocaleUpperCase()
        )
        const materialFisrt = data.material_short_texts.shift()
        const materialDesc = materialEN ? materialEN.MATERIAL_DESCRIPTION : materialFisrt.MATERIAL_DESCRIPTION

        setDescType(`${materialDesc} (${data.MATERIAL_TYPE})`)
        setDesc(`${materialDesc}`)

        return data
      }     
      setloading(false)
    }).catch((er)=>{
      handlePopup(401)
    })   

  }, [])

  
  const title = descType

  const dataConvert = [
    {
      title: t('MaterialsDetail.BASIC_DATA'), 
      children: [
        {
          label:  t('MaterialsDetail.MATERIAL_NR'),
          key: 'MATERIAL_NR',
          value: data.MATERIAL_NR,
          tooltip: 'Message MATERIAL_NR',
          editable: false,
          splitWord: false,
        },
        {
          label: t('MaterialsDetail.DESCRIPTION'),
          key: 'desc',
          value: desc,
          tooltip: 'Message MATERIAL_NR',
          editable: false,
          splitWord: false,
        },
        {
          label: t('MaterialsDetail.MATERIAL_TYPE'),
          key: 'MATERIAL_TYPE',
          value: data.MATERIAL_TYPE,
          tooltip: 'Message MATERIAL_TYPE',
          editable: false,
          splitWord: false,
        },
        {
          label: t('MaterialsDetail.BASE_UOM'),
          key: 'BASE_UOM',
          value: data.BASE_UOM,
          tooltip: 'Message BASE_UOM',
          editable: false,
          splitWord: false,
        },
      ]
    },
    {
      title: t('MaterialsDetail.PARAMETERS'),
      children: [
        {
          label: t('MaterialsDetail.AE_MATERIAL_STATUS'),
          key: 'AE_MATERIAL_STATUS',
          value: data.AE_MATERIAL_STATUS_BU,
          tooltip: 'Message AE_MATERIAL_STATUS_BU',
          editable: false,
          splitWord: false,
          status_id: data.MATERIAL_NR,
          endpoint: backend_url.mat_status,
          name_component: 'Product'
        },
        {
          label: t('MaterialsDetail.LAST_CHANGED_ON'),
          key: 'LAST_CHANGED_ON',
          value: data.LAST_CHANGED_ON && dateTimeFormatter(data.LAST_CHANGED_ON, prefsData.date_format, prefsData.time_format, prefsData.time_zone, true),
          tooltip: 'Message LAST_CHANGED_ON',
          editable: false,
          splitWord: false,
          spacer: true,
        },
        {
          label: t('MaterialsDetail.LAST_CHANGED_BY'),
          key: 'LAST_CHANGED_BY',
          value: data.LAST_CHANGED_BY,
          tooltip: 'Message LAST_CHANGED_BY',
          editable: false,
          splitWord: false,
        },
      ]
    },
  ]

  const dataColumns = {
    material_chars: [
      { 
        field: 'ZSEGMENT', 
        headerName: t('MaterialsDetail.CHARS.ZSEGMENT'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'KEY', 
        headerName: t('MaterialsDetail.CHARS.KEY'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'VALUE', 
        headerName: t('MaterialsDetail.CHARS.VALUE'), 
        flex: 1, 
        minWidth: 230 
      },
    ],
    material_classification: [
      { 
        field: 'ATTR_GROUP', 
        headerName: t('MaterialsDetail.CLASS.ATTR_GROUP'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'ATTR_NAME', 
        headerName: t('MaterialsDetail.CLASS.ATTR_NAME'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'VALUE', 
        headerName: t('MaterialsDetail.CLASS.VALUE'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'DESCRIPTION', 
        headerName: t('MaterialsDetail.CLASS.DESCRIPTION'), 
        flex: 1, 
        minWidth: 230 
      },
    ],
    material_ean: [
      { 
        field: 'UOM_FOR_DISPLAY', 
        headerName: t('MaterialsDetail.EAN.UOM_FOR_DISPLAY'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'EAN_UPC', 
        headerName: t('MaterialsDetail.EAN.EAN_UPC'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'CATEGORY_OF_EAN', 
        headerName: t('MaterialsDetail.EAN.CATEGORY_OF_EAN'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'INDICATOR_MAIN_EAN', 
        headerName: t('MaterialsDetail.EAN.INDICATOR_MAIN_EAN'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'CONSECUTIVE_NUMBER', 
        headerName: t('MaterialsDetail.EAN.CONSECUTIVE_NUMBER'), 
        flex: 1, 
        minWidth: 230 
      },
    ],
    material_sales_data: [
      { 
        field: 'BUSINESS_AREA', 
        headerName: t('MaterialsDetail.SD.BUSINESS_AREA'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'DISTRIBUTION_CHAIN_DELETION_FLAG', 
        headerName: t('MaterialsDetail.SD.DISTRIBUTION_CHAIN_DELETION_FLAG'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'DIST_CHAIN_MATERIAL_STATUS', 
        headerName: t('MaterialsDetail.SD.DIST_CHAIN_MATERIAL_STATUS'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'DATE_FROM_DIST_CHAIN_MATERIAL_STATUS', 
        headerName: t('MaterialsDetail.SD.DATE_FROM_DIST_CHAIN_MATERIAL_STATUS'), 
        flex: 1, 
        minWidth: 230,
        renderCell: ({ value }) => dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)
      },
      { 
        field: 'SALES_UNIT', 
        headerName: t('MaterialsDetail.SD.SALES_UNIT'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'DELIVERING_PLANT', 
        headerName: t('MaterialsDetail.SD.DELIVERING_PLANT'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'PRODUCT_HIERARCHY', 
        headerName: t('MaterialsDetail.SD.PRODUCT_HIERARCHY'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'PRICING_REFERENCE_MATERIAL', 
        headerName: t('MaterialsDetail.SD.PRICING_REFERENCE_MATERIAL'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'MATERIAL_PRICING_GROUP', 
        headerName: t('MaterialsDetail.SD.MATERIAL_PRICING_GROUP'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'ACCOUNT_ASSIGNMENT_GROUP', 
        headerName: t('MaterialsDetail.SD.ACCOUNT_ASSIGNMENT_GROUP'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'MATERIAL_GROUP1', 
        headerName: t('MaterialsDetail.SD.MATERIAL_GROUP1'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'MATERIAL_GROUP2', 
        headerName: t('MaterialsDetail.SD.MATERIAL_GROUP2'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'MATERIAL_GROUP3', 
        headerName: t('MaterialsDetail.SD.MATERIAL_GROUP3'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'MATERIAL_GROUP4', 
        headerName: t('MaterialsDetail.SD.MATERIAL_GROUP4'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'MATERIAL_GROUP5', 
        headerName: t('MaterialsDetail.SD.MATERIAL_GROUP5'), 
        flex: 1, 
        minWidth: 230 
      },
    ],
    material_short_texts: [
      { 
        field: 'LANGUAGE_CODE', 
        headerName: t('MaterialsDetail.ST.LANGUAGE_CODE'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'MATERIAL_DESCRIPTION', 
        headerName: t('MaterialsDetail.ST.MATERIAL_DESCRIPTION'), 
        flex: 1, 
        minWidth: 230 
      },
    ],
    material_tax_classification: [
      { 
        field: 'DEPARTURE_COUNTRY', 
        headerName: t('MaterialsDetail.TC.DEPARTURE_COUNTRY'), 
        flex: 1, 
        minWidth: 150 
      },
      { 
        field: 'TAX_CLASSIFICATION_MATERIAL1', 
        headerName: t('MaterialsDetail.TC.TAX_CLASSIFICATION_MATERIAL1'), 
        flex: 1, 
        minWidth: 180 
      },
      { 
        field: 'TAX_CLASSIFICATION_MATERIAL2', 
        headerName: t('MaterialsDetail.TC.TAX_CLASSIFICATION_MATERIAL2'), 
        flex: 1, 
        minWidth: 180 
      },
      { 
        field: 'TAX_CLASSIFICATION_MATERIAL3', 
        headerName: t('MaterialsDetail.TC.TAX_CLASSIFICATION_MATERIAL3'), 
        flex: 1, 
        minWidth: 180 
      },    
      { 
        field: 'TAX_CLASSIFICATION_MATERIAL4', 
        headerName: t('MaterialsDetail.TC.TAX_CLASSIFICATION_MATERIAL4'), 
        flex: 1, 
        minWidth: 180 
      },
      { 
        field: 'TAX_CLASSIFICATION_MATERIAL5', 
        headerName: t('MaterialsDetail.TC.TAX_CLASSIFICATION_MATERIAL5'), 
        flex: 1, 
        minWidth: 180 
      },
      { 
        field: 'TAX_CLASSIFICATION_MATERIAL6', 
        headerName: t('MaterialsDetail.TC.TAX_CLASSIFICATION_MATERIAL6'), 
        flex: 1, 
        minWidth: 180 
      },
      { 
        field: 'TAX_CLASSIFICATION_MATERIAL7', 
        headerName: t('MaterialsDetail.TC.TAX_CLASSIFICATION_MATERIAL7'), 
        flex: 1, 
        minWidth: 180 
      },
      { 
        field: 'TAX_CLASSIFICATION_MATERIAL8', 
        headerName: t('MaterialsDetail.TC.TAX_CLASSIFICATION_MATERIAL8'), 
        flex: 1, 
        minWidth: 180 
      },
      { 
        field: 'TAX_CLASSIFICATION_MATERIAL9', 
        headerName: t('MaterialsDetail.TC.TAX_CLASSIFICATION_MATERIAL9'), 
        flex: 1, 
        minWidth: 180 
      },
    ],
    material_uom: [
      { 
        field: 'ALTERNATIVE_UOM_FOR_STOCKKEEPING_UNIT', 
        headerName: t('MaterialsDetail.UOM.ALTERNATIVE_UOM_FOR_STOCKKEEPING_UNIT'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'NUMERATOR_FOR_CONVERSION_TO_BASE_UOM', 
        headerName: t('MaterialsDetail.UOM.NUMERATOR_FOR_CONVERSION_TO_BASE_UOM'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'DENOMINATOR_FOR_CONVERSION_TO_BASE_UOM', 
        headerName: t('MaterialsDetail.UOM.DENOMINATOR_FOR_CONVERSION_TO_BASE_UOM'), 
        flex: 1, 
        minWidth: 230 
      },
      { 
        field: 'BASE_UOM', headerName: t('MaterialsDetail.UOM.BASE_UOM'), 
        flex: 1, 
        minWidth: 230 
      },
    ]
  }
  
  const accordion = [
    { 
      id: "1", 
      label: t('MaterialsDetail.Tabs.main'), 
      dataKey: 'main',
      fetching: false, 
    },
    { 
      id: "3", 
      label: t('MaterialsDetail.Tabs.materialClassification'), 
      dataKey: 'material_classification', 
      collapse: 'material_classification',
      fetching: false, 
    },
    { 
      id: "4", 
      label: t('MaterialsDetail.Tabs.materialEan'), 
      dataKey: 'material_ean',
      fetching: false,
    },
    { 
      id: "5", 
      label: t('MaterialsDetail.Tabs.materialSales'), 
      dataKey: 'material_sales_data',
      fetching: false, 
    },
    { 
      id: "6", 
      label: t('MaterialsDetail.Tabs.materialShort'), 
      dataKey: 'material_short_texts',
      fetching: false, 
    },
    { 
      id: "7", 
      label: t('MaterialsDetail.Tabs.materialTax'), 
      dataKey: 'material_tax_classification',
      fetching: false, 
    },
    { 
      id: "8", 
      label: t('MaterialsDetail.Tabs.materialUom'), 
      dataKey: 'material_uom',
      fetching: false,
    },
  ]

  const dataChangeLogs = {  
    title: t("CHANGE_LOGS.TitleMat"),
    value: 'MATERIAL_NR',
    url: backend_url.mat_status,
    endpoint: [{ field: 'MATERIAL_NR', name: 'MATERIAL_NR' }],
    endpointName: '/change_log',
    checkToHide: ['AE_USER_ID', 'EXT_USER_ID', 'DOC_NUMBER'],
    isTreeChar: false,
    skip: 100,
    limit: 100,
    prevHide: {
      "EXT_USER_ID": false,
      "DOC_NUMBER": false
    },
    columns: [
      {
        field: 'TIMESTAMP',
        headerName: t("CHANGE_LOGS.ChangeTime"),
        flex: 1,
        minWidth: 150,
        renderCell: ({ value }) => {
          if (value) {
            return dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)
          }
          return value
        },
      },
      { field: 'AREA', headerName: t("CHANGE_LOGS.Area"), flex: 1 },
      { field: 'FIELD_OF_CHANGE', headerName: t("CHANGE_LOGS.Attribute"), flex: 1 },
      {
        field: 'BUSINESS_AREA_PRIMARY_KEY',
        headerName: t("CHANGE_LOGS.PrimaryKey"),
        minWidth: 220,
        renderCell: ({ value }) => {

          const lines = Object.entries(value).map(it => `${it[0]}: ${it[1]}`);
          return (
            <div style={{ whiteSpace: 'pre-line' }}>
              {lines.map((line, index) => (
                <div key={index}>{line}</div>
              ))}
            </div>
          );
        },
      },
      { field: 'FROM', headerName: t("CHANGE_LOGS.OldValue"), flex: 1 },
      { field: 'TO', headerName: t("CHANGE_LOGS.NewValue"), flex: 1 },
      { field: 'AE_USER_ID', headerName: t("CHANGE_LOGS.AeUserId"), flex: 1 },
      {
        field: 'EXT_USER_ID',
        headerName: t("CHANGE_LOGS.ExtUserId"),
        flex: 1,
      },
      { field: 'DOC_NUMBER', headerName: t("CHANGE_LOGS.MessageId"), flex: 1 },
    ]
  }

  const breadCrumb = [
    { label: t('Breadcrumb.home', { ns: 'translation'}), link: '/' },
    { label: t('Breadcrumb.masterData'), link: '' },
    { label: t('Breadcrumb.products'), link: '/master-data/products' }, 
    { label: params.id, link: '' }
  ]

  const handleClickChangeLog = ()=>{
    setOpen(true)
  }

  const onClose = ()=>{
    setOpen(false)
  }

  return (
  <>
    {
      loading?
        <LoadingWithText text="Your request is being processed, please wait." size={50} />:
        <FlexibleTabs 
          data={data}
          title={title}
          dataConvert={dataConvert}
          dataColumns={dataColumns}
          accordion={accordion}
          breadCrumb={breadCrumb}
          hiddenBreadCrumb={materialPopup}
          handleClickChangeLog={handleClickChangeLog}
        />
    }
      <DialogChangeLogs open={open} onCloseHandle={onClose} data={data} dataLogs={dataChangeLogs}/>

  </>   
  )
}
