import React, { useEffect, useState } from 'react'
import { LoggedIn, SwitchEdit } from '../index'
// import { GridToolbarColumnsButton } from '@mui/x-data-grid-pro'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import CustomExport from '../../mui-components/CustomExport'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Box } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import RestoreIcon from '@mui/icons-material/Restore'
import { useNavigate } from 'react-router'
import { useSearchParams } from "react-router-dom"
import { useParams } from 'react-router-dom'
import IconCloseAO from '../../assets/images/closeAO_button.png'
import FindProductIcon from '../../assets/images/find_product.png'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay'
import { UserGenInfo } from '../../pages/AuthAuditData/UserGenInfo'
import { usePermissions } from '../../hooks'
import CalculateIcon from '@mui/icons-material/Calculate'
import { useTranslation } from 'react-i18next'
import { dateTimeFormatter } from '../../utils/utils'
import { useUserPrefs } from '../../pages'
import UpdateIcon from '@mui/icons-material/Update'
import SvgIcon from '@mui/material/SvgIcon';
import ExcelExport from '../../mui-components/CustomExport/exports/ExcelExport'
import FontSize from './FontSize'

function CustomToolbar(props) {

  const { 
    bread, 
    title, 
    newAdjustment = false,
    displayColumn = false, 
    displayEditField = false,
    displayColumnForTrade=false,
    openAdjustment = null,
    isSelectBU, 
    filters,
    exportSetting,
    dataCloseAO,
    handleClickCloseAO,
    handleClickDialog,
    isAccrualPosting,
    isRefreshBtn,
    handleRecalculate,
    isLockedRecalculate,
    isSwitcher=false,
    onSwitcher,
    switcher,
    loading,
    handleClickChangeLog,
    isFindButton,
    onFindProductHandle,
    hiddenBreadCrumb=false,
    tradeData,
    showUserGen = false,
    filterValue = null,
    pdfExport = false,
    additionalTable,
    handleClickRefresh,
    displayColumnReconciliation = false,
    showExport = true,
    isFontSize=false,
    fontSize=null,
    onChangeFontSize=()=>{},
    apiRef
  } = props

  const { childObj } = usePermissions();
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [anchorEl, setAnchorEl] = useState(null)
  const openMenu = Boolean(anchorEl)
  const {prefsData} = useUserPrefs()
  //const isReturn = searchParams.get('isReturn')
  const params = useParams();
  const passID = params.id

  const checkRefreshPermission = childObj.hasOwnProperty("RunPlan_Regeneration")
  const checkRecalculateAOPermission = childObj.hasOwnProperty("Recalculate_AO")

  const { t } = useTranslation('translation')

  function resetPage () {
    localStorage.removeItem("filters")
    return window.location.reload()
  }

  function handleClick (event, id) {
    navigate(`/accrual-balance/accrual_posting?AO_ID=${id}&filters=false`)
  }

  const handleClickMenu = (event) => {
    event.preventDefault()
    setAnchorEl(event.currentTarget)
    
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  

  function displayColumnAndExport () {
    

    if(displayColumnForTrade){
      return (
        <div className='toolbarSection toolbarIconsBlock tradeToolbarIconsBlock'>
          {tradeData.map((it, ind)=>{
            const {headerName, onClick, icon, disabled,anotherButton, style}  = it;

            return(
              <div className='toolbarIconSection' key={ind}>
                {anotherButton ?
                  anotherButton:
                  <button onClick={onClick} disabled={disabled}>
                  <span className="MuiButton-startIcon" style={style}>
                    {icon}
                  </span>
                    {headerName} 
                  <span className="MuiTouchRipple-root"></span>
                </button>
                }
              
          </div>
            )
          })} 
          <div className='toolbarIconSection toolbarMoreHorizIcon'>
            <MoreHorizIcon sx={{ fontSize: "1.3rem" }} onClick={handleClickMenu}/>
          </div>
         
          <Menu
              id="basic-menu"
              className='block-menu-toolbar'
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleCloseMenu}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              disableAutoFocusItem={true}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
               <MenuItem onClick={resetPage} className='tradeToolbarMenuItem'>
                <div className='section-menu-toolbar'>
                  <span className='icon-menu-toolbar reset'>
                    <RestartAltIcon/>
                  </span>
                  <span className='text-menu-toolbar'>{t("Buttons.RESET_FILTER")}</span>
                </div>
              </MenuItem>
            <MenuItem onClick={()=>{}} className='tradeToolbarMenuItem'>
              <div  id="columnAnchor" className='section-menu-toolbar'>
              {/* <GridToolbarColumnsButton sx={{ fontSize: "0.875rem" }} /> */}
              </div>
            </MenuItem>
            <MenuItem className='tradeToolbarMenuItem' disabled={exportSetting.disabled}>
              <ExcelExport  setting={exportSetting} pdfExport={pdfExport} fileName={`${exportSetting.fileName}_${dateTimeFormatter(new Date().toISOString(), prefsData.date_format, prefsData.time_format, prefsData.time_zone)}`} />  
            </MenuItem>
      </Menu>
        </div>
      )
    }

    if (displayColumn) {
      return (
        <div className='toolbarSection toolbarIconsBlock'>
          {newAdjustment &&
          <div className='toolbarIconSection toolbarGridColumnsButton'>
              <button onClick={openAdjustment}>
                <span className="MuiButton-startIcon">
                  <AddCircleIcon sx={{ fontSize: "1rem" }} />  
                </span>
                {t("Buttons.New_Adjustment")}
                <span className="MuiTouchRipple-root"></span>
              </button>
          </div>}

          
          { // Download Manager
            isSwitcher && (
              <div className='toolbarIconSection toolbarSwitch'>
                <SwitchEdit 
                  onSwitcher={onSwitcher} 
                  switcher={switcher}
                  loading={loading} 
                  text={t("Buttons.Show_All")}
                />
              </div>
            )
          }
          {
            isFindButton && (
              <div className='toolbarIconSection'>
                <button onClick={onFindProductHandle}>
                  <span className="MuiButton-startIcon MuiButton-startIcon-find-product">
                    <img src={FindProductIcon} alt='icon find product' height={14} style={{paddingTop: 2}}/>  
                  </span>               
                  <span className="MuiTouchRipple-root">{t("Buttons.Find_Product")}</span>
                </button>
              </div>
            )
          }

          {
            displayColumnReconciliation && (
              <div className='toolbarIconSection toolbarGridColumnsButton'>
                <button onClick={handleClickRefresh}>
                  <span className="MuiButton-startIcon">  
                    <UpdateIcon sx={{ fontSize: "1rem", color: 'rgb(246,109,56) !important' }} />
                  </span>
                    Refresh
                  <span className="MuiTouchRipple-root"></span>
                </button>
              </div>
            )
          }
          
          <div id="columnAnchor" className='toolbarIconSection toolbarGridColumnsButton'>
            <button onClick={resetPage}>
              <span className="MuiButton-startIcon">
                <RestartAltIcon sx={{ fontSize: "1rem" }} />  
              </span>
                {t("Buttons.RESET_FILTER")}
              <span className="MuiTouchRipple-root"></span>
            </button>
          </div>
          <div className='toolbarIconSection toolbarGridColumnsButton'>
            {/* <GridToolbarColumnsButton sx={{ fontSize: "1.3rem" }} /> */}
            <button onClick={() => apiRef.current.showPreferences('columns')} className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall" tabIndex="0" type="button" aria-label="Select columns">
              <span className="MuiButton-startIcon MuiButton-iconSizeSmall">
                <svg xmlns="http://www.w3.org/2000/svg" style={{ width: "1em", height: "1em", display: "inline-block", fill: "currentColor", fontSize: "1rem"}} className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ColumnIconIcon">
                  <path d="M6 5H3c-.55 0-1 .45-1 1v12c0 .55.45 1 1 1h3c.55 0 1-.45 1-1V6c0-.55-.45-1-1-1zm14 0h-3c-.55 0-1 .45-1 1v12c0 .55.45 1 1 1h3c.55 0 1-.45 1-1V6c0-.55-.45-1-1-1zm-7 0h-3c-.55 0-1 .45-1 1v12c0 .55.45 1 1 1h3c.55 0 1-.45 1-1V6c0-.55-.45-1-1-1z">
                  </path>
                </svg>
              </span>
              Columns
              <span className="MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root"></span>
            </button>
          </div>
          {
            displayColumnReconciliation == false && (
              <>
                {showExport && <div className='toolbarIconSection toolbarGridExport'>
                  <CustomExport setting={exportSetting} pdfExport={pdfExport} fileName={`${exportSetting.fileName}_${dateTimeFormatter(new Date().toISOString(), prefsData.date_format, prefsData.time_format, prefsData.time_zone)}`}/>
                </div>}
                <div className='toolbarIconSection toolbarMoreHorizIcon'>
                  <MoreHorizIcon sx={{ fontSize: "1.3rem" }} />
                </div>
              </>
            )
          }
                  </div>
      )
    } else if (displayEditField /*&& Boolean(isReturn)*/) {

      return (
        <div className='toolbarSection toolbarIconsBlock'>
          
          {
            isRefreshBtn && checkRefreshPermission && (
              <div className='toolbarIconSection toolbarGridColumnsButton'>
                <button onClick={handleClickDialog}>
                  <span className="MuiButton-startIcon">
                    <PlaylistPlayIcon sx={{ fontSize: "17px" }} />  
                  </span>
                  {t("Buttons.Refresh_Run_Plan")}
                  <span className="MuiTouchRipple-root"></span>
                </button>
              </div>
            )
          }
          
          {
            handleRecalculate && checkRecalculateAOPermission && (
              <div className='toolbarIconSection toolbarGridColumnsButton'>
                <button onClick={handleRecalculate} disabled={isLockedRecalculate==null||isLockedRecalculate}>
                  <span className="MuiButton-startIcon">
                    <CalculateIcon sx={{ fontSize: "17px" }} />  
                  </span>
                    {t("Buttons.Recalculate_AO")}
                  <span className="MuiTouchRipple-root"></span>
                </button>
              </div>
            )
          }
          

          {
            handleClickChangeLog &&
            <div id="columnAnchor" className='toolbarIconSection toolbarGridColumnsButton'>
              <button onClick={handleClickChangeLog}>
                <span className="MuiButton-startIcon">
                  <RestoreIcon sx={{ fontSize: "17px" }} />  
                </span>
                  {t("Menu.changeLogs")}
                <span className="MuiTouchRipple-root"></span>
              </button>
            </div>
          }
          {
            isAccrualPosting&&
            <div id="columnAnchor" className='toolbarIconSection toolbarGridColumnsButton'>
              <button onClick={(e) => handleClick(e, passID)}>
                <span className="MuiButton-startIcon">
                  <ArrowForwardIcon sx={{ fontSize: "18px" }} />  
                </span>
                {t("Menu.accrualPostingList")}
                <span className="MuiTouchRipple-root"></span>
              </button>
            </div>
          }
          
          {
            dataCloseAO && dataCloseAO.isVisible && !dataCloseAO.isDisable ? 
              <div className='toolbarIconSection'>
                <button onClick={handleClickCloseAO}>
                  <span className="MuiButton-startIcon MuiButton-startIcon-closeAO">
                    <img src={IconCloseAO} alt='close AO'/>  
                  </span>               
                  <span className="MuiTouchRipple-root">{t("Buttons.CLOSE_AO")}</span>
                </button>
              </div> :
            null
        }
        </div>
        
      )
      
    } else {
      return null
    }

  }

  
  return (
    <>
    <Box
      sx={{
        display: 'none',
        displayPrint: 'block',
        m: 1,
        fontSize: '0.875rem',
        fontWeight: '700',
      }}
        >
        Date created: {dateTimeFormatter(new Date().toISOString(), prefsData.date_format, prefsData.time_format, prefsData.time_zone)}
    </Box>
    <div sx={{displayPrint: 'none'}}className='toolbarContainerBlock MuiDataGrid-toolbar'>
      
      {(isSelectBU) && (
        <div className='toolbarSelectBU'>
          {isSelectBU}
        </div> 
      )}
      
      {
        !hiddenBreadCrumb && 
          <div className='toolbarborderBlock'>
            <div className='toolbarBlock'>
              <LoggedIn breadcrumbs={bread} /> 
              { displayColumnAndExport() }
            </div>
          </div>
      }

      {
        isFontSize?
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <h2 className='toolbarTitle'>{title}</h2> 
          <FontSize size={fontSize} onChange={onChangeFontSize}/> 
        </Box>:
        <h2 className='toolbarTitle'>{title}</h2>  
      }     
      {showUserGen && <UserGenInfo filterValue={filterValue}/>}

      { filters && (<Box sx={{ mb: 2 }}>{filters}</Box>) }

      { additionalTable && additionalTable }
      
    </div>
    </>
  )
}

export default CustomToolbar
