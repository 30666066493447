import React, { useState, useEffect, useCallback } from 'react'
import { DataGridPro, GridOverlay, useGridApiRef, getGridStringOperators } from '@mui/x-data-grid-pro'
import { LinearProgress } from "@mui/material"
import { backend_url } from '../../settings'
import { Link } from 'react-router-dom'
import { getParam, checkObjectKey, checkInFilterCache, dateTimeFormatter,checkSize } from '../../utils/utils'
import { useTranslation } from 'react-i18next'
import { CustomToolbar, CustomColumnMenu, CustomActionList, DialogChangeLogs, DialogStatus } from '../../components'
import { useFetch, usePermissions } from '../../hooks'
import CustomPanel from '../../mui-components/CustomPanel'

import { useUserPrefs } from "../UserPreferences/ProvideUserPrefs"

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}



export default function BPList() {

  const apiRef = useGridApiRef()

  const { t } = useTranslation('translation')
  const tokenBpxRequest = localStorage.getItem('token_bpx')
  const value_bu = localStorage.getItem('bu_bpx')
  
  const request = useFetch()
  const { obj, childObj } = usePermissions()
  const checkBP = checkObjectKey(obj, childObj, 'Business_Partners_Details')


  const [rows, setRows] = useState([])
  const [page, setPage] = useState(0)
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState({status: false, logs: false})
  const [ data, setData ] = useState({});
  const [exportQuery, setExportQuery] = useState("")

  const { prefsData, saveUserPrefs, updateUserPrefsOnClose, saveUserPrefsWithoutRefresh, silentUpdateUserPrefs } = useUserPrefs();
  const [orderedColumns, setOrderedColumns] = useState(prefsData.reports_column_order && prefsData.reports_column_order.Business_Partners || []);
  const [fontSize, setFontSize] = useState(prefsData.reports_column_size&&prefsData.reports_column_size.Business_Partners || 'm');
  const columnVisibility = {};
  if(prefsData.reports_column_visibilty&&prefsData.reports_column_visibilty.Business_Partners){
    prefsData.reports_column_visibilty.Business_Partners.forEach(it=>columnVisibility[it]=false);
  }


  const saveColumnVisibility = (data)=>{
    saveUserPrefs({
      ...prefsData,
      reports_column_visibilty:{
        ...prefsData.reports_column_visibilty,
        Business_Partners: Object.entries(data).filter(it=>!it[1]).map(it=>it[0])
      }
    })
  }

  const cachedfilters = localStorage.getItem("filters")
  var filterObj = {
      bpList: []
  }
  const filterOperators = getGridStringOperators().filter(({ value }) =>
    ['contains'].includes(value),
  );
  
  if (cachedfilters && Object.keys(cachedfilters).length) { 
      filterObj = JSON.parse(cachedfilters)
      if (!('bpList' in filterObj)) {
          filterObj["bpList"] = []
      }
  }
  const [queryOptions, setQueryOptions] = useState(filterObj["bpList"])

  const applyFilter = useCallback((item) => {
    if (item.value) {
      setQueryOptions((prev) => {
        const uniqueKey = prev.filter(cc => cc.id !== item.id)
        return [...uniqueKey, item]
      })
      
      checkInFilterCache(item, filterObj.bpList)
      localStorage.setItem("filters", JSON.stringify(filterObj))

      setRows([])
      setPage(0)
    }

    apiRef.current.upsertFilterItem(item)
  }, [apiRef, queryOptions])


  const deleteFilter = useCallback((item) => {
    let deleteItem = queryOptions.filter(cc => cc.id !== item.id)
    setQueryOptions(deleteItem)

    filterObj.bpList = filterObj.bpList.filter(fl => fl.id !== item.id)
    localStorage.setItem("filters", JSON.stringify(filterObj))

    setRows([])
    setPage(0)
  
    const shouldCloseFilterPanel = queryOptions.length === 0
    apiRef.current.deleteFilterItem(item)

    if (shouldCloseFilterPanel) {
      apiRef.current.hideFilterPanel()
    }
  }, [apiRef, queryOptions])

  // request.fetchData(
  //   `http://localhost:8001/backend/bps/CU/10180493/change_log?skip=0&limit=100&business_unit=PL-218-05`,
  //   'GET',
  //   null,
  //   false,
  //   tokenBpxRequest,
  //   false
  // )
  // .then(data => {
  //  console.log(data)
  // })

  const addLoadedRows = useCallback((items) => {
    const changeIdRow = items.map( d => ({ id: d.BP_ROLE + d.BP_NR, ...d }) )
    setRows((old) => {
      return [...old, ...changeIdRow]
    })
  }, [])

  useEffect(() => {

    setLoading(true)  

    const queryParams = {
      business_unit: value_bu,
      limit: 100,
      skip: page,
      ...queryOptions.map(cc => ({ [cc.columnField]: cc.value }))
    }

    const searchParam = getParam(queryParams).toString()
      
    setExportQuery(searchParam)
    
    request.fetchData(
      `${backend_url.bp_list}?${searchParam}`,
      'GET',
      null,
      false,
      tokenBpxRequest,
      false
    )
    .then(data => {
      setLoading(false)
      addLoadedRows(data)
      setCount(data.length)
    })

  }, [queryOptions, page])

  useEffect(() => {
      return apiRef.current.subscribeEvent('columnHeaderDragEnd', (params) => {
          silentUpdateUserPrefs()
      })
  }, [apiRef])
  
  const handleOnRowsScrollEnd = (params) => {
    if (params.viewportPageSize&&!loading) {
      if (count >= 100) {
        return setPage(page + 100)
      } else {
        return null
      }
    }
  }

  const columns = React.useMemo(() => {
    const allColumns = {
      id: { 
          field: 'id', 
          headerName: 'id', 
          filterable: false, 
          hide: true
      },
      BP_NR: { 
          field: 'BP_NR', 
          headerName: t("BPList.BN_NR"), 
          type: "number",
          align: 'left',
          headerAlign: 'left',
          flex: 0.08,
          minWidth: 50,
          filterOperators,
          renderCell: (params) => {
            if (checkBP) {
              return (
                <div className="MuiDataGrid-cellContent">
                  <Link 
                    style={{ color: '#f69e80' }} 
                    to={`/master-data/business_partners/${params.row.BP_ROLE}/${params.value}/detail`}
                    className="dark-mode-link-color"
                  >
                    {params.value}
                  </Link>
                </div>
              )
            }
          },
          renderHeader: ({colDef})=>{
            if(colDef.width < 100){
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Business Partner" 
                data-mui-internal-clone-element="true">
                    {t("BPList.BN_NR_SHORT")}
                </div>
              )
            }
            return (
              <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Business Partner" 
                data-mui-internal-clone-element="true">
                  {t("BPList.BN_NR")}
              </div>
            )
          },
      },
      BP_ROLE: { 
          field: 'BP_ROLE', 
          headerName: t("BPList.BP_ROLE"),
          filterOperators,
          flex: 0.05,
          minWidth: 50,
      },
      ACCOUNT_GROUP: { 
          field: 'ACCOUNT_GROUP', 
          headerName: t("BPList.ACCOUNT_GROUP"), 
          flex: 1, 
          filterOperators,
          flex: 0.08,
          minWidth: 50,
          renderHeader: ({colDef})=>{
            if(colDef.width < 100){
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Account Group" 
                data-mui-internal-clone-element="true">
                    {t("BPList.ACCOUNT_GROUP_SHORT")}
                </div>
              )
            }
            return (
              <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Account Group" 
                data-mui-internal-clone-element="true">
                  {t("BPList.ACCOUNT_GROUP")}
              </div>
            )
          },
      },
      NAME1: { 
          field: 'NAME1', 
          headerName: t("BPList.NAME1"),
          flex: 0.15,
          minWidth: 50,
          filterOperators 
      },
      NAME2: { 
          field: 'NAME2', 
          headerName: t("BPList.NAME2"),
          flex: 0.08,
          minWidth: 50,
          filterOperators 
      },
      STREET_HOUSE_NUM: { 
          field: 'STREET_HOUSE_NUM', 
          headerName: t("BPList.STREET_HOUSE_NUM"),
          flex: 0.12,
          minWidth: 50,
          filterOperators 
      },
      CITY: { 
          field: 'CITY', 
          headerName: t("BPList.CITY"),
          flex: 0.09,
          minWidth: 50,
          filterOperators
      },
      POSTAL_CODE: { 
          field: 'POSTAL_CODE', 
          headerName: t("BPList.POSTAL_CODE"),
          flex: 0.08,
          minWidth: 50,
          filterOperators
      },
      TAX_NUM_1: { 
          field: 'TAX_NUM_1', 
          headerName: t("BPList.TAX_NUM_1"),
          flex: 0.08,
          minWidth: 50,
          filterOperators 
      },
      COUNTRY: { 
          field: 'COUNTRY', 
          headerName: t("BPList.COUNTRY"),
          flex: 0.08,
          minWidth: 50,
          filterOperators 
      },
      BP_STATUS: { 
          field: 'BP_STATUS', 
          headerName: t("BPList.BP_STATUS"),
          flex: 0.08,
          minWidth: 50,
          filterOperators,
          renderCell: ({value})=>{
            const statuses = ["Deleted", "Manual_Block", "Blocked_Posting", "Active"]
            const status = statuses.includes(value)? t(`BPList.STATUSES.${value}`): value;
            return ( <div className="MuiDataGrid-cellContent">
              {status}
            </div>)
          }
      },
      actions: {
          field: 'actions',
          type: 'actions',
          width: 65,
          minWidth: 50,
          headerName: t("BPList.ACTIONS"),
          headerAlign: 'left',
          getActions: (params) => {
            const data = {
              "label": "Status",
              "key": "BP_STATUS",
              "value": params.row.BP_STATUS,
              "tooltip": "Message Status",
              "editable": false,
              "splitWord": false,
              "status_id": params.row.BP_NR,
              "endpoint": backend_url.bp_list + '/' + params.row.BP_ROLE,
              "name_component": "Business Partner",
              "BP_NR": params.row.BP_NR,
              "BP_ROLE": params.row.BP_ROLE
          }
          return [ 
            <CustomActionList 
              id={params.id} 
              data={data} 
              handleBlock={()=>{
                setData(data);
                setOpen(prev=>({...prev, status: true}));
              }} 
              handleLog={()=>{
                setData(data)
                setOpen(prev=>({...prev, logs: true}))
              }}
            />
            ]
          }
      }
    }

    return orderedColumns.length?orderedColumns.reduce((acc, field) => {
      return [...acc, allColumns[field]];
    }, []):Object.values(allColumns);
  }, [orderedColumns]);

  const logs = {
    title:  t("CHANGE_LOGS.TitleBP"),
    value: 'BP_NR',
    url: backend_url.bp_list,
    endpoint: [{field: 'BP_ROLE', name:'BP_ROLE'}, {field: 'BP_NR', name:'BP_NR' }],
    endpointName: '/change_log',
    checkToHide: ['AE_USER_ID', 'EXT_USER_ID', 'DOC_NUMBER'],
    skip: 100, 
    limit: 100,
    prevHide: {
      "EXT_USER_ID": false,
      "DOC_NUMBER": false
    },
    columns: [
      { 
        field: 'TIMESTAMP', 
        headerName: t("CHANGE_LOGS.ChangeTime"),
        flex: 1,
        minWidth: 150,
        renderCell: ({ value }) =>{
          if(value){
            return  dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone, true, true, true)
          }
          return value
        },
      },
      { field: 'AREA', headerName: t("CHANGE_LOGS.Area"), flex: 1 },
      { field: 'FIELD_OF_CHANGE', headerName: t("CHANGE_LOGS.Attribute"), flex: 1 },
      { 
        field: 'BUSINESS_AREA_PRIMARY_KEY', 
        headerName: t("CHANGE_LOGS.PrimaryKey"), 
        minWidth: 220,
        renderCell: ({ value }) =>{

          const lines = Object.entries(value).map(it=> `${it[0]}: ${it[1]}`);
          return(
            <div style={{ whiteSpace: 'pre-line' }}>
              {lines.map((line, index) => (
                <div key={index}>{line}</div>
              ))}
            </div>
          );
        },
      },
      { field: 'FROM', headerName: t("CHANGE_LOGS.OldValue"), flex: 1 },
      { field: 'TO', headerName: t("CHANGE_LOGS.NewValue"), flex: 1 },
      { field: 'AE_USER_ID', headerName: t("CHANGE_LOGS.AeUserId"), flex: 1 },
      {
        field: 'EXT_USER_ID',
        headerName: t("CHANGE_LOGS.ExtUserId"),
        flex: 1,
       },
      { field: 'DOC_NUMBER', headerName: t("CHANGE_LOGS.MessageId"), flex: 1 },
    ]
  }

  const objBreadcrumb = [
    { label: t("Breadcrumb.home"), link: '/' },
    { label: t("Breadcrumb.masterData"), link: '' },
    { label: t("Breadcrumb.businessPartners"), link: '/master-data/business_partners' },
  ]

  const onClose = ()=>{
    setOpen({status: false, logs: false})
  }

  const handleColumnOrderChange = React.useCallback((params) => {
    setOrderedColumns((prevOrderedColumns) => {
      const newOrderedColumns = [...prevOrderedColumns];
      const oldIndex = params.oldIndex;
      const targetIndex = params.targetIndex;
      const oldColumn = prevOrderedColumns[oldIndex];
      newOrderedColumns.splice(oldIndex, 1);
      newOrderedColumns.splice(targetIndex, 0, oldColumn);
      saveUserPrefsWithoutRefresh({
        ...prefsData,
        reports_column_order: {
          ...prefsData.reports_column_order,
          Business_Partners: newOrderedColumns
        }
      })
      return newOrderedColumns;
    });
  }, []);

  const onChangeFontSize = (value)=>{
    setFontSize(value)
    saveUserPrefs({
      ...prefsData,
      reports_column_size:{
        ...prefsData.reports_column_size,
        Business_Partners: value
      }
    })
  }

  return (
      <div className="tableContainer">
        <DataGridPro
          rows={rows}
          apiRef={apiRef}
          sx={{ 
            '& .MuiDataGrid-row': {
              minHeight: `${checkSize(fontSize, 'row_height')}px !important`,
              height: 'auto !important'
            },
            '& .MuiDataGrid-cell': {
              whiteSpace: 'nowrap',
              overflow: 'hidden'
            },
            '& .MuiDataGrid-main':{
              fontSize: checkSize(fontSize, 'font_size')
            }  
          }}
          columns={columns}
          loading={loading}
          sortingOrder={['desc', 'asc']}
          filterMode="server"
          onColumnOrderChange={handleColumnOrderChange}
          onRowsScrollEnd={handleOnRowsScrollEnd}
          onColumnVisibilityModelChange={saveColumnVisibility}
          onPreferencePanelClose={()=> updateUserPrefsOnClose()}
          getRowHeight={() => 'auto'}
          headerHeight={checkSize(fontSize, 'header_height')}
          localeText={{
            toolbarColumns: t("Buttons.Columns"),
            toolbarExport: t("Buttons.Export"),
            noRowsLabel: t("Common.noRows")

          }}
          initialState={{
            columns: {
              columnVisibilityModel:columnVisibility
            },
            filter: {
                filterModel: {
                  items: filterObj && filterObj.bpList,
                },
            }
          }}
          components={{
            Toolbar: CustomToolbar,
            ColumnMenu: CustomColumnMenu,
            LoadingOverlay: CustomLoadingOverlay,
            Panel: CustomPanel
          }}
          componentsProps={{
            toolbar: {
              bread: objBreadcrumb,
              title: t("Title.businessPartnersList"),
              displayColumn: true,
              isFontSize: true,
              fontSize: fontSize,
              onChangeFontSize: onChangeFontSize,
              exportSetting: {
                endpoint: backend_url.bp_excel_file + '?' + exportQuery,
                endpointCount: backend_url.bp_excel_count + '?' + exportQuery,
                fileName: "Business Partners Report"
              },
            },
            filterPanel: {
              filterFormProps: {
                applyFilterChanges: applyFilter,
                deleteFilter: deleteFilter
              },
              linkOperators: ['and']
            }, 
          }}
        />
        <DialogStatus open={open.status} setOpen={onClose} sub={data} />
        <DialogChangeLogs open={open.logs} onCloseHandle={onClose} data={data} dataLogs={logs}/>
      </div>
  )
}
